<template>
  <v-container v-if="isLoaded">
    <v-card>
      <v-card-title>
        View carriers
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="_headers"
        :items="carriers"
        :items-per-page="50"
        :search="search">
        <template v-slot:item.refresh="{ item }">
          <v-btn icon @click="refresh(item)">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <base-dialog-ok
        label="Entity has been refresh!"
        :dialog="refreshDialog"
        @ok="() => refreshDialog = false"/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { security } from '@/mixins/utilsMixin'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'

export default {
  name: 'ViewCarriers',
  components: { BaseDialogOk },
  mixins: [security],
  data () {
    return {
      isLoaded: false,
      search: '',
      refreshDialog: false,
      tableHeaders: [
        { text: 'code', sortable: true, value: 'code' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Refresh', align: 'start', sortable: false, value: 'refresh' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'carriers',
      'getUserRoles'
    ]),
    _headers () {
      if (!this.isTechAdmin(this.getUserRoles)) {
        return this.tableHeaders.filter(header => header.text !== 'Refresh')
      }
      return this.tableHeaders
    }
  },
  async mounted () {
    await this.$store.dispatch('getAllCarriers').then(() => { this.isLoaded = true })
  },
  methods: {
    refresh (item) {
      return axios.get('/api/touchpoint/carriers/refresh/' + item.code)
        .then(response => {
          const index = this.carriers.findIndex(sp => sp.code === response.data.code)
          this.carriers.splice(index, 1, response.data)
          this.refreshDialog = true
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>
