<template>
  <v-textarea
    filled
    auto-grow
    :rows="rows"
    :label="itemLabel"
    :rules="rules"
    v-model="theValue"
    :hint="hint"
    :persistent-hint="showHint"
    @blur="blur"
    @click:clear="() => this.$emit('blur', [])"
    clearable>
  </v-textarea>
</template>

<script>
export default {
  name: 'BaseInputTextAreaByNewLine',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    value: {
      type: Array,
      require: false
    },
    rules: {
      type: Array,
      require: false
    },
    hint: {
      String,
      require: false
    },
    rows: {
      String: Number,
      require: false,
      default: 3
    }
  },
  data () {
    return {
      theValue: ''
    }
  },
  mounted () {
    this.theValue = this.value
  },
  watch: {
    value (val) {
      this.theValue = val.join('\n')
    }
  },
  computed: {
    showHint () {
      return !!this.hint
    }
  },
  methods: {
    blur () {
      if (this.theValue) {
        this.$emit('blur', this.theValue.split(/\r?\n|\r/).filter(item => item))
      }
    }
  }
}
</script>

<style scoped>

</style>
