<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search final destination lead time"
        icon="mdi-calendar-export"/>
      <v-card-text class="pl-8 pr-8">
        <v-row >
          <v-col md="4">
            <base-input-select-with-flag2
              :items="locations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Location"
              :selected-value="finalDestinationLeadTimeSearchCriteria.locationId"
              @change="finalDestinationLeadTimeSearchCriteria.locationId = arguments[0]"/>
          </v-col>
          <v-col cols="4">
            <base-input-multi-select-with-freight-service
              item-label="Shipping MoT"
              :grouped-freight-services="groupedFreightServices"
              :freight-services="freightServices"
              :selected-value="finalDestinationLeadTimeSearchCriteria.inboundFreightServiceIds"
              @change="finalDestinationLeadTimeSearchCriteria.inboundFreightServiceIds = arguments[0]"/>
          </v-col>
          <v-col md="4">
            <base-input-multi-select-with-flag
              :items="logicalWarehouses"
              item-label="Warehouses"
              item-value="id"
              item-text="description"
              item-country="geographicalCountry.code"
              :selected-values="finalDestinationLeadTimeSearchCriteria.logicalWarehouseIds"
              @change="finalDestinationLeadTimeSearchCriteria.logicalWarehouseIds = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="finalDestinationLeadTimeSearchCriteria.active"
              input-value="searchCriteria.active"
              @change="finalDestinationLeadTimeSearchCriteria.active = arguments[0]"
              label="Only active final destination lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-and-download-buttons
      @search="search"
      @reset="reset"
      @download="download"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="finalDestinationLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="finalDestinationLeadTimeSearchResults"
        url="/api/touchpoint/final-destination-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.location="{ item }">
          <base-label-with-flag :item-country="item.location.location.code" :item-label="item.location.unlocode"/>
        </template>
        <template v-slot:item.warehouses="{ item }">
          <BaseWarehouseWithToolTip
            :logical-warehouses="item.logicalWarehouses"/>
        </template>
        <template v-slot:item.inboundFreightServices="{ item }">
          <BaseFreightServiceWithTooltip
            :inbound-freight-services="item.inboundFreightServices"/>
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Edit"
            icon="mdi-pencil"
            @click="$router.push({ path: `/FinalDestinationLeadTime/Update/${item.id}` })">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Copy"
            icon="mdi-content-copy"
            @click="$router.push({ path: `/FinalDestinationLeadTime/Create/${item.id}` })">
          </base-tool-tip>
          <base-tool-tip
            v-if="!item.successor && !item.validTo && isAdmin(getUserRoles)"
            label="Replace"
            icon="mdi-file-replace-outline"
            @click="$router.push({ path: `/FinalDestinationLeadTime/Replace/${item.id}`})">
          </base-tool-tip>
          <base-tool-tip
            v-if="item.eligibleForDelete && isAdmin(getUserRoles)"
            label="Delete"
            icon="mdi-delete"
            @click="deleteConfirmation(item)">
          </base-tool-tip>
        </template>
        <template v-slot:item.modificationTime="{ item }">
          {{ item.modificationTime | formatDate }}
        </template>
      </base-table-paged-server-side>
    </v-card>
    <final-destination-view-details-dialog
      :can-update="isAdmin(getUserRoles)"
      :view-dialog="viewDialog"
      :final-destination-lead-time="viewFinalDestinationLeadTime"
      @close="closeViewDialog"/>
    <base-dialog-confirm
      :confirm-dialog="deleteDialogConfirm"
      label="Do you want to delete the final destination lead time ?"
      @confirm="deleteFinalDestinationLeadTime()"
      @cancel="() => this.deleteDialogConfirm = false">
    </base-dialog-confirm>
    <base-dialog-ok
      label="Final destination lead time deleted successfully."
      @ok="closeDeleteDialog"
      :dialog="deleteDialogOk"/>
  </v-container>
</template>

<script>
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputSelectWithFlag2 from '@/base/input/BaseInputSelectWithFlag'
import FinalDestinationViewDetailsDialog from '@/components/finaldestinationleadtime/FinalDestinationViewDetailsDialog'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseWarehouseWithToolTip from '@/base/tooltip/BaseWarehouseWithToolTip'
import BaseFreightServiceWithTooltip from '@/base/tooltip/BaseFreightServiceWithTooltip'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import { mapGetters } from 'vuex'
import { download, security } from '@/mixins/utilsMixin'
import BaseInputSearchAndClearAndDownloadButtons from '@/base/input/BaseInputSearchAndClearAndDownloadButtons'

export default {
  name: 'SearchShippingLeadTime',
  mixins: [security, download],
  components: {
    BaseInputSearchAndClearAndDownloadButtons,
    BaseDialogOk,
    BaseToolTip,
    BaseDialogConfirm,
    FinalDestinationViewDetailsDialog,
    BaseInputSelectWithFlag2,
    BaseInputMultiSelectWithFreightService,
    BaseLabelWithFlag,
    BaseInputMultiSelectWithFlag,
    BaseCardTitle,
    BaseTablePagedServerSide,
    BaseWarehouseWithToolTip,
    BaseFreightServiceWithTooltip
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'locations',
      'logicalWarehouses',
      'finalDestinationLeadTimeSearchCriteria',
      'getUserRoles'
    ])
  },
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      viewFinalDestinationLeadTime: {},
      finalDestinationLeadTimeIdToDelete: '',
      deleteDialogConfirm: false,
      deleteDialogOk: false,
      tableHeaders: [
        { text: 'Location', align: 'start', sortable: true, value: 'location' },
        { text: 'Shipping MoT', align: 'start', sortable: false, value: 'inboundFreightServices' },
        { text: 'Warehouses', align: 'start', sortable: false, value: 'warehouses' },
        { text: 'In warehouse to allocated', align: 'start', sortable: false, value: 'inWarehouseToAllocated' },
        { text: 'Allocated to sellable', align: 'start', sortable: false, value: 'allocatedToSellable' },
        { text: 'Total days', align: 'start', sortable: false, value: 'totalDays' },
        { text: 'Valid from', align: 'start', sortable: false, value: 'validFrom' },
        { text: 'Valid to', align: 'start', sortable: false, value: 'validTo' },
        { text: 'Quick demand deduction', align: 'start', sortable: false, value: 'quickDemandDeduction' },
        { text: 'Modified on', align: 'start', sortable: false, value: 'modificationTime' },
        { text: 'Actions', align: 'start', sortable: false, value: 'actions' }
      ]
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllLocations'),
      this.$store.dispatch('getAllLogicalWarehouses')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetFinalDestinationLeadTimeSearchCriteria')
    },
    showViewDialog (finalDestinationLeadTime) {
      this.viewFinalDestinationLeadTime = finalDestinationLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    deleteConfirmation (item) {
      this.finalDestinationLeadTimeIdToDelete = item.id
      this.deleteDialogConfirm = true
    },
    deleteFinalDestinationLeadTime () {
      this.deleteDialogConfirm = false
      axios.delete('/api/touchpoint/final-destination-lead-time/' + this.finalDestinationLeadTimeIdToDelete)
        .then(response => {
          this.$refs.table.doSearch()
          this.deleteDialogOk = true
        })
        .catch(e => console.log(e))
    },
    closeDeleteDialog () {
      this.deleteDialogOk = false
    },
    download () {
      const url = '/api/touchpoint/final-destination-lead-time/criteria-search/download'
      const fileName = 'FinalDestinationLeadTimes.xlsx'
      this.downloadToExcel(url, this.finalDestinationLeadTimeSearchCriteria, fileName)
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
