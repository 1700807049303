const initState = () => {
  return {
    proposedLogisticLeadTimeSearchCriteria: {
      countryOfDeliveryIds: [],
      freightServiceIds: [],
      planningMarketIds: [],
      serviceProviderId: '',
      shippingPlaceOfLoadingId: '',
      shippingPlaceOfDeliveryId: '',
      finalDestinationLocationId: '',
      finalDestinationCountryId: '',
      includeTransit: null,
      validFrom: '',
      active: 'true'
    },
    proposedLogisticLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchProposedLogisticLeadTime = {
  state: initState(),
  mutations: {
    updateProposedLogisticLeadTimeSearchResults (state, searchResults) {
      state.proposedLogisticLeadTimeSearchResults = searchResults
    },
    resetProposedLogisticLeadTimeSearchCriteria (state) {
      Object.assign(state.proposedLogisticLeadTimeSearchCriteria, initState().proposedLogisticLeadTimeSearchCriteria)
    }
  },
  getters: {
    proposedLogisticLeadTimeSearchCriteria: state => state.proposedLogisticLeadTimeSearchCriteria,
    proposedLogisticLeadTimeSearchResults: state => state.proposedLogisticLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchProposedLogisticLeadTime
