const initState = () => {
  return {
    warehouseSearchCriteria: {
      warehouseId: '',
      warehouseCode: '',
      planningMarketId: ''
    },
    warehouseSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const viewLogicalWarehouse = {
  state: initState(),
  mutations: {
    updateWarehouseSearchResults (state, searchResults) {
      state.warehouseSearchResults = searchResults
    },
    resetWarehouseSearchCriteria (state) {
      Object.assign(state.warehouseSearchCriteria, initState().warehouseSearchCriteria)
    }
  },
  getters: {
    warehouseSearchCriteria: state => state.warehouseSearchCriteria,
    warehouseSearchResults: state => state.warehouseSearchResults
  },
  actions: {
  }
}

export default viewLogicalWarehouse
