<template>
  <UpdateShippingLeadTime :replace-slt=true></UpdateShippingLeadTime>
</template>

<script>
import UpdateShippingLeadTime from '@/views/shippingleadtime/UpdateShippingLeadTime'

export default {
  name: 'ReplaceShippingLeadTime',
  components: {
    UpdateShippingLeadTime
  }
}
</script>

<style scoped>

</style>
