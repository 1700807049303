<template>
  <div class="text-start">
     <span>
        <v-icon class="mr-1">{{ resolveIcon(modeOfTransportCategory) }}</v-icon>
      </span>
    <span> {{modeOfTransportCategory.name}} </span>
  </div>
</template>

<script>
import { modeOfTransportCategoryIconResolverMixin } from '@/mixins/modeOfTransportCategoryIconResolverMixin'

export default {
  name: 'BaseLabelWithModeOfTransportCategory',
  mixins: [modeOfTransportCategoryIconResolverMixin],
  props: {
    modeOfTransportCategory: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped>

</style>
