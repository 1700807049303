<template>
      <v-list-group :key="groupItem.title"
                    v-if="hasAccess(getUserRoles, groupItem.rolesAllowed)"
                    v-model="groupItem.active"
                    :prepend-icon="groupItem.icon"
                    sub-group
                    no-action>
        <template v-slot:activator>
          <v-list-item-content class="ml-4">
            <v-list-item-title v-text="groupItem.title"/>
          </v-list-item-content>
        </template>
        <template v-for="child in groupItem.subItems">
          <v-list-item v-if="hasAccess(getUserRoles, child.rolesAllowed)" :key="child.title">
            <v-list-item dense class="pl-4" :to="child.to" v-text="child.title"></v-list-item>
          </v-list-item>
        </template>
      </v-list-group>
</template>
<script>
import { mapGetters } from 'vuex'
import { security } from '@/mixins/utilsMixin'

export default {
  name: 'NavigationGroup',
  mixins: [security],
  props: {
    groupItem: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters([
      'getUserRoles',
      'getUserName',
      'getAbbreviatedUserName'
    ])
  }
}
</script>
<style scoped>
  .col {
    padding: 5px;
  }
  .v-list-item {
    min-height: 48px;
  }
</style>
