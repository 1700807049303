<template>
  <v-autocomplete
    filled
    dense
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    :hint="hint"
    :validate-on-blur="true"
    hide-details="auto"
    v-model="selectValueItem"
    :clearable="clearable"
    @change="$emit('change', selectValueItem)">
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseInputSelectThin',
  props: {
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      require: true
    },
    itemValue: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      require: false
    },
    rules: {
      type: Array,
      require: false
    },
    hint: {
      type: String,
      require: false
    },
    clearable: {
      type: Boolean,
      require: false,
      default: () => true
    }
  },
  data () {
    return {
      selectValueItem: ''
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  computed: {
    showHint () {
      return !!this.hint
    }
  }
}
</script>

<style scoped>

</style>
