<template>
  <div class="text-start">
     <span>
        <v-icon class="mr-1">{{ resolveIcon(packingMode) }}</v-icon>
      </span>
    <span> {{packingMode.name}} </span>
  </div>
</template>

<script>
import { packingModeIconResolverMixin } from '@/mixins/packingModeIconResolverMixin'

export default {
  name: 'BaseLabelWithPackingMode',
  mixins: [packingModeIconResolverMixin],
  props: {
    packingMode: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped>

</style>
