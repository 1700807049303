<template>
  <v-dialog
    v-model="createDialog"
    scrollable
    persistent
    max-width="900px"
    @keydown.esc="close">
    <v-container class="container">
      <v-form ref="logistics-lead-time-create-form">
        <v-row>
          <v-col cols="12" class="mt-3">
            <create-proposed-or-update-logistic-lead-time
              :proposed-logistic-lead-time="true"
              :validation-error-messages="validationErrorMessages"
              :shipping-lead-time="shippingLeadTime"
              :transit-lt-exists="transitLtExists"
              :transit-lead-times="transitLeadTimes"
              :haulage-lt-exists="haulageLtExists"
              :haulage-lead-times="haulageLeadTimes"
              :final-destination-lead-times="finalDestinationLeadTimes"
              @showViewShippingLeadTimeDialog="showViewShippingLeadTimeDialog(shippingLeadTime)"
              @showViewTransitDialog="showViewTransitDialog(transitLeadTimes[0])"
              @showViewHaulageDialog="showViewHaulageDialog(haulageLeadTimes[0])"
              @showViewFinalDestinationDialog="showViewFinalDestinationDialog(finalDestinationLeadTimes[0])">
            </create-proposed-or-update-logistic-lead-time>

            <v-card class="mt-3 mb-6">
              <div class="text-h6 mb-3 ml-4 mb-6 pt-3">Valid period</div>
              <v-row>
                <v-col cols="7" class="pb-0 pt-0 ml-6">
                  <base-input-select-date-picker
                    item-label="Valid from"
                    :picker-date="validFromDateAsString"
                    :min="validFromDateAsString"
                    :max="validToDateAsString"
                    :selected-value="logisticsLeadTime.validFrom"
                    :rules="[rules.required]"
                    @change="logisticsLeadTime.validFrom = arguments[0]"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" class="pb-0 pt-0 ml-6">
                  <base-input-select-date-picker
                    item-label="Valid to"
                    :picker-date="minDateForValidTo"
                    :min="minDateForValidTo"
                    :max="validToDateAsString"
                    :rules="[rules.fromDateIsBeforeToDate]"
                    :selected-value="logisticsLeadTime.validTo"
                    @change="logisticsLeadTime.validTo = arguments[0]"/>
                </v-col>
              </v-row>
            </v-card>
            <base-input-primary-button
              label="save"
              icon="mdi-arrow-right-bold-circle"
              @click="save"/>
            <base-input-secondary-button
              label="cancel"
              icon="mdi-cancel"
              @click="cancel"/>
          </v-col>
        </v-row>
      </v-form>

      <search-shipping-lead-time-view-details-dialog
        :view-dialog="viewShippingLeadTimeDialog"
        :view-dialog-shipping-lead-time="viewDialogShippingLeadTime"
        :can-update="false"
        @close="closeViewShippingLeadTimeDialog"/>

      <search-transit-lead-time-view-details-dialog
        :view-dialog="viewTransitDialog"
        :view-dialog-transit-lead-time="viewDialogTransitLeadTime"
        :can-update="false"
        @close="closeViewTransitDialog"/>

      <search-haulage-lead-time-view-details-dialog
        :view-dialog="viewHaulageDialog"
        :view-dialog-haulage-lead-time="viewDialogHaulageLeadTime"
        :can-update="false"
        @close="closeViewHaulageDialog"/>

      <final-destination-view-details-dialog
        :view-dialog="viewFinalDestinationDialog"
        :final-destination-lead-time="viewDialogFinalDestinationLeadTime"
        :can-update="false"
        @close="closeViewFinalDestinationDialog"/>

      <base-dialog-yes-no-cancel
        label=""
        :dialog="publishLeadTimeDialog"
        @yes="send"
        @no="withoutPublishLeadTime"
        @cancel="() => this.publishLeadTimeDialog = false">
        <v-row justify="center">
          <p>Publish the logistic lead time? Select date for publish logistic lead time.</p>
          <p>Click no or leave empty and the logistic lead time will be saved and can be published at a later time.</p>
        </v-row>
        <v-row justify="center">
          <v-col cols="6">
            <base-input-select-date-picker
              :min-today="true"
              item-label="Publish time"
              @change="logisticsLeadTime.publishTime = arguments[0]"/>
          </v-col>
        </v-row>
      </base-dialog-yes-no-cancel>

      <base-dialog-ok
        label="Logistic lead time has been created successfully!"
        :max-width="550"
        :persistent="true"
        @ok="close"
        :dialog="sendDialog"/>
    </v-container>
  </v-dialog>
</template>

<script>
import CreateProposedOrUpdateLogisticLeadTime from '@/components/logisticsleadtime/createlofisticleadtime/CreateProposedOrUpdateLogisticLeadTime'
import SearchShippingLeadTimeViewDetailsDialog from '@/components/shippingleadtime/searchshippingleadtime/SearchShippingLeadTimeViewDetailsDialog'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import SearchHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchHaulageLeadTimeViewDetailsDialog'
import FinalDestinationViewDetailsDialog from '@/components/finaldestinationleadtime/FinalDestinationViewDetailsDialog'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseDialogYesNoCancel from '@/base/dialog/BaseDialogYesNoCancel'
import { validateDate } from '@/validation/customvalidation'
import { max, min } from 'lodash'

export default {
  name: 'CreateLogisticsLeadTimeFromProposedLeadTime',
  components: {
    CreateProposedOrUpdateLogisticLeadTime,
    SearchShippingLeadTimeViewDetailsDialog,
    SearchTransitLeadTimeViewDetailsDialog,
    SearchHaulageLeadTimeViewDetailsDialog,
    FinalDestinationViewDetailsDialog,
    BaseInputSelectDatePicker,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseDialogOk,
    BaseDialogYesNoCancel
  },
  props: {
    createDialog: {
      type: Boolean,
      require: true
    },
    createDialogLogisticLeadTime: {
      type: Object,
      require: true
    },
    shippingLeadTime: {
      type: Object,
      require: true
    },
    transitLtExists: {
      type: Boolean,
      require: true
    },
    haulageLtExists: {
      type: Boolean,
      require: true
    },
    transitLeadTimes: {
      type: Array,
      require: true
    },
    haulageLeadTimes: {
      type: Array,
      require: true
    },
    finalDestinationLeadTimes: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      validationErrorMessages: [],
      viewShippingLeadTimeDialog: false,
      viewHaulageDialog: false,
      viewTransitDialog: false,
      viewFinalDestinationDialog: false,
      publishLeadTimeDialog: false,
      sendDialog: false,
      logisticsLeadTime: {
        shippingLeadTimeId: '',
        transitLeadTimeId: '',
        haulageLeadTimeId: '',
        finalDestinationLeadTimeId: '',
        validFrom: '',
        validTo: '',
        publishTime: ''
      },
      viewDialogShippingLeadTime: {},
      viewDialogTransitLeadTime: {},
      viewDialogHaulageLeadTime: {},
      viewDialogFinalDestinationLeadTime: {},
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.logisticsLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  methods: {
    showViewShippingLeadTimeDialog (leadtime) {
      this.viewDialogShippingLeadTime = leadtime
      this.viewShippingLeadTimeDialog = true
    },
    closeViewShippingLeadTimeDialog () {
      this.viewShippingLeadTimeDialog = false
    },
    showViewTransitDialog (leadtime) {
      this.viewDialogTransitLeadTime = leadtime
      this.viewTransitDialog = true
    },
    showViewHaulageDialog (leadtime) {
      this.viewDialogHaulageLeadTime = leadtime
      this.viewHaulageDialog = true
    },
    closeViewTransitDialog () {
      this.viewTransitDialog = false
    },
    closeViewHaulageDialog () {
      this.viewHaulageDialog = false
    },
    showViewFinalDestinationDialog (leadtime) {
      this.viewDialogFinalDestinationLeadTime = leadtime
      this.viewFinalDestinationDialog = true
    },
    closeViewFinalDestinationDialog () {
      this.viewFinalDestinationDialog = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    cancel () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.sendDialog = false
      Object.assign(this.$data.logisticsLeadTime, this.$options.data().logisticsLeadTime)
      this.$refs['logistics-lead-time-create-form'].resetValidation()
    },
    save () {
      this.isValid().then(() => {
        if (this.validationErrorMessages.length === 0) {
          this.publishLeadTimeDialog = true
        }
      })
    },
    withoutPublishLeadTime () {
      this.send()
    },
    send () {
      this.publishLeadTimeDialog = false
      if (this.validationErrorMessages.length === 0) {
        axios.post('/api/touchpoint/create-from-proposed-logistic-lead-time',
          {
            logisticLeadTimeCommand: this.logisticsLeadTime,
            proposedLogisticLeadTimeId: this.createDialogLogisticLeadTime.id
          })
          .then((response) => {
            this.sendDialog = true
            this.$refs['logistics-lead-time-create-form'].resetValidation()
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    async isValid () {
      this.validationErrorMessages = []

      if (!this.$refs['logistics-lead-time-create-form'].validate()) {
        if (!this.logisticsLeadTime.validFrom) {
          this.validationErrorMessages.push("Please select 'from date'")
        }
        if (!validateDate(this.logisticsLeadTime.validFrom, this.logisticsLeadTime.validTo)) {
          this.validationErrorMessages.push('Valid to date cannot be before valid from date.')
        }
      }

      if (this.validationErrorMessages.length === 0) {
        await axios.post('/api/touchpoint/logistic-lead-time/validate', this.logisticsLeadTime)
          .then((response) => {
            if (response.data.length > 0) {
              this.validationErrorMessages.push('Similar logistics lead time exists with overlapping intervals')
            }
            return response.data.length === 0
          })
          .catch(e => console.log(e))
      }

      return this.validationErrorMessages.length === 0
    }
  },
  watch: {
    createDialog (val) {
      if (val && Object.keys(this.createDialogLogisticLeadTime).length > 0) {
        this.validationErrorMessages = []
        this.logisticsLeadTime.shippingLeadTimeId = this.shippingLeadTime.id

        if (this.transitLtExists) {
          this.logisticsLeadTime.transitLeadTimeId = this.transitLeadTimes[0].id
        }

        if (this.haulageLtExists) {
          this.logisticsLeadTime.haulageLeadTimeId = this.haulageLeadTimes[0].id
        }

        this.logisticsLeadTime.finalDestinationLeadTimeId = this.finalDestinationLeadTimes[0].id

        this.logisticsLeadTime.validFrom = this.validFromDateAsString
        this.logisticsLeadTime.validTo = this.validToDateAsString
      }
    }
  },
  computed: {
    calculateValidFromDate () {
      const transitLeadTime = this.transitLeadTimes[0]
      const haulageLeadTime = this.haulageLeadTimes[0]
      const finalDestinationLeadTime = this.finalDestinationLeadTimes[0]
      if (this.shippingLeadTime.id && finalDestinationLeadTime.id) {
        const validFromDates = []
        if (this.shippingLeadTime.validFrom) {
          validFromDates.push(new Date(this.shippingLeadTime.validFrom))
        }

        if (finalDestinationLeadTime.validFrom) {
          validFromDates.push(new Date(finalDestinationLeadTime.validFrom))
        }

        if (transitLeadTime && transitLeadTime.validFrom) {
          validFromDates.push(new Date(transitLeadTime.validFrom))
        }

        if (haulageLeadTime && haulageLeadTime.validFrom) {
          validFromDates.push(new Date(haulageLeadTime.validFrom))
        }

        return max(validFromDates)
      }
      return ''
    },
    calculateValidToDate () {
      const transitLeadTime = this.transitLeadTimes[0]
      const haulageLeadTime = this.haulageLeadTimes[0]
      const finalDestinationLeadTime = this.finalDestinationLeadTimes[0]
      if (this.shippingLeadTime.id && finalDestinationLeadTime.id) {
        const validToDates = []
        if (this.shippingLeadTime.validTo) {
          validToDates.push(new Date(this.shippingLeadTime.validTo))
        }

        if (finalDestinationLeadTime.validTo) {
          validToDates.push(new Date(finalDestinationLeadTime.validTo))
        }

        if (transitLeadTime && transitLeadTime.validTo) {
          validToDates.push(new Date(transitLeadTime.validTo))
        }

        if (haulageLeadTime && haulageLeadTime.validTo) {
          validToDates.push(new Date(haulageLeadTime.validTo))
        }

        return min(validToDates)
      }
      return ''
    },
    validFromDateAsString () {
      const validFromDate = this.calculateValidFromDate
      if (validFromDate) {
        return validFromDate.toISOString().split('T')[0]
      }
      return ''
    },
    validToDateAsString () {
      const validToDate = this.calculateValidToDate
      if (validToDate) {
        return validToDate.toISOString().split('T')[0]
      }
      return undefined
    },
    minDateForValidTo () {
      if (!this.logisticsLeadTime.validFrom) {
        return this.validFromDateAsString
      } else {
        return this.logisticsLeadTime.validFrom
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1300px;
}
</style>
