const initState = () => {
  return {
    proposedFinalDestinationLeadTimeSearchCriteria: {
      locationId: '',
      active: 'true'
    },
    proposedFinalDestinationLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchProposedFinalDestinationLeadTime = {
  state: initState(),
  mutations: {
    updateProposedFinalDestinationLeadTimeSearchResults (state, searchResults) {
      state.proposedFinalDestinationLeadTimeSearchResults = searchResults
    },
    resetProposedFinalDestinationLeadTimeSearchCriteria (state) {
      Object.assign(state.proposedFinalDestinationLeadTimeSearchCriteria,
        initState().proposedFinalDestinationLeadTimeSearchCriteria)
    }
  },
  getters: {
    proposedFinalDestinationLeadTimeSearchCriteria: state => state.proposedFinalDestinationLeadTimeSearchCriteria,
    proposedFinalDestinationLeadTimeSearchResults: state => state.proposedFinalDestinationLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchProposedFinalDestinationLeadTime
