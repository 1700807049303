<template>
  <v-container>
      <v-card class="mb-6 forbidden-card" color="grey lighten-4">
        <div id="forbidden-message">
          <h1>Access Denied</h1>
          <p class="icon"><v-icon color="red">mdi-cancel</v-icon></p>
          <p class="grey--text">You do not have permission to access this page. <br/> Please contact SLLT support to request access.<br/><br/></p>
          <base-input-primary-button label="Go to home" @click="backToHome"/>
        </div>
      </v-card>
  </v-container>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'

export default {
  name: 'AccessDenied',
  components: { BaseInputPrimaryButton },

  methods: {
    backToHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}

</script>

<style scoped>

.forbidden-card {
  min-height: 300px;
  margin-top: 100px;
}

#forbidden-message {
  margin: auto;
  width: 85%;
  text-align: center;
}

p.icon > i {
  font-size: 75px;
}
</style>
