<template>
  <v-container class="container">
    <v-form ref="logistics-lead-time-update-form">
      <v-row>
        <v-col cols="8" class="mt-3">
          <create-proposed-or-update-logistic-lead-time
            :validation-error-messages="validationErrorMessages"
            :shipping-lead-time="shippingLeadTime"
            :transit-lt-exists="transitLtExists"
            :transit-lead-times="transitLeadTimes"
            :haulage-lt-exists="haulageLtExists"
            :haulage-lead-times="haulageLeadTimes"
            :final-destination-lead-times="finalDestinationLeadTimes"
            @showViewShippingLeadTimeDialog="showViewShippingLeadTimeDialog(shippingLeadTime)"
            @showViewTransitDialog="showViewTransitDialog(transitLeadTime)"
            @showViewHaulageDialog="showViewHaulageDialog(haulageLeadTime)"
            @showViewFinalDestinationDialog="showViewFinalDestinationDialog(finalDestinationLeadTime)">
          </create-proposed-or-update-logistic-lead-time>

          <v-card class="mt-3 mb-6">
            <div class="text-h6 mb-3 ml-4 mb-6 pt-3">Valid</div>
            <v-row>
              <v-col cols="7" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker-read-only
                  item-label="Publish time"
                  :value="logisticsLeadTime.publishTime | formatDate"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker-read-only
                  item-label="Valid from"
                  :value="logisticsLeadTime.validFrom"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :picker-date="pickerDateForValidTo"
                  :min="minDateForValidTo"
                  :max="validToDateAsString"
                  :rules="[rules.fromDateIsBeforeToDate,rules.toDateIsNotEmpty]"
                  :selected-value="logisticsLeadTime.validTo"
                  @change="logisticsLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            label="update"
            icon="mdi-arrow-right-bold-circle"
            @click="update"/>

          <base-input-primary-button
            v-if="!logisticsLeadTime.publishTime"
            class="ml-2"
            label="Publish Lead time"
            icon="mdi-upload"
            @click="showPublishConfirmDialog"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>
        <v-col cols="4" class="mt-3">
          <logistic-lead-time-time-line
            v-if="shippingLeadTime.placeOfLoading"
            :shipping-lead-time="shippingLeadTime"
            :transit-lead-time="transitLeadTime"
            :haulage-lead-time="haulageLeadTime"
            :final-destination-lead-time="finalDestinationLeadTime"/>
        </v-col>
      </v-row>
    </v-form>

    <base-dialog-update
      label="Logistic lead time has been updated successfully!"
      @create="search"
      @search="search"
      :send-dialog="sendDialog"/>

    <base-dialog-confirm
      :confirm-dialog="publishDialogConfirm"
      label="Select a date for when logistic lead time should be published."
      @confirm="publishLeadTime"
      @cancel="() => this.publishDialogConfirm = false">
      <v-form ref="publishForm">
        <v-row justify="center">
          <v-col cols="6">
            <base-input-select-date-picker
              :min-today="true"
              :rules="[rules.required]"
              :validate-on-blur=true
              item-label="Publish time"
              @change="publishTime = arguments[0]"/>
          </v-col>
        </v-row>
      </v-form>
    </base-dialog-confirm>

    <base-dialog-ok
      label="Logistic lead time has been published!"
      @ok="closePublishLeadTimeDialog"
      :dialog="publishOkDialog"/>

    <search-shipping-lead-time-view-details-dialog
      :view-dialog="viewShippingLeadTimeDialog"
      :view-dialog-shipping-lead-time="viewDialogShippingLeadTime"
      :can-update="false"
      @close="closeViewShippingLeadTimeDialog"/>

    <search-transit-lead-time-view-details-dialog
      :view-dialog="viewTransitDialog"
      :view-dialog-transit-lead-time="viewDialogTransitLeadTime"
      :can-update="false"
      @close="closeViewTransitDialog"/>

    <search-haulage-lead-time-view-details-dialog
      :view-dialog="viewHaulageDialog"
      :view-dialog-haulage-lead-time="viewDialogHaulageLeadTime"
      :can-update="false"
      @close="closeViewHaulageDialog"/>

    <final-destination-view-details-dialog
      :view-dialog="viewFinalDestinationDialog"
      :final-destination-lead-time="viewDialogFinalDestinationLeadTime"
      :can-update="false"
      @close="closeViewFinalDestinationDialog"/>

  </v-container>
</template>

<script>

import axios from 'axios'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import LogisticLeadTimeTimeLine from '@/components/logisticsleadtime/LogisticLeadTimeTimeLine'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseDialogUpdate from '@/base/dialog/BaseDialogUpdate'
import SearchHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchHaulageLeadTimeViewDetailsDialog'
import FinalDestinationViewDetailsDialog from '@/components/finaldestinationleadtime/FinalDestinationViewDetailsDialog'
import SearchShippingLeadTimeViewDetailsDialog from '@/components/shippingleadtime/searchshippingleadtime/SearchShippingLeadTimeViewDetailsDialog'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import CreateProposedOrUpdateLogisticLeadTime from '@/components/logisticsleadtime/createlofisticleadtime/CreateProposedOrUpdateLogisticLeadTime'
import { validateDate } from '@/validation/customvalidation'
import { min } from 'lodash'

export default {
  name: 'UpdateLogisticsLeadTime',
  components: {
    CreateProposedOrUpdateLogisticLeadTime,
    SearchTransitLeadTimeViewDetailsDialog,
    BaseDialogConfirm,
    SearchShippingLeadTimeViewDetailsDialog,
    FinalDestinationViewDetailsDialog,
    SearchHaulageLeadTimeViewDetailsDialog,
    BaseDialogUpdate,
    BaseDialogOk,
    BaseInputSelectDatePickerReadOnly,
    BaseInputSelectDatePicker,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    LogisticLeadTimeTimeLine
  },
  data () {
    return {
      validationErrorMessages: [],
      sendDialog: false,
      transitLtExists: false,
      haulageLtExists: false,
      logisticsLeadTime: {
        id: this.$route.params.id,
        validFrom: '',
        validTo: '',
        publishTime: ''
      },
      transitLeadTimes: [],
      haulageLeadTimes: [],
      finalDestinationLeadTimes: [],
      shippingLeadTime: {},
      transitLeadTime: {},
      haulageLeadTime: {},
      finalDestinationLeadTime: {},
      publishTime: '',
      publishOkDialog: false,
      publishDialogConfirm: false,
      viewShippingLeadTimeDialog: false,
      viewDialogShippingLeadTime: {},
      viewHaulageDialog: false,
      viewTransitDialog: false,
      viewDialogTransitLeadTime: {},
      viewDialogHaulageLeadTime: {},
      viewFinalDestinationDialog: false,
      viewDialogFinalDestinationLeadTime: {},
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.logisticsLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.',
        toDateIsNotEmpty: value => {
          if (this.validToDateAsString && this.logisticsLeadTime.validTo) {
            return true
          } else if (this.validToDateAsString && !this.logisticsLeadTime.validTo) {
            return 'Valid to date cannot be empty if any component(s) have valid to set.'
          }
        }
      }
    }
  },
  mounted () {
    axios.get('/api/touchpoint/logistic-lead-time/' + this.$route.params.id)
      .then((response) => {
        this.shippingLeadTime = response.data.shippingLeadTime
        this.finalDestinationLeadTimes.push(response.data.finalDestinationLeadTime)

        if (response.data.haulageLeadTime != null) {
          this.haulageLeadTimes.push(response.data.haulageLeadTime)
          this.haulageLeadTime = response.data.haulageLeadTime
          this.haulageLtExists = true
        }

        if (response.data.transitLeadTime != null) {
          this.transitLeadTimes.push(response.data.transitLeadTime)
          this.transitLeadTime = response.data.transitLeadTime
          this.transitLtExists = true
        }

        this.finalDestinationLeadTime = response.data.finalDestinationLeadTime

        this.logisticsLeadTime.validFrom = response.data.validFrom
        this.logisticsLeadTime.validTo = response.data.validTo
        this.logisticsLeadTime.publishTime = response.data.publishTime
      }).catch(e => console.log(e))
  },
  computed: {
    minDateForValidTo () {
      if (this.logisticsLeadTime.validFrom) {
        return this.logisticsLeadTime.validFrom
      }
      return undefined
    },
    pickerDateForValidTo () {
      if (new Date(this.logisticsLeadTime.validFrom) > new Date()) {
        return this.logisticsLeadTime.validFrom
      }
      return new Date().toISOString().split('T')[0]
    },
    validToDateAsString () {
      if (this.shippingLeadTime.id && this.finalDestinationLeadTime.id) {
        const validToDates = []
        if (this.shippingLeadTime.validTo) {
          validToDates.push(new Date(this.shippingLeadTime.validTo))
        }

        if (this.finalDestinationLeadTime.validTo) {
          validToDates.push(new Date(this.finalDestinationLeadTime.validTo))
        }

        if (this.transitLeadTime.validTo) {
          validToDates.push(new Date(this.transitLeadTime.validTo))
        }

        if (this.haulageLeadTime.validTo) {
          validToDates.push(new Date(this.haulageLeadTime.validTo))
        }

        const minDate = min(validToDates)
        if (minDate) {
          return minDate.toISOString().split('T')[0]
        }
      }
      return undefined
    }
  },
  methods: {
    update () {
      if (!this.$refs['logistics-lead-time-update-form'].validate()) {
        return false
      }
      this.validationErrorMessages = []
      this.isValid()
        .then(() => {
          if (this.validationErrorMessages.length === 0) {
            axios.put('/api/touchpoint/logistic-lead-time', this.logisticsLeadTime)
              .then((response) => {
                this.sendDialog = true
              })
              .catch(e => console.log(e))
          } else {
            window.scrollTo(0, 0)
          }
        })
        .then()
    },
    cancel () {
      this.$router.push({ name: 'SearchLogisticsLeadTime' })
    },
    async isValid () {
      await axios.post('/api/touchpoint/logistic-lead-time/update/validate', this.logisticsLeadTime)
        .then((response) => {
          if (response.data.length > 0) {
            this.validationErrorMessages.push('Similar logistics lead time exists with overlapping intervals')
          }
          return response.data.length === 0
        })
        .catch(e => console.log(e))
      return this.validationErrorMessages.length === 0
    },
    showViewShippingLeadTimeDialog (leadtime) {
      this.viewDialogShippingLeadTime = leadtime
      this.viewShippingLeadTimeDialog = true
    },
    closeViewShippingLeadTimeDialog () {
      this.viewShippingLeadTimeDialog = false
    },
    showViewTransitDialog (leadtime) {
      this.viewDialogTransitLeadTime = leadtime
      this.viewTransitDialog = true
    },
    showViewHaulageDialog (leadtime) {
      this.viewDialogHaulageLeadTime = leadtime
      this.viewHaulageDialog = true
    },
    closeViewTransitDialog () {
      this.viewTransitDialog = false
    },
    closeViewHaulageDialog () {
      this.viewHaulageDialog = false
    },
    showViewFinalDestinationDialog (leadtime) {
      this.viewDialogFinalDestinationLeadTime = leadtime
      this.viewFinalDestinationDialog = true
    },
    closeViewFinalDestinationDialog () {
      this.viewFinalDestinationDialog = false
    },
    showPublishConfirmDialog () {
      if (this.$refs.publishForm) {
        this.$refs.publishForm.resetValidation()
      }
      this.publishDialogConfirm = true
    },
    publishLeadTime () {
      if (this.$refs.publishForm && this.$refs.publishForm.validate()) {
        axios.post('/api/touchpoint/logistic-lead-time/publish', {
          ids: [this.logisticsLeadTime.id],
          publishTime: this.publishTime
        }).then((response) => {
          this.publishDialogConfirm = false
          this.publishOkDialog = true
        }).catch(e => console.log(e))
      }
    },
    closePublishLeadTimeDialog () {
      this.$router.go()
    },
    search () {
      this.$router.push({ name: 'SearchLogisticsLeadTime' })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1300px;
}

.v-card__text {
  padding-top: 0;
}

.v-expansion-panel {
  margin-top: 10px;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 30px;
}

.v-expansion-panel-header:before {
  background-color: white;
  border-bottom: 1px solid;
}
</style>
