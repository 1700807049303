<template>
  <v-container>
    <v-form ref="additional-shipping-lead-time-update-form">
      <v-row>
        <v-col class="mt-8" cols="6" offset="2">
          <v-alert v-if="validationErrorMessages && validationErrorMessages.length > 0" color="red lighten-2" dark>
            <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
              <li>{{validationErrorMessage.message}}</li>
            </ul>
          </v-alert>
          <v-card class="mb-6">
            <base-card-title
                label="Update Additional Shipping Lead Time"
                icon="mdi-clock-plus"/>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-multi-select-with-freight-service-read-only
                    item-label="Freight services"
                    :freight-services="freightServices"
                    :grouped-freight-services="groupedFreightServices"
                    :selected-value="additionalShippingLeadTime.freightServiceIds"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                    item-label="Place of loading"
                    :items="placesOfLoading"
                    item-country="location.code"
                    item-text="description"
                    item-value="id"
                    :selected-value="additionalShippingLeadTime.placeOfLoadingId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                    item-label="Place of delivery"
                    :items="placesOfDelivery"
                    item-country="location.code"
                    item-text="description"
                    item-value="id"
                    :selected-value="additionalShippingLeadTime.placeOfDeliveryId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                    item-label="Place of loading country"
                    :items="placeOfLoadingCountries"
                    item-country="code"
                    item-text="description"
                    item-value="id"
                    :selected-value="additionalShippingLeadTime.placeOfLoadingCountryId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                    item-label="Place of delivery country"
                    :items="placeOfDeliveryCountries"
                    item-country="code"
                    item-text="description"
                    item-value="id"
                    :selected-value="additionalShippingLeadTime.placeOfDeliveryCountryId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-read-only-text-field
                    item-label="Additional lead time (days)"
                    :value="additionalShippingLeadTime.additionalLeadTimeInDays"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker-read-only
                    item-label="Valid from"
                    :value="additionalShippingLeadTime.validFrom"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                    item-label="Valid to"
                    :selected-value="additionalShippingLeadTime.validTo"
                    :min="additionalShippingLeadTime.validFrom"
                    :rules="[rules.required, rules.fromDateIsBeforeToDate]"
                    @change="additionalShippingLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
              label="update"
              icon="mdi-arrow-right-bold-circle"
              @click="update"/>

          <base-input-secondary-button
              label="cancel"
              icon="mdi-cancel"
              @click="cancel"/>
        </v-col>
      </v-row>
    </v-form>
    <base-dialog-send
        label="Additional shipping lead time has been updated successfully!"
        @create="createNew"
        @search="search"
        :show-copy="false"
        :send-dialog="sendDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import { validateDate } from '@/validation/customvalidation'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputMultiSelectWithFreightServiceReadOnly from '@/base/input/BaseInputMultiSelectWithFreightServiceReadOnly'
import BaseInputSelectWithFlagReadOnly from '@/base/input/BaseInputSelectWithFlagReadOnly'
import BaseInputReadOnlyTextField from '@/base/input/BaseInputReadOnlyTextField'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import { mapGetters } from 'vuex'

export default {
  name: 'UpdateAdditionalShippingLeadTime',
  components: {
    BaseInputSelectDatePickerReadOnly,
    BaseInputReadOnlyTextField,
    BaseInputSelectWithFlagReadOnly,
    BaseInputMultiSelectWithFreightServiceReadOnly,
    BaseInputSelectDatePicker,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseDialogSend
  },
  data () {
    return {
      sendDialog: false,
      validationErrorMessages: [],
      additionalShippingLeadTime: {
        placeOfLoadingId: '',
        placeOfDeliveryId: '',
        placeOfLoadingCountryId: '',
        placeOfDeliveryCountryId: '',
        freightServiceIds: [],
        additionalLeadTimeInDays: '',
        validFrom: '',
        validTo: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.additionalShippingLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'placesOfLoading',
      'placesOfDelivery',
      'placeOfLoadingCountries',
      'placeOfDeliveryCountries',
      'additionalShippingLeadTimeSearchCriteria',
      'getUserRoles'
    ])
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDelivery'),
      this.$store.dispatch('getPlaceOfLoadingCountries'),
      this.$store.dispatch('getPlaceOfDeliveryCountries'),
      this.$store.dispatch('getAllGeographicalCountries')
    ]).then(() => {
      axios.get('/api/touchpoint/additional-shipping-lead-time/' + this.$route.params.id)
        .then((response) => this.mapResponse(response.data))
        .then(() => { this.isLoaded = true })
    })
  },
  methods: {
    mapResponse (data) {
      this.additionalShippingLeadTime.id = this.$route.params.id
      this.additionalShippingLeadTime.placeOfLoadingId = data.placeOfLoading?.id
      this.additionalShippingLeadTime.placeOfDeliveryId = data.placeOfDelivery?.id
      this.additionalShippingLeadTime.placeOfLoadingCountryId = data.placeOfLoadingCountry?.id
      this.additionalShippingLeadTime.placeOfDeliveryCountryId = data.placeOfDeliveryCountry?.id
      this.additionalShippingLeadTime.additionalLeadTimeInDays = data.additionalLeadTimeInDays.toString()
      this.additionalShippingLeadTime.validFrom = data.validFrom
      if (data.validTo) {
        this.additionalShippingLeadTime.validTo = data.validTo
      }
      data.freightServices.forEach(freightService => this.additionalShippingLeadTime.freightServiceIds.push(freightService.id))
    },
    validate () {
      return this.$refs['additional-shipping-lead-time-update-form'].validate()
    },
    update () {
      if (this.validate()) {
        axios.put('/api/touchpoint/additional-shipping-lead-time', this.additionalShippingLeadTime)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    cancel () {
      this.$router.push({ name: 'SearchAdditionalShippingLeadTime' })
    },
    createNew () {
      this.sendDialog = false
      this.$router.push({ name: 'CreateAdditionalShippingLeadTime' })
    },
    search () {
      this.$router.push({ name: 'SearchAdditionalShippingLeadTime' })
    }
  }
}
</script>

<style scoped>
</style>
