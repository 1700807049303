<template>
  <div class="text-start">
     <span>
        <v-icon class="mr-1">{{ resolveIcon(modeOfTransport) }}</v-icon>
      </span>
    <span> {{ modeOfTransport.name }} </span>
  </div>
</template>

<script>
import { modeOfTransportIconResolverMixin } from '@/mixins/modeOfTransportIconResolverMixin'

export default {
  name: 'BaseLabelWithModeOfTransport',
  mixins: [modeOfTransportIconResolverMixin],
  props: {
    modeOfTransport: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped>

</style>
