<template>
  <v-form ref="shipping-lead-time-replace-step1-form">
    <v-card
        elevation="0"
        class="mb-3">

      <div class="mb-3">Select place of loading and freight service to create new shipping lead times from existing</div>

      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
          <base-input-select-with-flag
            item-label="Place of loading"
            :items="placesOfLoading"
            item-country="location.code"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="shippingLeadTimeSearchCriteria.placeOfLoadingId"
            @change="shippingLeadTimeSearchCriteria.placeOfLoadingId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-with-freight-service
            :freight-services="freightServices"
            item-label="Mode of transport"
            :rules="[rules.required]"
            :selected-value="shippingLeadTimeSearchCriteria.freightServiceId"
            @change="shippingLeadTimeSearchCriteria.freightServiceId = arguments[0]"/>
        </v-col>
      </v-row>
    </v-card>
    <base-dialog-ok label="No active shipping lead time(s) are eligible to replace for selected place of loading and freight service combination"
                    :show-icon="false"
                    :dialog="dialog"
                    @ok="closeDialog"/>
  </v-form>
</template>

<script>
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'

export default {
  name: 'ReplaceShippingLeadTimeStep1',
  components: {
    BaseDialogOk,
    BaseInputSelectWithFlag,
    BaseInputSelectWithFreightService
  },
  data () {
    return {
      dialog: false,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  props: {
    freightServices: {
      type: Array,
      require: true
    },
    placesOfLoading: {
      type: Array,
      require: true
    },
    shippingLeadTimeSearchCriteria: {
      type: Object,
      require: true
    }
  },
  methods: {
    validate () {
      return this.$refs['shipping-lead-time-replace-step1-form'].validate()
    },
    async loadResults () {
      await axios.get('/api/touchpoint/shipping-lead-time/place-of-loading/' + this.shippingLeadTimeSearchCriteria.placeOfLoadingId + '/freight-service/' + this.shippingLeadTimeSearchCriteria.freightServiceId)
        .then(response => {
          this.$emit('search', response.data)
          if (response.data.length === 0) {
            this.dialog = true
          }
        })
        .catch(e => console.log(e))
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
</style>
