<template>
  <v-container v-if="isLoaded">

    <v-card class="mt-5">
      <base-card-title
        label="Search warehouse"
        icon="mdi-warehouse"/>
      <v-card-text>
        <v-row >
          <v-col md="4">
            <base-input-select
              :items="logicalWarehouses"
              item-text="warehouseId"
              item-value="warehouseId"
              item-label="Warehouse id"
              :selected-value="warehouseSearchCriteria.warehouseId"
              @change="warehouseSearchCriteria.warehouseId = arguments[0]"/>
          </v-col>
          <v-col md="4">
            <base-input-select-with-flag
              :items="logicalWarehouses"
              item-text="description"
              item-value="currentWarehouseCode"
              item-label="Warehouse codes"
              item-country="geographicalCountry.code"
              :selected-value="warehouseSearchCriteria.warehouseCode"
              @change="warehouseSearchCriteria.warehouseCode = arguments[0]"/>
          </v-col>
          <v-col md="4">
            <base-input-select-with-flag
              :items="planningMarkets"
              item-value="id"
              item-text="description"
              item-country="logisticCountry.code"
              item-label="Planning market"
              :selected-value="warehouseSearchCriteria.planningMarketId"
              @change="warehouseSearchCriteria.planningMarketId = arguments[0]"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-buttons
      @search="search"
      @reset="reset"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="warehouseSearchCriteria"
        :table-headers="_headers"
        search-result-name="warehouseSearchResults"
        url="/api/touchpoint/logical-warehouse/criteria-search">

        <!-- slots -->
        <template v-slot:item.geographicalCountry="{ item }">
          <base-label-with-flag :item-country="item.geographicalCountry.code" :item-label="item.geographicalCountry.name"/>
        </template>
        <template v-slot:item.activeStartDate="{ item }">
          {{ item.activeStartDate | formatDate }}
        </template>
        <template v-slot:item.activeEndDate="{ item }">
          {{ item.activeEndDate | formatDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
        </template>

        <template v-slot:item.refresh="{ item }" v-if="isTechAdmin(getUserRoles)">
          <v-btn icon @click="refresh(item)">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
        </template>

      </base-table-paged-server-side>
    </v-card>

    <base-dialog-ok
      label="Entity has been refreshed!"
      :dialog="refreshDialog"
      @ok="closeRefreshDialog"/>

    <logical-warehouse-view-details-dialog
      :logical-warehouse="viewLogicalWarehouse"
      :view-dialog="viewDialog"
      @close="() => this.viewDialog = false"/>

  </v-container>
</template>

<script>
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import { mapGetters } from 'vuex'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import { security } from '@/mixins/utilsMixin'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import LogicalWarehouseViewDetailsDialog from '@/components/logicalwarehouse/LogicalWarehouseViewDetailsDialog'

export default {
  name: 'ViewLogicalWarehouse',
  mixins: [security],
  components: {
    LogicalWarehouseViewDetailsDialog,
    BaseToolTip,
    BaseInputSelect,
    BaseDialogOk,
    BaseInputSearchAndClearButtons,
    BaseLabelWithFlag,
    BaseInputSelectWithFlag,
    BaseCardTitle,
    BaseTablePagedServerSide
  },
  data () {
    return {
      isLoaded: false,
      warehouseIds: [],
      warehouseCodes: [],
      refreshDialog: false,
      viewDialog: false,
      viewLogicalWarehouse: {},
      tableHeaders: [
        { text: 'Warehouse Id', align: 'start', sortable: true, value: 'warehouseId' },
        { text: 'Warehouse code', sortable: true, value: 'currentWarehouseCode' },
        { text: 'PM', sortable: true, value: 'planningMarketAbbreviation' },
        { text: 'Type', sortable: true, value: 'warehouseType' },
        { text: 'Country', sortable: true, value: 'geographicalCountry' },
        { text: 'Location', sortable: true, value: 'transportLocation.name' },
        { text: 'Active start date', sortable: false, value: 'activeStartDate' },
        { text: 'Active end date', sortable: false, value: 'activeEndDate' },
        { text: 'Action', sortable: false, value: 'actions' },
        { text: 'Refresh', sortable: false, value: 'refresh' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'planningMarkets',
      'logicalWarehouses',
      'warehouseSearchCriteria',
      'getUserRoles'
    ]),
    _headers () {
      if (!this.isTechAdmin(this.getUserRoles)) {
        return this.tableHeaders.filter(header => header.text !== 'Refresh')
      }
      return this.tableHeaders
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllPlanningMarkets'),
      this.$store.dispatch('getAllLogicalWarehouses')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetWarehouseSearchCriteria')
    },
    refresh (item) {
      return axios.put('/api/touchpoint/logical-warehouses/refresh/' + item.sourceSystemKey)
        .then(response => {
          this.refreshDialog = true
        })
        .catch(e => console.log(e))
    },
    closeRefreshDialog () {
      this.refreshDialog = false
      this.search()
    },
    showViewDialog (logicalWarehouse) {
      this.viewLogicalWarehouse = logicalWarehouse
      this.viewDialog = true
    }
  }
}
</script>

<style scoped>
</style>
