<template>
  <v-text-field
    type="number"
    filled
    :label="itemLabel"
    :rules="theRules"
    :validate-on-blur=true
    v-model.number="theValue"
    @blur="$emit('blur', theValue)"
    clearable>
    <v-icon slot="append" color="green" @click="increment">
      mdi-plus
    </v-icon>
    <v-icon slot="append" color="red" @click="decrement">
      mdi-minus
    </v-icon>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseInputNumberField',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    value: {
      type: [String, Number],
      require: false
    },
    rules: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      theValue: '',
      theRules: [],
      positive: value => (value !== '' && value !== null && value >= 0) || 'Required zero or positive number.'
    }
  },
  mounted () {
    this.theValue = this.value
    this.theRules.push(this.positive)
    if (this.rules && this.rules.length > 0) {
      this.theRules = this.rules.concat(this.theRules)
    }
  },
  watch: {
    value (val) {
      this.theValue = val
    }
  },
  methods: {
    increment () {
      this.theValue++
      this.$emit('blur', this.theValue)
    },
    decrement () {
      if (this.theValue > 0) {
        this.theValue--
        this.$emit('blur', this.theValue)
      }
    }
  }
}
</script>

<style scoped>

</style>
