<template>
  <v-container>
    <v-form ref="final-destination-lead-time-update-form">
      <v-row>
        <v-col class="mt-8">
          <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
            <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
              <li>{{validationErrorMessage.message}}</li>
            </ul>
          </v-alert>
          <v-card class="mb-6">
            <base-card-title
              label="Update final destination lead time"
              icon="mdi-calendar-export"/>
            <div class="text-h6 mb-3 mt-4 ml-4">Destination details</div>
            <v-row >
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Location"
                  :items="locations"
                  item-country="location.code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="finalDestinationLeadTime.locationId"/>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-read-only-text-field
                  item-label="Shipping MoT"
                  :value="selectedFreightServices"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-multi-select-with-flag
                  :items="warehouses"
                  item-value="id"
                  item-text="description"
                  item-country="geographicalCountry.code"
                  item-label="Warehouses"
                  :rules="[rules.selected]"
                  :selected-values="finalDestinationLeadTime.warehouseIds"
                  @change="finalDestinationLeadTime.warehouseIds = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Lead times</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Warehouse to allocated days"
                  :value="finalDestinationLeadTime.inWarehouseToAllocatedDays"
                  @blur="finalDestinationLeadTime.inWarehouseToAllocatedDays = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Allocated to sellable days"
                  :value="finalDestinationLeadTime.allocatedToSellableDays"
                  @blur="finalDestinationLeadTime.allocatedToSellableDays = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Quick demand deduction</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Quick demand deduction"
                  :value="finalDestinationLeadTime.quickDemandDeduction"
                  @blur="finalDestinationLeadTime.quickDemandDeduction = arguments[0]"/>
              </v-col>
            </v-row>

            <div class="text-h6 mb-3 ml-4 mb-6">Valid period</div>

            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker-read-only v-if="!replace"
                  item-label="Valid from"
                  :value="finalDestinationLeadTime.validFrom"
                  @change="finalDestinationLeadTime.validFrom = arguments[0]"/>
                <base-input-select-date-picker v-if="replace"
                   item-label="Valid from"
                   :selected-value="finalDestinationLeadTime.validFrom"
                   :rules="[rules.required , rules.fromDateIsBeforeMaxLLtFromDate]"
                   @change="finalDestinationLeadTime.validFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :rules="[rules.fromDateIsBeforeToDate]"
                  :selected-value="finalDestinationLeadTime.validTo"
                  @change="finalDestinationLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            label="update"
            icon="mdi-arrow-right-bold-circle"
            @click="save" />

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>

        <v-col cols="5" class="mt-8">
          <create-final-destination-lead-time-line
            :final-destination-lead-time="finalDestinationLeadTime"
            :locations="locations"
            :freight-services="freightServices"
            :warehouses="warehouses"/>
        </v-col>
      </v-row>
    </v-form>
    <base-dialog-update
      label="Final destination lead time has been updated successfully!"
      @create="createNew"
      @search="search"
      :send-dialog="sendDialog"/>
    <affected-logistic-lead-times-view-dialog
      :view-dialog="viewDialog"
      :view-affected-logistic-lead-times="viewAffectedLogisticLeadTimes"
      @cancel="viewDialog = false"
      @confirm="updateFinalDestinationLeadTime"/>
    <base-dialog-ok :label="warningMessage"
                    :show-icon="false"
                    :show-warning-icon="true"
                    :dialog="warningDialog"
                    @ok="() => this.warningDialog = false"/>
    <replace-final-destination-lead-time-dialog
      :dialog="showReplaceDialog"
      :createLLtsDialog="createLLtsDialog"
      @replace="doReplace"
      @close="abortReplace"
      :show-results-dialog="createLLtsResultDialog"/>

  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import CreateFinalDestinationLeadTimeLine from '@/components/finaldestinationleadtime/createfinaldestinationleadtime/CreateFinalDestinationLeadTimeLine'
import axios from 'axios'
import BaseInputNumberField from '@/base/input/BaseInputNumberField'
import BaseDialogUpdate from '@/base/dialog/BaseDialogUpdate'
import AffectedLogisticLeadTimesViewDialog from '@/views/logisticsleadtime/AffectedLogisticLeadTimesViewDialog'
import BaseInputSelectWithFlagReadOnly from '@/base/input/BaseInputSelectWithFlagReadOnly'
import { validateDate } from '@/validation/customvalidation'
import BaseInputReadOnlyTextField from '@/base/input/BaseInputReadOnlyTextField'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import { max } from 'lodash'
import BaseDialogOk from '@/base/dialog/BaseDialogOk.vue'
import moment from 'moment/moment'
import ReplaceFinalDestinationLeadTimeDialog
  from '@/components/finaldestinationleadtime/replace/ReplaceFinalDestinationLeadTimeDialog.vue'

export default {
  name: 'UpdateFinalDestinationLeadTime',
  components: {
    ReplaceFinalDestinationLeadTimeDialog,
    BaseDialogOk,
    BaseInputReadOnlyTextField,
    BaseInputSelectWithFlagReadOnly,
    AffectedLogisticLeadTimesViewDialog,
    BaseDialogUpdate,
    BaseInputNumberField,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseInputSelectDatePicker,
    BaseInputMultiSelectWithFlag,
    CreateFinalDestinationLeadTimeLine,
    BaseInputSelectDatePickerReadOnly
  },
  data () {
    return {
      reRenderMultiSelect: false,
      validationErrorMessages: [],
      locations: [],
      warehouses: [],
      freightServices: [],
      groupedFreightServices: [],
      finalDestinationLeadTime: {
        id: this.$route.params.id,
        locationId: '',
        warehouseIds: [],
        inboundFreightServiceIds: [],
        inWarehouseToAllocatedDays: '',
        allocatedToSellableDays: '',
        validFrom: '',
        validTo: '',
        quickDemandDeduction: ''
      },
      sendDialog: false,
      viewDialog: false,
      showReplaceDialog: false,
      createLLtsDialog: false,
      createLLtsResultDialog: false,
      viewAffectedLogisticLeadTimes: [],
      affectedMaxLltValidFromDate: '',
      warningDialog: false,
      warningMessage: '',
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.finalDestinationLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.',
        fromDateIsBeforeMaxLLtFromDate: value => {
          if (this.replace) {
            const msg = 'Valid from date must be greater than connected max Logistic lead time from date.'
            if (this.affectedMaxLltValidFromDate === value) {
              return msg
            }
            return validateDate(this.affectedMaxLltValidFromDate, value) || msg
          }
        }
      }
    }
  },
  props: {
    replace: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    selectedFreightServices () {
      const selectedFreightServices = []
      if (this.freightServices) {
        this.freightServices.forEach(item => {
          if (this.finalDestinationLeadTime.inboundFreightServiceIds.includes(item.id)) {
            selectedFreightServices.push(item)
          }
        })

        if (selectedFreightServices.length > 1) {
          return selectedFreightServices[0].name
            .concat(' (+')
            .concat(selectedFreightServices.length - 1)
            .concat(' others)')
        } else if (selectedFreightServices.length === 1) {
          return selectedFreightServices[0].name
        }
      }
      return ''
    }
  },
  beforeCreate () {
    axios.get('/api/touchpoint/transport-location/place-of-discharge')
      .then(response => ([...response.data]).forEach(data => this.locations.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/grouped-freight-services')
      .then(response => ([...response.data]).forEach(data => this.groupedFreightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/final-destination-lead-time/' + this.$route.params.id)
      .then((response) => {
        this.finalDestinationLeadTime.locationId = response.data.location.id
        this.finalDestinationLeadTime.warehouseIds = response.data.logicalWarehouses.map(item => item.id)
        this.warehouses = response.data.logicalWarehouses
        this.finalDestinationLeadTime.inboundFreightServiceIds = response.data.inboundFreightServices.map(item => item.id)
        this.finalDestinationLeadTime.inWarehouseToAllocatedDays = response.data.inWarehouseToAllocated
        this.finalDestinationLeadTime.allocatedToSellableDays = response.data.allocatedToSellable
        this.finalDestinationLeadTime.validFrom = response.data.validFrom
        this.finalDestinationLeadTime.validTo = response.data.validTo
        this.finalDestinationLeadTime.quickDemandDeduction = response.data.quickDemandDeduction

        this.getWarehousesFor(this.finalDestinationLeadTime.locationId)
        this.reRenderMultiSelect = true
      }).catch(e => console.log(e))

    axios.get('/api/touchpoint/logistic-lead-time/final-destination-lead-time/' + this.$route.params.id)
      .then((response) => {
        if (this.replace) {
          this.viewAffectedLogisticLeadTimes = response.data.filter(sllt => sllt.validTo == null)
        } else {
          this.viewAffectedLogisticLeadTimes = response.data
        }
        const allValidFromDates = [...response.data.map(sllt => sllt.validFrom)]
        this.affectedMaxLltValidFromDate = max(allValidFromDates)
        if (this.replace) {
          this.finalDestinationLeadTime.validFrom = moment(this.affectedMaxLltValidFromDate).add(1, 'days').format('YYYY-MM-DD')
        }
      })
      .catch(e => console.log(e))
  },
  methods: {
    validate () {
      return this.$refs['final-destination-lead-time-update-form'].validate()
    },
    getWarehousesFor (locationId) {
      if (!locationId) {
        this.warehouses = []
        return
      }
      axios.get('/api/touchpoint/logical-warehouse/location/' + locationId)
        .then(response => ([...response.data]).forEach(data => this.warehouses.push(data)))
        .catch(e => console.log(e))
    },
    updateFinalDestinationLeadTime () {
      this.viewDialog = false
      if (this.replace) {
        this.createLLtsDialog = true
        this.showReplaceDialog = true
      } else {
        axios.put('/api/touchpoint/final-destination-lead-time', this.finalDestinationLeadTime)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      }
    },
    save () {
      if (this.validate()) {
        if (this.viewAffectedLogisticLeadTimes.length === 0) {
          this.updateFinalDestinationLeadTime()
        } else {
          if (this.finalDestinationLeadTime.validTo) {
            this.validateActiveSllt(this.finalDestinationLeadTime.validTo)
          }
          if (this.replace && this.finalDestinationLeadTime.validFrom) {
            this.validateActiveSllt(moment(this.finalDestinationLeadTime.validFrom).subtract(1, 'days').format('YYYY-MM-DD'))
          }
          this.viewAffectedLLtsDialog(this.warningDialog)
        }
      } else {
        window.scrollTo(0, 0)
      }
    },
    doReplace () {
      this.showReplaceDialog = false
      this.createLLtsDialog = false
      axios.put('/api/touchpoint/final-destination-lead-time/replace', {
        finalDestinationLeadTimeCreateCommand: this.finalDestinationLeadTime,
        finalDestinationLeadTimeUpdateCommand: this.finalDestinationLeadTime
      })
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.showReplaceDialog = true
            this.createLLtsResultDialog = true
          } else {
            this.validationErrorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },
    abortReplace () {
      this.createLLtsDialog = false
      this.showReplaceDialog = false
    },
    validateActiveSllt: function (date) {
      const finalDestinationEndDate = new Date(date)
      const lltExistWithFutureStartDate = this.viewAffectedLogisticLeadTimes.some(sllt => new Date(sllt.validFrom) > finalDestinationEndDate)
      if (lltExistWithFutureStartDate) {
        this.warningMessage = `Final destination lead time cannot end on '${date}' as there are connected SLLT valid from '${this.affectedMaxLltValidFromDate}'`
        this.warningDialog = true
        this.viewDialog = false
      }
    },
    viewAffectedLLtsDialog () {
      if (!this.warningDialog) {
        this.viewDialog = true
      }
    },
    cancel () {
      this.finalDestinationLeadTime = {
        locationId: '',
        warehouseIds: [],
        inboundFreightServiceIds: [],
        inWarehouseToAllocatedDays: '',
        allocatedToSellableDays: '',
        validFrom: '',
        validTo: '',
        quickDemandDeduction: ''
      }
      this.$router.push({ name: 'SearchFinalDestinationLeadTime' })
    },
    createNew () {
      this.$router.push({ name: 'CreateFinalDestinationLeadTime' })
    },
    search () {
      this.$router.push({ name: 'SearchFinalDestinationLeadTime' })
    }
  }
}
</script>

<style>

</style>
