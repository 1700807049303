<template>
  <v-container v-if="isLoaded">
    <v-card>
      <v-card-title>
        View planning markets
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="_headers"
        :items="planningMarkets"
        :items-per-page="50"
        :search="search">

        <template v-slot:item.refresh="{ item }">
          <v-btn icon @click="refresh(item)">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-card>
    <base-dialog-ok
      label="Entity has been refresh!"
      :dialog="refreshDialog"
      @ok="() => refreshDialog = false"/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import axios from 'axios'
import { security } from '@/mixins/utilsMixin'

export default {
  name: 'ViewPlanningMarkets',
  components: { BaseDialogOk },
  mixins: [security],
  data () {
    return {
      isLoaded: false,
      search: '',
      refreshDialog: false,
      tableHeaders: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Company', sortable: true, value: 'company' },
        { text: 'Code', sortable: true, value: 'code' },
        { text: 'Abbreviation', sortable: false, value: 'abbreviation' },
        { text: 'Short Name', sortable: false, value: 'shortName' },
        { text: 'Country', sortable: false, value: 'logisticCountry.name' },
        { text: 'Channel', sortable: false, value: 'channel.name' },
        { text: 'CorporateBrand', sortable: false, value: 'corporateBrand.name' },
        { text: 'Refresh', align: 'start', sortable: false, value: 'refresh' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'corporateBrands',
      'planningMarkets',
      'getUserRoles'
    ]),
    _headers () {
      if (!this.isTechAdmin(this.getUserRoles)) {
        return this.tableHeaders.filter(header => header.text !== 'Refresh')
      }
      return this.tableHeaders
    }
  },
  async mounted () {
    await this.$store.dispatch('getAllPlanningMarkets')
      .then(() => { this.isLoaded = true })
  },
  methods: {
    refresh (item) {
      return axios.get('/api/touchpoint/planningmarket/refresh/' + item.sourceSystemKey)
        .then(response => {
          const index = this.planningMarkets.findIndex(pm => pm.id === response.data.id)
          this.fetchCorporateBrandById(response.data)
          this.planningMarkets.splice(index, 1, response.data)
          this.refreshDialog = true
        })
        .catch(e => console.log(e))
    },
    fetchCorporateBrandById (planingMarket) {
      const brand = this.corporateBrands.filter(pm => pm.corporateBrandId.toString() === planingMarket.corporateBrandId)
        .map(pm => pm.name)
      planingMarket.brand = brand
    }
  }
}
</script>

<style scoped>

</style>
