<template>
  <v-container v-if="isLoaded">
    <v-card>
      <v-card-title>
        View freight service
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="tableHeaders"
        :items="freightServices"
        :items-per-page="50"
        :search="search"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ViewFreightService',
  data () {
    return {
      isLoaded: false,
      search: '',
      tableHeaders: [
        {
          text: 'SdsId',
          align: 'start',
          sortable: true,
          value: 'sdsId'
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Is MultiModal',
          sortable: true,
          value: 'multiModal'
        },
        {
          text: 'Transport Mode',
          sortable: true,
          value: 'transportModeName'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'freightServices'
    ])
  },
  async mounted () {
    await this.$store.dispatch('getAllFreightServices').then(() => { this.isLoaded = true })
  }
}
</script>

<style scoped>

</style>
