<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search additional shipping lead time"
        icon="mdi-calendar-clock"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
                :items="placesOfLoading"
                item-value="id"
                item-text="description"
                item-country="location.code"
                item-label="Place of loading"
                :selected-value="additionalShippingLeadTimeSearchCriteria.placeOfLoadingId"
                @change="additionalShippingLeadTimeSearchCriteria.placeOfLoadingId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
                :items="placesOfDelivery"
                item-value="id"
                item-text="description"
                item-country="location.code"
                item-label="Place of delivery"
                :selected-value="additionalShippingLeadTimeSearchCriteria.placeOfDeliveryId"
                @change="additionalShippingLeadTimeSearchCriteria.placeOfDeliveryId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Place of loading country"
              :selected-value="additionalShippingLeadTimeSearchCriteria.placeOfLoadingCountryId"
              @change="additionalShippingLeadTimeSearchCriteria.placeOfLoadingCountryId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placeOfDeliveryCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Place of delivery country"
              :selected-value="additionalShippingLeadTimeSearchCriteria.placeOfDeliveryCountryId"
              @change="additionalShippingLeadTimeSearchCriteria.placeOfDeliveryCountryId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
              :grouped-freight-services="groupedFreightServices"
              :freight-services="freightServices"
              item-label="Mode of transport"
              :selected-value="additionalShippingLeadTimeSearchCriteria.freightServiceIds"
              @change="additionalShippingLeadTimeSearchCriteria.freightServiceIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
                class="mt-0"
                v-model="additionalShippingLeadTimeSearchCriteria.active"
                input-value="searchCriteria.active"
                @change="additionalShippingLeadTimeSearchCriteria.active = arguments[0]"
                label="Only active additional shipping lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="mt-2">
      <base-input-search-and-clear-buttons
        class="d-inline"
        @search="search"
        @reset="reset"/>
    </div>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="additionalShippingLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="additionalShippingLeadTimeSearchResults"
        url="/api/touchpoint/additional-shipping-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.placeOfLoading="{ item }">
          <v-tooltip bottom color="red" v-if="item.placeOfLoading">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                 <base-label-with-flag :item-country="item.placeOfLoading.location.code" :item-label="item.placeOfLoading.unlocode"/>
              </span>
            </template>
            <span>{{item.placeOfLoading.description}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.placeOfDelivery="{ item }">
          <v-tooltip bottom color="red" v-if="item.placeOfDelivery">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <base-label-with-flag :item-country="item.placeOfDelivery.location.code" :item-label="item.placeOfDelivery.unlocode"/>
              </span>
            </template>
            <span>{{item.placeOfDelivery.description}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.placeOfLoadingCountry="{ item }">
          <v-tooltip bottom color="red" v-if="item.placeOfLoadingCountry" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                 <base-label-with-flag :item-country="item.placeOfLoadingCountry.code" :item-label="item.placeOfLoadingCountry.code"/>
              </span>
            </template>
            <span>{{item.placeOfLoadingCountry.name}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.placeOfDeliveryCountry="{ item }">
          <v-tooltip bottom color="red" v-if="item.placeOfDeliveryCountry">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                 <base-label-with-flag :item-country="item.placeOfDeliveryCountry.code" :item-label="item.placeOfDeliveryCountry.code"/>
              </span>
            </template>
            <span>{{item.placeOfDeliveryCountry.name}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.freightServices="{ item }">
          <base-freight-service-with-tooltip :inbound-freight-services="item.freightServices"/>
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
              v-if="isAdmin(getUserRoles)"
              label="Edit"
              icon="mdi-pencil"
              @click="update(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>
  </v-container>
</template>

<script>
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import { arraysUtils, security } from '@/mixins/utilsMixin'
import { mapGetters } from 'vuex'
import BaseFreightServiceWithTooltip from '@/base/tooltip/BaseFreightServiceWithTooltip'
import BaseToolTip from '@/base/tooltip/BaseToolTip'

export default {
  name: 'SearchAdditionalShippingLeadTime',
  components: {
    BaseToolTip,
    BaseFreightServiceWithTooltip,
    BaseInputSelectWithFlag,
    BaseInputMultiSelectWithFreightService,
    BaseInputSearchAndClearButtons,
    BaseLabelWithFlag,
    BaseCardTitle,
    BaseTablePagedServerSide
  },
  mixins: [arraysUtils, security],
  data () {
    return {
      isLoaded: false,
      shippingLeadTimeId: '',
      validate: false,
      tableHeaders: [
        { text: 'Place of loading', align: 'start', sortable: true, value: 'placeOfLoading' },
        { text: 'Place of delivery', sortable: true, value: 'placeOfDelivery' },
        { text: 'Place of loading country', sortable: true, value: 'placeOfLoadingCountry' },
        { text: 'Place of delivery country', sortable: true, value: 'placeOfDeliveryCountry' },
        { text: 'Mode of transport', sortable: false, value: 'freightServices' },
        { text: 'Additional lead time in days', sortable: true, value: 'additionalLeadTimeInDays' },
        { text: 'Valid from', sortable: false, value: 'validFrom' },
        { text: 'Valid to', sortable: false, value: 'validTo' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'placesOfLoading',
      'geographicalCountries',
      'placesOfDelivery',
      'placeOfDeliveryCountries',
      'additionalShippingLeadTimeSearchCriteria',
      'getUserRoles'
    ])
  },

  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDelivery'),
      this.$store.dispatch('getPlaceOfDeliveryCountries'),
      this.$store.dispatch('getAllGeographicalCountries')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetAdditionalShippingLeadTimeSearchCriteria')
    },
    showViewDialog (shippingLeadTime) {
      this.viewDialogShippingLeadTime = shippingLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
      this.viewDialogShippingLeadTime = {}
    },
    update (item) {
      this.$router.push({ path: '/AdditionalShippingLeadTime/Update/' + item.id })
    },
    closeOkDialog () {
      this.showOkDialog = false
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
