<template>
  <v-text-field
    type="number"
    filled
    :label="itemLabel"
    :rules="theRules"
    :validate-on-blur=true
    v-model.number="theValue"
    @change="$emit('change', theValue)"
    dense
    flat
    outlined
    height="48px"
    hide-details="auto">
    <v-btn slot="append" class="mx-1" @click="increment"  elevation="0" fab dark x-small color="red darken-2">
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-btn slot="append" class="mx-1" @click="decrement" elevation="0" fab dark x-small color="red darken-2">
      <v-icon dark>
        mdi-minus
      </v-icon>
    </v-btn>
  </v-text-field>
</template>

<script>
export default {
  name: 'ShippingEventWeeksInput',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    value: {
      type: [String, Number],
      require: false
    },
    rules: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      theValue: '',
      theRules: [],
      positive: value => (value !== '' && value >= 1) || 'Required a positive number.'
    }
  },
  mounted () {
    this.theValue = this.value
    this.theRules.push(this.positive)
    if (this.rules && this.rules.length > 0) {
      this.theRules.concat(this.rules)
    }
  },
  watch: {
    value (val) {
      this.theValue = val
    }
  },
  methods: {
    increment () {
      this.theValue++
      this.$emit('change', this.theValue)
    },
    decrement () {
      if (this.theValue > 0) {
        this.theValue--
        this.$emit('change', this.theValue)
      }
    }
  }
}
</script>

<style scoped>

</style>
