import moment from 'moment'

const initState = () => {
  return {
    termsOfDeliverySearchCriteria: {
      externalId: '',
      countryOfDeliveryIds: [],
      destinationLogisticCountryIds: [],
      modeOfTransportIds: [],
      incoTermIds: [],
      packingModeIds: [],
      modeOfTransportCategoryIds: [],
      validFrom: '',
      validTo: '',
      validOn: moment().format('YYYY-MM-DD'),
      publishTimeFrom: '',
      publishTimeTo: '',
      published: null,
      active: true,
      hazMat: null
    },
    termsOfDeliverySearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchTermsOfDelivery = {
  state: initState(),
  mutations: {
    updateTermsOfDeliverySearchResults (state, searchResults) {
      state.termsOfDeliverySearchResults = searchResults
    },
    resetTermsOfDeliverySearchCriteria (state) {
      Object.assign(state.termsOfDeliverySearchCriteria, initState().termsOfDeliverySearchCriteria)
    }
  },
  getters: {
    termsOfDeliverySearchCriteria: state => state.termsOfDeliverySearchCriteria,
    termsOfDeliverySearchResults: state => state.termsOfDeliverySearchResults
  },
  actions: {
  }
}

export default searchTermsOfDelivery
