export const packingModeIconResolverMixin = {
  methods: {
    resolveIcon (packingMode) {
      if (packingMode && packingMode.name.toUpperCase() === 'HANGING') {
        return 'mdi-hanger'
      } else if (packingMode && packingMode.name.toUpperCase() === 'FLAT') {
        return 'mdi-iron'
      } else {
        return 'mdi-package-variant'
      }
    }
  }
}
