<template>
  <v-container :style="{ 'max-width': containerWidth }">
    <v-row>
      <v-col class="mt-8">
        <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="(validationErrorMessage, index) in validationErrorMessages" v-bind:key="index">
            <li>{{validationErrorMessage.message}}</li>
          </ul>
        </v-alert>
        <v-stepper v-model="stepper">
          <replace-shipping-lead-time-stepper-head
            :stepper="stepper"/>
          <v-divider></v-divider>
          <v-stepper-items>
            <v-stepper-content step="1">
              <replace-shipping-lead-time-step1
                ref="step1"
                :places-of-loading="placesOfLoading"
                :freight-services="freightServices"
                :shipping-lead-time-search-criteria="shippingLeadTimeSearchCriteria"
                @search="updateShippingLeadTimes"/>
            </v-stepper-content>

            <v-stepper-content step="2">
              <replace-shipping-lead-time-step2
                ref="step2"
                :shipping-lead-times="shippingLeadTimes"
                @updateReplaceShippingLeadTimes="replaceShippingLeadTimes = arguments[0]"/>
            </v-stepper-content>

            <v-stepper-content step="3">
              <replace-shipping-lead-time-step3
                :replace-shipping-lead-times="replaceShippingLeadTimes"/>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div class="mt-2">
        <base-input-primary-button v-if="stepper < 3"
            label="continue"
            icon="mdi-arrow-right-bold-circle"
            @click="nextStep"/>

          <base-input-primary-button v-if="stepper === 3" class="ml-2"
           label="DIRECT CREATE OF SLLTs"
           icon="mdi-arrow-right-bold-circle"
           @click="saveAndCreateLLts"/>

        <base-input-secondary-button
            v-if="stepper > 1"
            label="back"
            icon="mdi-arrow-left-bold-circle"
            @click="previousStep"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </div>
      </v-col>
    </v-row>
    <replace-shipping-lead-time-dialog-step4 :dialog="dialog"
                                             :createLLtsDailog="createLLtsDailog"
                                             @doAnotherCombination="doAnotherCombination"
                                             @createDirectLLts="createDirectLLts"
                                             @closeSaveAndCreateLLtsDialog="closeSaveAndCreateLLtsDialog"
                                             :create-l-lts-result-dailog="createLLtsResultDailog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import ReplaceShippingLeadTimeStepperHead from '@/components/shippingleadtime/replaceshippingleadtime/ReplaceShippingLeadTimeStepperHead'
import ReplaceShippingLeadTimeStep1 from '@/components/shippingleadtime/replaceshippingleadtime/ReplaceShippingLeadTimeStep1'
import ReplaceShippingLeadTimeStep2 from '@/components/shippingleadtime/replaceshippingleadtime/ReplaceShippingLeadTimeStep2'
import ReplaceShippingLeadTimeStep3 from '@/components/shippingleadtime/replaceshippingleadtime/ReplaceShippingLeadTimeStep3'
import ReplaceShippingLeadTimeDialogStep4 from '@/components/shippingleadtime/replaceshippingleadtime/ReplaceShippingLeadTimeDialogStep4'
import { mapGetters } from 'vuex'

export default {
  name: 'ReplaceShippingLeadTimes',
  components: {
    ReplaceShippingLeadTimeStep3,
    ReplaceShippingLeadTimeStep2,
    ReplaceShippingLeadTimeStep1,
    ReplaceShippingLeadTimeStepperHead,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    ReplaceShippingLeadTimeDialogStep4
  },
  props: {
    copyShippingLeadTimeFrom: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      dialog: false,
      createLLtsDailog: false,
      createLLtsResultDailog: false,
      stepper: 1,
      shippingLeadTimes: [],
      replaceShippingLeadTimes: [],
      shippingLeadTimeSearchCriteria: {
        placeOfLoadingId: '',
        freightServiceId: ''
      },
      validationErrorMessages: []
    }
  },
  computed: {
    ...mapGetters([
      'placesOfLoading',
      'freightServices',
      'serviceProviders',
      'carriers',
      'serviceCodes'
    ]),
    containerWidth () {
      if (this.stepper === 1) {
        return '650px'
      } else {
        return '100%'
      }
    }
  },
  methods: {
    updateShippingLeadTimes (results) {
      this.shippingLeadTimes = results
      this.$refs.step2.clear()
    },
    nextStep () {
      if (this.stepper === 1) {
        if (this.$refs.step1.validate()) {
          this.$refs.step1.loadResults()
            .then(() => {
              if (this.shippingLeadTimes.length > 0) {
                this.stepper++
                this.$refs.step2.clearValidation()
              }
            })
        }
      } else if (this.stepper === 2) {
        window.scrollTo(0, 0)
        if (this.$refs.step2.validate()) {
          this.stepper++
        }
      }
    },
    previousStep () {
      this.stepper--
      if (this.stepper === 1) {
        this.replaceShippingLeadTimes = []
        this.validationErrorMessages = []
      }
    },
    getEvents (shippingLeadTimeCreateCommand) {
      const events = []
      shippingLeadTimeCreateCommand.shippingEvents
        .forEach(eventValue => {
          events.push({
            shippingEventTypeId: eventValue.shippingEventTypeId || eventValue.type.id,
            dayOfWeek: eventValue.dayOfWeek,
            weekNumber: eventValue.weekNumber,
            note: eventValue.note
          })
        })

      return events
    },
    setUpCommandForEndedShippingLeadTime (value) {
      return {
        validFrom: value.validFrom,
        end: value.end
      }
    },
    setUpCommandForShippingLeadTime (value) {
      return {
        id: value.id,
        countryOfDeliveryId: value.countryOfDelivery.id,
        placeOfLoadingId: value.placeOfLoading.id,
        placeOfDeliveryId: value.placeOfDelivery.id,
        freightServiceId: value.freightService.id,
        serviceProviderId: value.serviceProvider.id,
        carrierId: value.carrier ? value.carrier.id : null,
        service: value.service,
        validFrom: value.validFrom,
        main: value.main,
        defaultForCountryOfDelivery: value.defaultForCountryOfDelivery,
        serviceCode: value.serviceCode ? value.serviceCode.name : null,
        shippingLeadTimeEvents: this.getEvents(value),
        predecessor: value.predecessor
      }
    },

    saveAndCreateLLts () {
      this.validationErrorMessages = []
      this.createLLtsDailog = true
      this.dialog = true
    },

    closeSaveAndCreateLLtsDialog () {
      this.createLLtsDailog = false
      this.dialog = false
    },

    createDirectLLts () {
      this.createLLtsDailog = false
      this.dialog = false
      this.validationErrorMessages = []
      const data = []
      this.replaceShippingLeadTimes
        .filter(value => !value.shippingLeadTimeCreateCommand.exclude)
        .forEach(value => {
          data.push({
            createShippingLeadTimeCommand: value.shippingLeadTimeCreateCommand.end
              ? this.setUpCommandForEndedShippingLeadTime(value.shippingLeadTimeCreateCommand)
              : this.setUpCommandForShippingLeadTime(value.shippingLeadTimeCreateCommand),
            updateShippingLeadTimeCommand: this.setUpCommandForShippingLeadTime(value.shippingLeadTimeUpdateCommand)
          })
        })
      axios.put('/api/touchpoint/shipping-lead-time/replace-and-create-logistic-lead-time', data)
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.dialog = true
            this.createLLtsResultDailog = true
          } else {
            this.validationErrorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },

    cancel () {
      if (this.stepper === 1) {
        this.$router.push({ name: 'Home' })
      } else {
        this.stepper = 1
        this.shippingLeadTimeSearchCriteria.freightServiceId = ''
        this.shippingLeadTimeSearchCriteria.placeOfLoadingId = ''
        this.replaceShippingLeadTimes = []
        this.shippingLeadTimes = []
        this.validationErrorMessages = []
      }
    },

    doAnotherCombination () {
      this.dialog = false
      this.createLLtsResultDailog = false
      this.createLLtsDailog = false
      this.cancel()
    }
  },
  async mounted () {
    axios.all([
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllServiceProviders'),
      this.$store.dispatch('getAllCarriers'),
      this.$store.dispatch('getAllServiceCodes')
    ]).then()
  }
}
</script>

<style scoped>
</style>
