<template>
  <v-container>
    <v-row>
      <v-col class="mt-8">
        <v-card class="mb-6">
          <base-card-title
            label="Planning Market BaseLoad"
            icon="mdi-calendar-export"/>
          <div class="text-h6 mb-3 mt-4 ml-4">Planning Market details</div>
          <v-row >
            <v-form ref="planning-market-baseload-form">
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-text-field
                  item-label="Enter season code"
                  :value="search.seasonCode"
                  :rules="[rules.required]"
                  hint="Enter season code"
                  @blur="search.seasonCode = arguments[0]"/>
              </v-col>
            </v-form>
          </v-row>
        </v-card>

        <base-input-primary-button
          label="Start BaseLoad"
          icon="mdi-arrow-right-bold-circle"
          @click="start" />
      </v-col>

      <v-col cols="5" class="mt-8">
        <base-load-status
          :base-load-statuses="baseLoadStatuses"
          @baseLoadUpdate="baseLoadStatusRefresh"
          complete-url="/api/touchpoint/planning-markets/base-loads/complete"
          cancel-url="/api/touchpoint/planning-markets/base-loads/cancel"/>
      </v-col>

      <base-dialog-ok
        :dialog="dialogStartBaseLoad"
        label="Base load has been submitted"
        @ok="() => this.dialogStartBaseLoad = false"/>

    </v-row>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseLoadStatus from '@/components/baseload/BaseLoadStatus'
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseInputTextField from '@/base/input/BaseInputTextField'
export default {
  name: 'PlanningMarketBaseload',
  components: {
    BaseInputTextField,
    BaseDialogOk,
    BaseLoadStatus,
    BaseInputPrimaryButton,
    BaseCardTitle
  },
  data () {
    return {
      search: {
        seasonCode: ''
      },
      baseLoadStatuses: {},
      dialogStartBaseLoad: false,
      baseLoadRefreshRequest: {},
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  mounted () {
    this.updateBaseLoadStatus()
    this.baseLoadRefreshRequest = setInterval(this.updateBaseLoadStatus, 5000)
  },
  beforeDestroy () {
    clearInterval(this.baseLoadRefreshRequest)
  },
  methods: {
    validate () {
      return this.$refs['planning-market-baseload-form'].validate()
    },
    start () {
      if (this.validate()) {
        axios.post('/api/touchpoint/planning-markets/base-loads/run', this.search)
          .then((response) => {
            this.updateBaseLoadStatus()
            this.dialogStartBaseLoad = true
          })
          .catch(e => console.log(e))
      }
    },
    updateBaseLoadStatus () {
      axios.get('/api/touchpoint/planning-markets/base-loads/statuses', { headers: { silent: 'silent' } })
        .then((response) => { this.baseLoadStatuses = response.data })
        .catch(e => console.log(e))
    },
    baseLoadStatusRefresh (baseLoads) {
      this.baseLoadStatuses = baseLoads
    }
  }

}
</script>

<style scoped>

</style>
