<template>
  <update-final-destination-lead-time :replace=true></update-final-destination-lead-time>
</template>

<script>
import UpdateFinalDestinationLeadTime from '@/views/finaldestinationleadtime/UpdateFinalDestinationLeadTime.vue'

export default {
  name: 'ReplaceFinalDestinationLeadTime',
  components: {
    UpdateFinalDestinationLeadTime
  }
}
</script>

<style scoped>

</style>
