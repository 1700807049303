<template>
  <div>
    <v-select
      filled
      :items="freightServices"
      item-text="name"
      item-value="id"
      :label="itemLabel"
      v-model="selectValueItem"
      :prepend-icon="resolveIcon(selectedFreightService)"
      disabled
      :hint="hint"
      persistent-hint
      clearable>
      <template v-slot:item="{ item } ">
        <span v-for="(transportMode, index) in item.transportModes" v-bind:key="index" >
          <v-icon>{{ resolveIcon(transportMode) }}</v-icon>
          <span v-if="index !== Object.keys(item.transportModes).length - 1">
            <v-icon class="">mdi-chevron-right</v-icon>
          </span>
        </span>
         <span class="ml-2 mt-3 mb-3">{{ item.name }}</span>
      </template>
      <template v-slot:prepend v-if="selectedFreightService">
        <span v-for="(transportMode, index) in selectedFreightService.transportModes" v-bind:key="index">
          <v-icon class="mr-1">{{ resolveIcon(transportMode) }}</v-icon>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'
export default {
  name: 'BaseInputSelectWithFreightServiceReadOnly',
  mixins: [transportModeIconResolverMixin],
  props: {
    freightServices: {
      type: Array,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      type: String,
      require: false
    }
  },
  data () {
    return {
      selectValueItem: ''
    }
  },
  computed: {
    selectedFreightService () {
      return this.freightServices.find(freightService => freightService.id === this.selectValueItem)
    },
    hint () {
      return this.itemLabel + ' is not allowed to be updated'
    }
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  mounted () {
    this.selectValueItem = this.value
  }
}
</script>

<style scoped>

</style>
