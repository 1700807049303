<template>
  <v-dialog
      v-model="showDialog"
      scrollable
      persistent
      max-width="700px"
      @keydown.esc="close">
    <v-container>
      <create-shipping-lead-time-step2
          ref="events"
          :selected-freight-service-id="selectedFreightServiceId"
          :freight-services="freightServices"
          :shipping-events="shippingLeadTimeEvents"
          @change="updateEvents" />

      <div class="pb-5">
        <base-input-primary-button
            label="save"
            icon="mdi-arrow-right-bold-circle"
            @click="saveUpdatedEvents"/>

        <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="close"/>
      </div>
    </v-container>
  </v-dialog>
</template>
<script>

import CreateShippingLeadTimeStep2 from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep2'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import goTo from 'vuetify/lib/services/goto'
import { sortBy } from 'lodash'

export default {
  name: 'ReplaceShippingLeadTimeEventsDialog',
  components: { BaseInputSecondaryButton, BaseInputPrimaryButton, CreateShippingLeadTimeStep2 },
  props: {
    shippingLeadTimeEvents: {
      type: Array,
      require: true
    },
    showDialog: {
      type: Boolean,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    selectedFreightServiceId: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      updatedShippingEvents: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    updateEvents (events) {
      this.updatedShippingEvents = [...events]
    },
    saveUpdatedEvents () {
      if (this.$refs.events.validate()) {
        const sortedEventTypes = sortBy(this.updatedShippingEvents, ['weekNumber', 'dayOfWeek'])
        this.$emit('updateEvents', sortedEventTypes)
      } else {
        goTo('.v-form', { container: '.v-dialog--active', easing: 'linear' })
      }
    }
  }
}
</script>

<style scoped>

.v-dialog.v-dialog--active .v-form  {
  background-color: #fff;
}

</style>
