var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('v-container',[_c('v-card',{staticClass:"mt-5"},[_c('base-card-title',{attrs:{"label":"Search warehouse","icon":"mdi-warehouse"}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('base-input-select',{attrs:{"items":_vm.logicalWarehouses,"item-text":"warehouseId","item-value":"warehouseId","item-label":"Warehouse id","selected-value":_vm.warehouseSearchCriteria.warehouseId},on:{"change":function($event){_vm.warehouseSearchCriteria.warehouseId = arguments[0]}}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('base-input-select-with-flag',{attrs:{"items":_vm.logicalWarehouses,"item-text":"description","item-value":"currentWarehouseCode","item-label":"Warehouse codes","item-country":"geographicalCountry.code","selected-value":_vm.warehouseSearchCriteria.warehouseCode},on:{"change":function($event){_vm.warehouseSearchCriteria.warehouseCode = arguments[0]}}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('base-input-select-with-flag',{attrs:{"items":_vm.planningMarkets,"item-value":"id","item-text":"description","item-country":"logisticCountry.code","item-label":"Planning market","selected-value":_vm.warehouseSearchCriteria.planningMarketId},on:{"change":function($event){_vm.warehouseSearchCriteria.planningMarketId = arguments[0]}}})],1)],1)],1)],1),_c('base-input-search-and-clear-buttons',{on:{"search":_vm.search,"reset":_vm.reset}}),_c('v-card',{staticClass:"mt-10"},[_c('base-table-paged-server-side',{ref:"table",attrs:{"search-criteria":_vm.warehouseSearchCriteria,"table-headers":_vm._headers,"search-result-name":"warehouseSearchResults","url":"/api/touchpoint/logical-warehouse/criteria-search"},scopedSlots:_vm._u([{key:"item.geographicalCountry",fn:function(ref){
var item = ref.item;
return [_c('base-label-with-flag',{attrs:{"item-country":item.geographicalCountry.code,"item-label":item.geographicalCountry.name}})]}},{key:"item.activeStartDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.activeStartDate))+" ")]}},{key:"item.activeEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.activeEndDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('base-tool-tip',{attrs:{"label":"View detail","icon":"mdi-magnify"},on:{"click":function($event){return _vm.showViewDialog(item)}}})]}},(_vm.isTechAdmin(_vm.getUserRoles))?{key:"item.refresh",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.refresh(item)}}},[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}:null],null,true)})],1),_c('base-dialog-ok',{attrs:{"label":"Entity has been refreshed!","dialog":_vm.refreshDialog},on:{"ok":_vm.closeRefreshDialog}}),_c('logical-warehouse-view-details-dialog',{attrs:{"logical-warehouse":_vm.viewLogicalWarehouse,"view-dialog":_vm.viewDialog},on:{"close":function () { return this$1.viewDialog = false; }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }