<template>
  <v-form
    v-if="replaceShippingLeadTimes.length > 0"
    ref="shipping-lead-time-replace-step3-form">
    <v-card
      elevation="0"
      class="mb-6">

      <div class="mb-5">Verify the new shipping lead time versus the current ones.</div>

      <v-row>
        <v-col cols="2">
          Place of loading
        </v-col>
        <v-col cols="4">
          <base-label-with-flag
            v-if="placeOfLoading"
            :item-label="placeOfLoading.description"
            :item-country="placeOfLoading.location.code"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          Freight service
        </v-col>
        <v-col cols="4">
          <base-label-with-freight-service
            v-if="freightService"
            :item-label="freightService.description"
            :freight-service="freightService"/>
        </v-col>
      </v-row>
    </v-card>
    <v-divider class="mb-6"></v-divider>

    <v-data-table
      :headers="tableHeaders"
      :items="replaceShippingLeadTimes"
      :items-per-page="-1"
      :hide-default-footer="true"
      :item-class="isExcludeOrEnding"
      class="elevation-0">
      <template v-slot:item.placeOfDelivery="{ item }">
        <base-tool-tip2>
          <base-label-with-flag
            :item-country="item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code"
            :item-label="item.shippingLeadTimeCreateCommand.placeOfDelivery.unlocode"/>
          <template v-slot:tooltip>
            <base-label-with-flag
              :item-country="item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code"
              :item-label="item.shippingLeadTimeCreateCommand.placeOfDelivery.description"/>
          </template>
        </base-tool-tip2>
      </template>
      <template v-slot:item.default="{ item }">
        <label v-if="item.shippingLeadTimeCreateCommand.defaultForCountryOfDelivery">Yes</label><label v-else>No</label>
      </template>
      <template v-slot:item.serviceProvider="{ item }">
        <label v-if="twoValuesAreNotEqual(item.shippingLeadTimeUpdateCommand.serviceProvider.code, item.shippingLeadTimeCreateCommand.serviceProvider.code)" class="yellow">
          {{item.shippingLeadTimeUpdateCommand.serviceProvider.code}} -> {{item.shippingLeadTimeCreateCommand.serviceProvider.code}}
        </label>
        <label v-else>{{item.shippingLeadTimeCreateCommand.serviceProvider.code}}</label>
      </template>

      <template v-slot:item.carrier="{ item }">
        <label :class="getClass(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand, 'carrier.code')">
          {{getCarrier(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand)}}
        </label>
      </template>

      <template v-slot:item.serviceCode="{ item }">
        <label :class="getClass(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand, 'serviceCode.name')">
          {{getServiceCode(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand)}}
        </label>
      </template>

      <template v-slot:item.carrierServiceString="{ item }">
        <label :class="getClass(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand, 'service')">
          {{getCarrierServiceString(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand)}}
        </label>
      </template>

      <template v-slot:item.events="{ item }">
        <label v-if="hasAnyEventChanged(item.shippingLeadTimeUpdateCommand.shippingEvents, item.shippingLeadTimeCreateCommand.shippingEvents)" class="yellow">
          Updated
        </label>
        <label v-else>No change</label>
      </template>

      <template v-slot:item.startDate="{ item }">
        <label v-if="!item.shippingLeadTimeCreateCommand.end">{{item.shippingLeadTimeCreateCommand.validFrom}}</label>
      </template>

      <template v-slot:item.days="{ item }">
        <label v-if="twoValuesAreNotEqual(item.shippingLeadTimeUpdateCommand.totalDays, item.shippingLeadTimeCreateCommand.totalDays)" class="yellow">
          {{item.shippingLeadTimeUpdateCommand.totalDays}} -> {{item.shippingLeadTimeCreateCommand.totalDays}}
        </label>
        <label v-else>{{item.shippingLeadTimeCreateCommand.totalDays}}</label>
      </template>

      <template v-slot:item.endDate="{ item }">
        <label v-if="item.shippingLeadTimeCreateCommand.end">{{item.shippingLeadTimeCreateCommand.validFrom}}</label>
      </template>

    </v-data-table>
  </v-form>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseToolTip2 from '@/base/tooltip/BaseToolTip2'
import { isEqual } from 'lodash'

export default {
  name: 'ReplaceShippingLeadTimeStep3',
  components: {
    BaseLabelWithFlag,
    BaseLabelWithFreightService,
    BaseToolTip2
  },
  props: {
    replaceShippingLeadTimes: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      tableHeaders: [
        { text: 'Place of delivery', align: 'start', sortable: true, value: 'placeOfDelivery' },
        { text: 'Default for del. country.', align: 'start', sortable: true, value: 'default', width: '60px' },
        { text: 'Main', align: 'start', sortable: false, value: 'shippingLeadTimeUpdateCommand.mainAsString' },
        { text: 'Service provider', sortable: true, value: 'serviceProvider' },
        { text: 'Carrier', sortable: true, value: 'carrier' },
        { text: 'Service code', sortable: true, value: 'serviceCode' },
        { text: 'Carrier service string', sortable: true, value: 'carrierServiceString' },
        { text: 'Events', sortable: true, value: 'events' },
        { text: 'Start date', sortable: true, value: 'startDate' },
        { text: 'Days', sortable: true, value: 'days' },
        { text: 'End date', sortable: false, value: 'endDate' }
      ]
    }
  },
  computed: {
    placeOfLoading () {
      return this.replaceShippingLeadTimes[0].shippingLeadTimeUpdateCommand.placeOfLoading
    },
    freightService () {
      return this.replaceShippingLeadTimes[0].shippingLeadTimeUpdateCommand.freightService
    }
  },
  methods: {
    twoValuesAreEqual (firstValue, secondValue) {
      return firstValue === secondValue
    },
    twoValuesAreNotEqual (firstValue, secondValue) {
      return firstValue !== secondValue
    },
    getClass (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, property) {
      if (this.propertyNotPresent(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, this.getFirstElement(property)) ||
        this.twoValuesAreEqual(this.resolve(shippingLeadTimeUpdateCommand, property), this.resolve(shippingLeadTimeCreateCommand, property))) {
        return 'transparent'
      }

      if (this.valueRemoved(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, property)) {
        return 'text-decoration-line-through yellow'
      }

      if (this.valueAdded(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, property) ||
        this.twoValuesAreNotEqual(this.resolve(shippingLeadTimeUpdateCommand, property), this.resolve(shippingLeadTimeCreateCommand, property))) {
        return 'yellow'
      }

      return 'yellow'
    },
    valueRemoved (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, property) {
      const firstElement = this.getFirstElement(property)
      return Object.prototype.hasOwnProperty.call(shippingLeadTimeUpdateCommand, firstElement) &&
        (shippingLeadTimeCreateCommand[firstElement] === null || shippingLeadTimeCreateCommand[firstElement] === undefined)
    },
    valueAdded (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, property) {
      return !Object.prototype.hasOwnProperty.call(shippingLeadTimeUpdateCommand, property) &&
        Object.prototype.hasOwnProperty.call(shippingLeadTimeCreateCommand, property)
    },
    propertyNotPresent (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, property) {
      return !Object.prototype.hasOwnProperty.call(shippingLeadTimeUpdateCommand, property) &&
        !Object.prototype.hasOwnProperty.call(shippingLeadTimeCreateCommand, property)
    },
    getCarrier (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand) {
      if (this.propertyNotPresent(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'carrier')) {
        return ''
      }

      if (this.valueRemoved(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'carrier')) {
        return shippingLeadTimeUpdateCommand.carrier.code
      }

      if (this.valueAdded(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'carrier')) {
        return shippingLeadTimeCreateCommand.carrier.code
      }

      if (this.twoValuesAreNotEqual(shippingLeadTimeUpdateCommand.carrier.code, shippingLeadTimeCreateCommand.carrier.code)) {
        return shippingLeadTimeUpdateCommand.carrier.code + ' -> ' + shippingLeadTimeCreateCommand.carrier.code
      }

      return shippingLeadTimeCreateCommand.carrier.code
    },
    getServiceCode (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand) {
      if (this.propertyNotPresent(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'serviceCode')) {
        return ''
      }

      if (this.valueRemoved(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'serviceCode')) {
        return shippingLeadTimeUpdateCommand.serviceCode.description
      }

      if (this.valueAdded(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'serviceCode')) {
        return shippingLeadTimeCreateCommand.serviceCode.description
      }

      if (this.twoValuesAreNotEqual(shippingLeadTimeUpdateCommand.serviceCode.name, shippingLeadTimeCreateCommand.serviceCode.name)) {
        return shippingLeadTimeUpdateCommand.serviceCode.description + ' -> ' + shippingLeadTimeCreateCommand.serviceCode.description
      }

      return shippingLeadTimeCreateCommand.serviceCode.description
    },
    getCarrierServiceString (shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand) {
      if (this.propertyNotPresent(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'service')) {
        return ''
      }

      if (this.valueRemoved(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'service')) {
        return shippingLeadTimeUpdateCommand.service
      }

      if (this.valueAdded(shippingLeadTimeUpdateCommand, shippingLeadTimeCreateCommand, 'service')) {
        return shippingLeadTimeCreateCommand.service
      }

      if (this.twoValuesAreNotEqual(shippingLeadTimeUpdateCommand.service, shippingLeadTimeCreateCommand.service)) {
        return shippingLeadTimeUpdateCommand.service + ' -> ' + shippingLeadTimeCreateCommand.service
      }

      return shippingLeadTimeCreateCommand.service
    },
    hasAnyEventChanged (firstEvents, secondEvents) {
      return firstEvents.length !== secondEvents.length || firstEvents.some((firstEvent, idx) => {
        const secondEvent = secondEvents[idx]
        return firstEvent.dayOfWeek !== secondEvent.dayOfWeek ||
            firstEvent.weekNumber !== secondEvent.weekNumber ||
            !isEqual(this.getDefaultStringValue(firstEvent.note), this.getDefaultStringValue(secondEvent.note))
      })
    },
    getDefaultStringValue (str) {
      return !str || ''
    },
    resolve (obj, path) {
      return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    getFirstElement (property) {
      const split = property.split('.')
      return split[0]
    },
    isExcludeOrEnding (item) {
      if (item.shippingLeadTimeCreateCommand.exclude) {
        return 'd-none'
      } else if (item.shippingLeadTimeCreateCommand.end) {
        return 'text-decoration-line-through yellow'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
