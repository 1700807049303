export const modeOfTransportIconResolverMixin = {
  methods: {
    resolveIcon (modeOfTransport) {
      if (modeOfTransport && (
        modeOfTransport.name.toUpperCase() === 'AIR' ||
        modeOfTransport.name.toUpperCase() === 'COURIER')) {
        return 'mdi-airplane'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'RAIL') {
        return 'mdi-train'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'ROAD') {
        return 'mdi-truck'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'SEA') {
        return 'mdi-ferry'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'RAIL AND AIR') {
        return 'mdi-plane-train'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'ROAD AND AIR') {
        return 'mdi-plane-car'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'RAIL AND ROAD') {
        return 'mdi-train-car'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase() === 'ROAD EXPRESS') {
        return 'mdi-truck-fast'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase().startsWith('AIR')) {
        return 'mdi-airplane'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase().startsWith('RAIL')) {
        return 'mdi-train'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase().startsWith('ROAD')) {
        return 'mdi-truck'
      } else if (modeOfTransport && modeOfTransport.name.toUpperCase().startsWith('SEA')) {
        return 'mdi-ferry'
      }
    }
  }
}
