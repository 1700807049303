<template>
  <v-form ref="shipping-lead-time-step2-form">
    <v-card
      :class="[shippingEvents && shippingEvents.length > 0 ? 'mb-5' : 'mb-12']"
      color="grey lighten-1">
      <v-card outlined class="pb3" elevation="0">
        <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="message in validationErrorMessages" v-bind:key="message">
            <li>{{message}}</li>
          </ul>
        </v-alert>
        <v-row>
          <v-col>
            <v-card-text class="pt-4">
              Shipping lead time needs to start with a closing event type and ending with an final type.
              Event type is shown within the parentheses when selecting event. <br>
              Add more weeks if needed by clicking on the + button below.
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-card-text class="pa-3">
              <v-col>
                <span class="ml-2">Week</span>
                <span class="ml-3 mr-18">Day#</span>
                <span class="ml-6 mr-15">Days</span>
                <span class="ml-15">Event type</span>
                <span class="ml-25">Shipping/Arrival point</span>
              </v-col>
            </v-card-text>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mr-0 v-card__text" v-for="(n, weekNumber) in numberOfWeeks" v-bind:key="weekNumber">
          <v-col cols="1"  class="ml-0 mt-2">
            <v-list-item-avatar>
              <v-avatar color="red darken-2" size="40" class="white--text">
                W{{ weekNumber }}
              </v-avatar>
            </v-list-item-avatar>
          </v-col>
          <v-col class="ml-3 mt-4">
            <div class="mt-3 mb-6 week-details" >
              <v-row v-for="weekDay in weeks" v-bind:key="weekDay.dayOfWeek">
                <v-col cols="1" class="pa-0">{{ weekNumber * 7 + weekDay.dayOfWeek }}</v-col>
                <v-col cols="3" class="mt-1">{{ weekDay.day }}</v-col>
                <v-col cols="3" class="mt-1">
                  <!-- See if there is any event added -->
                  <div class="mb-1" v-if="shippingLeadEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek)">
                    {{ shippingLeadEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek).type.name }}
                    <v-btn @click="removeEvent(weekNumber, weekDay.dayOfWeek)" class="pb-1" max-width="20" max-height="20" icon color="red">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <!-- No Event added, show the add event menu -->
                  <create-shipping-lead-time-step2-event-menu
                    v-else
                    :event-types="filteredShippingEventTypes"
                    :week-number="weekNumber"
                    :day-of-week="weekDay.dayOfWeek"
                    @click="addEvent"/>
                </v-col>
                <!-- Add note -->
                <v-col cols="4" class="mb-1" v-if="shippingLeadEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek)">
                  <v-text-field
                    :value="shippingLeadEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek).note"
                    @change="addEventNote(weekNumber, weekDay.dayOfWeek, $event)"
                    dense
                    flat
                    outlined
                    height="20px"
                    hide-details="auto"
                    label="Note" clearable/>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-card-text class="pa-5">
            <v-col cols="6">
              <shipping-event-weeks-input
                item-label="Number of weeks"
                :value="numberOfWeeks"
                @change="addOrRemoveWeek"/>
            </v-col>
          </v-card-text>
        </v-row>
      </v-card>

    </v-card>
  </v-form>
</template>

<script>

import axios from 'axios'
import CreateShippingLeadTimeStep2EventMenu from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep2EventMenu'
import { first, last, sortBy } from 'lodash'
import ShippingEventWeeksInput from '@/components/shippingleadtime/createshippingleadtime/ShippingEventWeeksInput'

export default {
  name: 'CreateShippingLeadTimeStep2',
  components: {
    CreateShippingLeadTimeStep2EventMenu,
    ShippingEventWeeksInput
  },
  data () {
    return {
      isValid: true,
      shippingEventTypes: [],
      shippingLeadEvents: [],
      numberOfWeeks: 1,
      weeks: [
        { day: 'Monday', dayOfWeek: 1 },
        { day: 'Tuesday', dayOfWeek: 2 },
        { day: 'Wednesday', dayOfWeek: 3 },
        { day: 'Thursday', dayOfWeek: 4 },
        { day: 'Friday', dayOfWeek: 5 },
        { day: 'Saturday', dayOfWeek: 6 },
        { day: 'Sunday', dayOfWeek: 7 }
      ],
      validationErrorMessages: []
    }
  },
  props: {
    selectedFreightServiceId: {
      type: String,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    shippingEvents: {
      type: Array,
      require: false
    }
  },
  computed: {
    filteredShippingEventTypes () {
      return this.shippingEventTypes
        .filter(eventType => eventType.freightServices.some(freightService => last(freightService.transportModes).name === this.selectedFreightServiceName))
    },
    selectedFreightServiceName () {
      if (this.selectedFreightServiceId) {
        const fs = this.freightServices.find(freightService => freightService.id === this.selectedFreightServiceId)
        return fs && last(fs.transportModes).name
      }
      return undefined
    }
  },
  mounted () {
    axios.get('/api/touchpoint/shipping-lead-time/shipping-event-type')
      .then(response => ([...response.data]).forEach(data => this.shippingEventTypes.push(data)))
      .catch(e => console.log(e))

    const that = this
    if (this.$route.params.id) {
      axios.get('/api/touchpoint/shipping-lead-time/' + this.$route.params.id + '/shipping-event')
        .then(response => {
          ([...response.data]).forEach(data => this.shippingLeadEvents.push({
            weekNumber: data.weekNumber,
            dayOfWeek: data.dayOfWeek,
            note: data.note,
            shippingEventTypeId: data.type.id,
            type: {
              name: data.type.name
            }
          }))
          that.calculateNumberOfWeek(response.data)
        })
        .catch(e => console.log(e))
    } else if (this.shippingEvents && this.shippingEvents.length > 0) {
      this.shippingEvents.forEach(data => this.shippingLeadEvents.push({
        weekNumber: data.weekNumber,
        dayOfWeek: data.dayOfWeek,
        note: data.note,
        shippingEventTypeId: data.type.id,
        type: {
          name: data.type.name
        }
      }))
      that.calculateNumberOfWeek(this.shippingEvents)
    }
  },
  methods: {
    addOrRemoveWeek (newWeekNumber) {
      if (newWeekNumber < this.numberOfWeeks) {
        // removing weeks
        const diff = this.numberOfWeeks - newWeekNumber
        let indexToBeRemoved = this.numberOfWeeks - 1 // weeks are zero indexed
        let count = 1
        while (count <= diff) {
          const events = []
          this.shippingLeadEvents.forEach((event, index) => event.weekNumber === indexToBeRemoved ? events.push(index) : null)
          events.reverse()
          events.forEach(index => this.shippingLeadEvents.splice(index, 1))
          this.emitUpdate()

          count++
          indexToBeRemoved--
        }
      }
      this.numberOfWeeks = newWeekNumber
    },
    addEvent (shippingEvent) {
      this.shippingLeadEvents.push({
        shippingEventTypeId: shippingEvent.shippingEventTypeId,
        dayOfWeek: shippingEvent.dayOfWeek,
        weekNumber: shippingEvent.weekNumber,
        type: {
          name: shippingEvent.name
        },
        note: ''
      })
      this.emitUpdate()
    },
    addEventNote (weekNumber, dayOfWeek, payload) {
      const event = this.shippingLeadEvents.find(function (event) {
        return event.weekNumber === weekNumber && event.dayOfWeek === dayOfWeek
      })
      event.note = payload
      this.emitUpdate()
    },
    removeEvent (weekNumber, dayOfWeek) {
      const index = this.shippingLeadEvents.findIndex(function (event) {
        return event.weekNumber === weekNumber && event.dayOfWeek === dayOfWeek
      })
      this.shippingLeadEvents.splice(index, 1)
      this.emitUpdate()
    },
    emitUpdate () {
      this.$emit('change', this.shippingLeadEvents)
    },
    clearEvents () {
      this.shippingLeadEvents = []
    },
    addEvents (shippingLeadEvents) {
      this.shippingLeadEvents = shippingLeadEvents
    },
    validate () {
      const sortedEventTypes = sortBy(this.shippingLeadEvents, ['weekNumber', 'dayOfWeek'])
      this.validationErrorMessages = []
      if (sortedEventTypes.length === 0) {
        this.validationErrorMessages.push('Select event type and add notes.')
      } else {
        const firstEvent = first(sortedEventTypes)
        const lastEvent = last(sortedEventTypes)
        if (firstEvent.type && firstEvent.type.name !== 'Closing') {
          this.validationErrorMessages.push("First event must be 'Closing'")
        }
        if (lastEvent.type && lastEvent.type.name && lastEvent.type.name.indexOf('Final') === -1) {
          this.validationErrorMessages.push("Last event must be 'Final'")
        }
        const finalEvents = sortedEventTypes.filter(item => item.type.name.indexOf('Final') !== -1)
        if (finalEvents.length > 1) {
          this.validationErrorMessages.push("Shipping lead time can have only one 'Final' event")
        }
      }
      this.$refs['shipping-lead-time-step2-form'].validate()
      return this.validationErrorMessages.length === 0
    },
    calculateNumberOfWeek (shippingLeadTimeEvents) {
      if (shippingLeadTimeEvents.length > 0) {
        this.numberOfWeeks = Math.max.apply(Math, shippingLeadTimeEvents.map(function (event) { return event.weekNumber + 1 }))
      } else {
        this.numberOfWeeks = 0
      }
      this.emitUpdate()
    }
  }
}
</script>

<style scoped>
  .col {
    padding: 5px;
  }

  div.error--text label {
    color: #ff5252 !important;
  }

  .week-details .row {
    border-bottom: 1px dotted lightgrey;
  }

  .ml-25 {
    margin-left: 100px;
  }

  .v-card__text.pa-3 {
    font-weight: 500 !important;
  }

  div.v-text-field {
    font-weight: 400 !important;
    font-size: 14px !important;
  }

</style>
