<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" >
                <v-icon color="red" medium>mdi-warehouse</v-icon>
                  {{ warehouseDescription(logicalWarehouses) }}
              </span>
    </template>
    <span>
      <div v-for="warehouse in logicalWarehouses"  v-bind:key="warehouse.id">
        <base-label-with-flag :item-country="warehouse.geographicalCountry.code" :item-label="warehouse.currentWarehouseCode"/>
      </div>
    </span>
  </v-tooltip>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'

export default {
  name: 'BaseWarehouseWithToolTip',
  components: {
    BaseLabelWithFlag
  },
  props: {
    logicalWarehouses: {
      type: Array,
      require: true
    }
  },
  methods: {
    warehouseDescription (item) {
      let warehouseDescription
      if (item.length > 0) {
        warehouseDescription = item.find(warehouse => warehouse).currentWarehouseCode
      }
      if (item.length > 1) {
        warehouseDescription += ' (+' + (item.length - 1) + ' others)'
      }
      return warehouseDescription
    }
  }
}
</script>

<style scoped>

</style>
