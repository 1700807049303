<template>
  <div>
    <v-autocomplete
      filled
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="itemLabel"
      multiple
      v-model="theSelectValueItems"
      @change="$emit('change', theSelectValueItems)"
      :disabled="isDisabled"
      clearable>
      <template v-slot:prepend-item v-if="selectAllOption">
        <v-list-item ripple @click="selectAll">
          <v-list-item-action>
            <v-icon :color="theSelectValueItems.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:item="{ active, item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <div v-if ="logisticLeadTimeToDisplay === 'mot'">
                <base-label-with-mode-of-transport :mode-of-transport="item"/>
              </div>
              <div v-else-if="logisticLeadTimeToDisplay === 'motCategory'">
                <base-label-with-mode-of-transport-category :mode-of-transport-category="item"/>
              </div>
              <div v-else-if="logisticLeadTimeToDisplay === 'packingMode'">
                <base-label-with-packing-mode :packing-mode="item"/>
              </div>
              <div v-else>
                {{ item[itemText] }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item[itemText] }}</span>
        <span v-if="index === 1" class="ml-2 grey--text caption">
          (+{{ theSelectValueItems.length - 1 }} others)
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import BaseLabelWithModeOfTransport from '@/base/label/BaseLabelWithModeOfTransport'
import BaseLabelWithModeOfTransportCategory from '@/base/label/BaseLabelWithModeOfTransportCategory'
import BaseLabelWithPackingMode from '@/base/label/BaseLabelWithPackingMode'
export default {
  name: 'BaseInputMultiSelectWithLogisticsMasterData',
  components: {
    BaseLabelWithModeOfTransport,
    BaseLabelWithModeOfTransportCategory,
    BaseLabelWithPackingMode
  },
  props: {
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      require: true
    },
    itemValue: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValues: {
      type: Array,
      require: false,
      default: () => []
    },
    selectAllOption: {
      type: Boolean,
      require: false,
      default: true
    },
    isDisabled: {
      type: Boolean,
      require: false,
      default: false
    },
    logisticLeadTimeToDisplay: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      theSelectValueItems: []
    }
  },
  computed: {
    selectedAll () {
      return this.theSelectValueItems.length === this.items.length
    },
    selectedSome () {
      return this.theSelectValueItems.length > 0 && !this.selectedAll
    },
    icon () {
      if (this.selectedAll) return 'mdi-close-box'
      if (this.selectedSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {
    selectedValues (val) {
      this.theSelectValueItems = val
    }
  },
  mounted () {
    this.theSelectValueItems = this.selectedValues
  },
  methods: {
    selectAll () {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.theSelectValueItems = []
        } else {
          this.theSelectValueItems = this.items.slice()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
