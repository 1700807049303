<template>
  <v-card min-height="150px" class="pb-1">
    <v-card-title>
      <v-icon class="mr-2">
        mdi-calendar-clock
      </v-icon>
      Transit lead time
    </v-card-title>
    <v-divider class="mb-6" v></v-divider>

    <v-timeline dense v-if="showTimeLine">
      <v-timeline-item v-if="selectedTransitWarehouse" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Transit Warehouse</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <span><country-flag :country="selectedTransitWarehouse.geographicalCountry.code" size='big' class="mt-1 ml-2"/></span>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ selectedTransitWarehouse.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item v-if="selectedAvailablePoint" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Available point</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <span><country-flag :country="selectedAvailablePoint.location.code" size='big' class="mt-1 ml-2"/></span>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ selectedAvailablePoint.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item v-if="selectedPlaceOfDelivery" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Place of delivery</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <span><country-flag :country="selectedPlaceOfDelivery.location.code" size='big' class="mt-1 ml-2"/></span>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ selectedPlaceOfDelivery.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <template v-if="selectedInboundFreightService">
        <v-timeline-item v-for="inboundTransportMode in selectedInboundFreightService.transportModes" v-bind:key="inboundTransportMode.name"
                         fill-dot color="red darken-2" :icon="resolveIcon(inboundTransportMode)" class="mb-2">
          <v-card class="elevation-2 mr-5 " >
            <v-row>
              <v-col class="pt-1 pb-0">
                <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Inbound mode of transport</span>
                <v-divider class="mt-1"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-body-2 ml-3">
                <v-icon> {{resolveIcon(inboundTransportMode)}}</v-icon>
                <span class="font-weight-medium"> {{ inboundTransportMode.description }} </span>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>
      </template>

      <template v-if="selectedOutboundFreightService">
        <v-timeline-item v-for="outboundTransportMode in selectedOutboundFreightService.transportModes" v-bind:key="outboundTransportMode.name"
                         fill-dot color="red darken-2" :icon="resolveIcon(outboundTransportMode)" class="mb-2">
          <v-card class="elevation-2 mr-5 " >
            <v-row>
              <v-col class="pt-1 pb-0">
                <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Outbound mode of transport</span>
                <v-divider class="mt-1"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-body-2 ml-3">
                <v-icon> {{resolveIcon(outboundTransportMode)}}</v-icon>
                <span class="font-weight-medium"> {{ outboundTransportMode.description }} </span>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>
      </template>
    </v-timeline>

    <v-card-text v-else class="justify-center">
      <v-row class="justify-center"> Time line for transit lead time</v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'

export default {
  name: 'CreateTransitLeadTimeTimeLine',
  mixins: [transportModeIconResolverMixin],
  components: { CountryFlag },
  props: {
    transitLeadTime: {
      type: Object,
      require: true
    },
    warehouses: {
      type: Array,
      require: true
    },
    locations: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    }
  },
  computed: {
    selectedTransitWarehouse () {
      return this.warehouses.find(item => item.id === this.transitLeadTime.transitWarehouseId)
    },
    selectedAvailablePoint () {
      return this.locations.find(item => item.id === this.transitLeadTime.availablePointId)
    },
    selectedPlaceOfDelivery () {
      return this.locations.find(item => item.id === this.transitLeadTime.placeOfDeliveryId)
    },
    selectedInboundFreightService () {
      return this.freightServices.find(item => item.id === this.transitLeadTime.inboundFreightServiceId)
    },
    selectedOutboundFreightService () {
      return this.freightServices.find(item => item.id === this.transitLeadTime.outboundFreightServiceId)
    },
    showTimeLine () {
      return this.selectedTransitWarehouse ||
        this.selectedAvailablePoint ||
        this.selectedPlaceOfDelivery ||
        this.selectedInboundFreightService ||
        this.selectedOutboundFreightService
    }
  }
}
</script>

<style scoped>

</style>
