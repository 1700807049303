<template>
  <v-container class="container">
    <v-form ref="logistics-lead-time-create-form">
      <v-row>
        <v-col cols="8" class="mt-3">
          <v-card>
            <base-card-title
              label="Create shipping & logistics lead time"
              icon="mdi-calendar-range"/>

            <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
              <ul v-for="message in validationErrorMessages" v-bind:key="message">
                <li>{{ message }}</li>
              </ul>
            </v-alert>
            <v-simple-table class="ml-6 mr-6" dense v-if="shippingLeadTime.placeOfLoading">
              <thead>
                <tr>
                  <th class="text-left">Place of loading</th>
                  <th class="text-left">Mode of transport</th>
                  <th class="text-left">Place of delivery</th>
                  <th class="text-left">Total days</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pt-3 pb-5"><base-label-with-flag :item-country="shippingLeadTime.placeOfLoading.location.code" :item-label="shippingLeadTime.placeOfLoading.description"/></td>
                  <td class="pt-3 pb-5"><base-label-with-freight-service :freight-service="shippingLeadTime.freightService"/></td>
                  <td class="pt-3 pb-5"><base-label-with-flag :item-country="shippingLeadTime.placeOfDelivery.location.code" :item-label="shippingLeadTime.placeOfDelivery.description"/></td>
                  <td class="pt-3 pb-5">{{ shippingLeadTime.totalDays }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-expansion-panels focusable :value="expandPanel">
            <!--Transit Lead time section-->
            <v-expansion-panel :key="0">
              <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-switch dense
                            color="green"
                            hide-details
                            class="ma-0 pa-0"
                            v-model="enableTransit"
                            @change="toggleTransit">
                  </v-switch>
                </template>
                <v-row>
                  <v-col cols="8" :class="!enableTransit ? 'text--disabled' : ''">
                    <span class="text-subtitle-2"> <v-icon>mdi-truck-delivery-outline</v-icon> Transit lead time</span>  <template v-if="!enableTransit">(skipped)</template>
                  </v-col>
                </v-row>
                <span v-if="!transitSelected && enableTransit" class="v-messages theme--light error--text text-right">Required</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="enableTransit">
                <v-data-table
                  :headers="transitLtTableHeaders"
                  :items="transitLeadTimes"
                  :search="searchTransitLt"
                  :items-per-page="10">
                  <!-- slots -->
                  <template v-slot:item.select="{ item }">
                    <v-radio-group v-model="transitLtSelect">
                      <v-radio
                        color="primary"
                        :value="item"
                        @click="setTransitLtAndPopulateHaulageOrFinalDestinationLt(item)"/>
                    </v-radio-group>
                  </template>
                  <template v-slot:item.availablePoint="{ item }">
                    <base-label-with-flag :item-country="item.availablePoint.location.code" :item-label="item.availablePoint.description"/>
                  </template>
                  <template v-slot:item.placeOfDelivery="{ item }">
                    <base-label-with-flag :item-country="item.placeOfDelivery.location.code" :item-label="item.placeOfDelivery.description"/>
                  </template>
                  <template v-slot:item.outboundFreightService="{ item }">
                    <base-label-with-freight-service :freight-service="item.outboundFreightService"/>
                  </template>
                  <template v-slot:item.transitWarehouse="{ item }">
                    <base-label-with-flag :item-country="item.transitWarehouse.geographicalCountry.code" :item-label="item.transitWarehouse.currentWarehouseCode"/>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon class="mr-2" @click="showViewTransitDialog(item)">mdi-magnify</v-icon>
                  </template>
                </v-data-table>
                <v-row>
                  <v-col>
                    <p class="text-right text-body-2 mb-0 mt-2">Transit lead time is optional, <a  class="blue--text" @click="() => {this.enableTransit = false; this.toggleTransit(false)}">click</a> to skip</p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--Haulage Lead time section-->
            <v-expansion-panel :key="1">
              <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-switch dense
                              color="green"
                              hide-details
                              class="ma-0 pa-0"
                              v-model="enableHaulage"
                              @change="toggleHaulage">
                    </v-switch>
                  </template>
                <v-row>
                  <v-col cols="8" :class="!enableHaulage ? 'text--disabled' : ''">
                    <span class="text-subtitle-2"> <v-icon>mdi-truck-delivery-outline</v-icon> Haulage lead time</span>  <template v-if="!enableHaulage">(skipped)</template>
                  </v-col>
                </v-row>
                <span v-if="!haulageSelected && enableHaulage" class="v-messages theme--light error--text text-right">Required</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="enableHaulage">
                  <v-data-table
                    :headers="haulageLtTableHeaders"
                    :items="haulageLeadTimes"
                    :search="searchHaulageLt"
                    :items-per-page="10">
                    <!-- slots -->
                    <template v-slot:item.select="{ item }">
                      <v-radio-group v-model="haulageLtSelect">
                        <v-radio
                          color="primary"
                          :value="item"
                          @click="setHaulageLtAndPopulateFinalDestinationLt(item)"/>
                      </v-radio-group>
                    </template>
                    <template v-slot:item.arrivalPoint="{ item }">
                      <base-label-with-flag :item-country="item.arrivalPoint.location.code" :item-label="item.arrivalPoint.description"/>
                    </template>
                    <template v-slot:item.destinationPoint="{ item }">
                      <base-label-with-flag :item-country="item.destinationPoint.location.code" :item-label="item.destinationPoint.description"/>
                    </template>
                    <template v-slot:item.freightService="{ item }">
                      <base-label-with-freight-service :freight-service="item.freightService"/>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon class="mr-2" @click="showViewHaulageDialog(item)">mdi-magnify</v-icon>
                    </template>
                  </v-data-table>
                <v-row>
                  <v-col>
                    <p class="text-right text-body-2 mb-0 mt-2">Haulage lead time is optional, <a  class="blue--text" @click="() => {this.enableHaulage = false; this.toggleHaulage(false)}">click</a> to skip</p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--Final Destination Lead time section-->
            <v-divider class="mb-6"></v-divider>
            <v-expansion-panel :key="2">
              <v-expansion-panel-header>
                <span class="text-subtitle-2"> <v-icon>mdi-calendar-export</v-icon> Final destination lead time</span> <span v-if="!finalDestinationSelected" class="v-messages theme--light error--text text-right mr-4">Required</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="finalDestinationLtTableHeaders"
                  :items="finalDestinationLeadTimes"
                  :items-per-page="10">
                  <!-- slots -->
                  <template v-slot:item.select="{ item }">
                    <v-radio-group v-model="finalDestLtSelect">
                      <v-radio
                        color="primary"
                        :value="item"
                        @click="setFinalDestinationLt(item)"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                  <template v-slot:item.location="{ item }">
                    <base-label-with-flag :item-country="item.location.location.code" :item-label="item.location.description"/>
                  </template>
                  <template v-slot:item.warehouses="{ item }">
                    <div class="pt-1 pb-1">
                      <div v-for="warehouse in item.logicalWarehouses"  v-bind:key="warehouse.id">
                        <div>
                          <span>{{ warehouse.currentWarehouseCode }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.planningMarkets="{ item }">
                    <div class="pt-1 pb-1">
                      <div v-for="planningMarket in item.planningMarketsActiveOnCurrentDate"  v-bind:key="planningMarket.id">
                        <div>
                          <span :title="planningMarket.description">{{ planningMarket.abbreviation }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.inboundFreightServices="{ item }">
                    <div class="mt-2">
                      <base-label-with-freight-services :freight-services="item.inboundFreightServices"/>
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon class="mr-2" @click="showViewFinalDestinationDialog(item)">mdi-magnify</v-icon>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card class="mt-3 mb-6">
            <div class="text-h6 mb-3 ml-4 mb-6 pt-3">Valid period</div>
            <v-row>
              <v-col cols="7" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid from"
                  :picker-date="logisticLeadTimeValidFromDate"
                  :min="logisticLeadTimeValidFromDate"
                  :max="logisticLeadTimeValidToDate"
                  :selected-value="logisticsLeadTime.validFrom"
                  :rules="[rules.required]"
                  @change="logisticsLeadTime.validFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :picker-date="minDateForValidTo"
                  :min="minDateForValidTo"
                  :max="logisticLeadTimeValidToDate"
                  :rules="validToValidationRules"
                  :selected-value="logisticsLeadTime.validTo"
                  @change="logisticsLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            label="save"
            icon="mdi-arrow-right-bold-circle"
            @click="save"/>
          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>
        <v-col cols="4" class="mt-3" v-if="shippingLeadTime.placeOfLoading">
          <logistic-lead-time-time-line
            :shippingLeadTime="shippingLeadTime"
            :transit-lead-time="transitLeadTime"
            :haulageLeadTime="haulageLeadTime"
            :finalDestinationLeadTime="finalDestinationLeadTime"/>
        </v-col>
      </v-row>
    </v-form>

    <base-dialog-yes-no-cancel
      label=""
      :dialog="publishLeadTimeDialog"
      @yes="send"
      @no="withoutPublishLeadTime"
      @cancel="() => this.publishLeadTimeDialog = false">
      <v-row justify="center">
        <p>Publish the logistic lead time? Select date for publish logistic lead time.</p>
        <p>Click no or leave empty and the logistic lead time will be saved and can be published at a later time.</p>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
            <base-input-select-date-picker
              :min-today="true"
              item-label="Publish time"
              @change="logisticsLeadTime.publishTime = arguments[0]"/>
        </v-col>
      </v-row>
    </base-dialog-yes-no-cancel>

    <base-dialog-send
      label="Logistic lead time has been saved successfully!"
      @create="createNew"
      @copy="copy"
      @search="search"
      :send-dialog="sendDialog"/>

    <search-transit-lead-time-view-details-dialog
      :view-dialog="viewTransitDialog"
      :view-dialog-transit-lead-time="viewDialogTransitLeadTime"
      :can-update="false"
      @close="closeViewTransitDialog"/>

    <search-haulage-lead-time-view-details-dialog
      :view-dialog="viewHaulageDialog"
      :view-dialog-haulage-lead-time="viewDialogHaulageLeadTime"
      :can-update="false"
      @close="closeViewHaulageDialog"/>

    <final-destination-view-details-dialog
      :view-dialog="viewFinalDestinationDialog"
      :final-destination-lead-time="viewDialogFinalDestinationLeadTime"
      :can-update="false"
      @close="closeViewFinalDestinationDialog"/>
  </v-container>
</template>

<script>

import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightServices from '@/base/label/BaseLabelWithFreightServices'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import LogisticLeadTimeTimeLine from '@/components/logisticsleadtime/LogisticLeadTimeTimeLine'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import SearchHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchHaulageLeadTimeViewDetailsDialog'
import FinalDestinationViewDetailsDialog from '@/components/finaldestinationleadtime/FinalDestinationViewDetailsDialog'
import BaseDialogYesNoCancel from '@/base/dialog/BaseDialogYesNoCancel'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import { first, min, max } from 'lodash'
import { validateDate } from '@/validation/customvalidation'

export default {
  name: 'CreateLogisticsLeadTime',
  components: {
    SearchTransitLeadTimeViewDetailsDialog,
    BaseDialogYesNoCancel,
    FinalDestinationViewDetailsDialog,
    BaseDialogSend,
    BaseCardTitle,
    BaseLabelWithFlag,
    BaseLabelWithFreightServices,
    BaseLabelWithFreightService,
    BaseInputSelectDatePicker,
    BaseInputPrimaryButton,
    LogisticLeadTimeTimeLine,
    BaseInputSecondaryButton,
    SearchHaulageLeadTimeViewDetailsDialog
  },
  data () {
    return {
      validationErrorMessages: [],
      transitSelected: true,
      haulageSelected: true,
      finalDestinationSelected: true,
      searchTransitLt: '',
      searchHaulageLt: '',
      transitLtSummary: '',
      haulageLtSummary: '',
      enableHaulage: false,
      enableTransit: false,
      viewHaulageDialog: false,
      viewTransitDialog: false,
      viewDialogTransitLeadTime: {},
      viewDialogHaulageLeadTime: {},
      viewFinalDestinationDialog: false,
      viewDialogFinalDestinationLeadTime: {},
      finalDestLtSelect: 0,
      haulageLtSelect: 0,
      transitLtSelect: 0,
      publishLeadTimeDialog: false,
      sendDialog: false,
      logisticsLeadTime: {
        shippingLeadTimeId: '',
        transitLeadTimeId: '',
        haulageLeadTimeId: '',
        finalDestinationLeadTimeId: '',
        validFrom: '',
        validTo: '',
        publishTime: ''
      },
      shippingLeadTimes: [],
      transitLeadTimes: [],
      haulageLeadTimes: [],
      finalDestinationLeadTimes: [],
      shippingLeadTime: {},
      transitLeadTime: {},
      haulageLeadTime: {},
      finalDestinationLeadTime: {},
      shippingLtTableHeaders: [
        { text: 'Place of loading', align: 'start', sortable: false, value: 'placeOfLoading' },
        { text: 'Mode of transport', sortable: false, value: 'freightService' },
        { text: 'Place of delivery', sortable: false, value: 'placeOfDelivery' },
        { text: 'Total days', sortable: false, value: 'totalDays' }
      ],
      transitLtTableHeaders: [
        { text: 'Select', sortable: false, value: 'select' },
        { text: 'Available point', align: 'start', sortable: false, value: 'availablePoint' },
        { text: 'Place of delivery', align: 'start', sortable: false, value: 'placeOfDelivery' },
        { text: 'Outbound MoT', sortable: false, value: 'outboundFreightService' },
        { text: 'Transit warehouse', sortable: false, value: 'transitWarehouse' },
        { text: 'Total days', sortable: false, value: 'totalDays' },
        { text: 'View', sortable: false, value: 'actions' }
      ],
      haulageLtTableHeaders: [
        { text: 'Select', sortable: false, value: 'select' },
        { text: 'Arrival point', align: 'start', sortable: false, value: 'arrivalPoint' },
        { text: 'Mode of transport', sortable: false, value: 'freightService' },
        { text: 'Destination point', sortable: false, value: 'destinationPoint' },
        { text: 'Total days', sortable: false, value: 'totalDays' },
        { text: 'View', sortable: false, value: 'actions' }
      ],
      finalDestinationLtTableHeaders: [
        { text: 'Select', sortable: false, value: 'select' },
        { text: 'Destination', align: 'start', sortable: false, value: 'location' },
        { text: 'Shipping MoT', align: 'start', sortable: false, value: 'inboundFreightServices' },
        { text: 'Warehouses', align: 'start', sortable: false, value: 'warehouses' },
        { text: 'PM', align: 'start', sortable: false, value: 'planningMarkets' },
        { text: 'Total days', sortable: false, value: 'totalDays' },
        { text: 'View', sortable: false, value: 'actions' }
      ],
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.logisticsLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.',
        toDateRequired: value => !!value || 'Required as one of the component has end date.'
      }
    }
  },
  mounted () {
    axios.get('/api/touchpoint/shipping-lead-time/' + this.$route.params.id)
      .then((response) => {
        this.shippingLeadTimes.push(response.data)
        this.shippingLeadTime = this.shippingLeadTimes[0]
        this.logisticsLeadTime.shippingLeadTimeId = this.$route.params.id

        // default toggle on haulage for SEA and AIR transport modes
        const transportModeName = first(this.shippingLeadTime.freightService.transportModes).name
        if (transportModeName === 'SEA' || transportModeName === 'AIR') {
          this.getHaulageLeadTimes(response.data.placeOfDelivery.id, response.data.freightService.id)
          this.enableHaulage = true
        } else {
          this.getFinalDestinationLeadTimes(response.data.placeOfDelivery.id, response.data.freightService.id)
        }
      }).catch(e => console.log(e))
  },
  computed: {
    expandPanel () {
      if (this.transitLeadTime.id && this.haulageLeadTime.id && this.finalDestinationLeadTime.id) {
        return [] // collapse all
      }
      if (this.transitLeadTime.id && this.haulageLeadTime.id) {
        return 2 // expand final destination
      } else if (this.transitLeadTime.id) {
        return 1 // expand haulage
      } else if (this.haulageLeadTime.id) {
        return 2 // expand final destination
      } else if (this.enableTransit) {
        return [0]
      } else if (this.enableHaulage) {
        return 1 // expand haulage
      } else if (!this.finalDestinationLeadTime.id) {
        return 2 // expand final destination
      } else {
        return [] // collapse all
      }
    },
    calculateValidFromDate () {
      if (this.shippingLeadTime.id) {
        const validFromDates = []
        if (this.shippingLeadTime.validFrom) {
          validFromDates.push(new Date(this.shippingLeadTime.validFrom))
        }

        if (this.finalDestinationLeadTime.validFrom) {
          validFromDates.push(new Date(this.finalDestinationLeadTime.validFrom))
        }

        if (this.transitLeadTime.validFrom) {
          validFromDates.push(new Date(this.transitLeadTime.validFrom))
        }

        if (this.haulageLeadTime.validFrom) {
          validFromDates.push(new Date(this.haulageLeadTime.validFrom))
        }

        return max(validFromDates)
      }
      return ''
    },
    calculateValidToDate () {
      if (this.shippingLeadTime.id) {
        const validToDates = []
        if (this.shippingLeadTime.validTo) {
          validToDates.push(new Date(this.shippingLeadTime.validTo))
        }

        if (this.finalDestinationLeadTime.validTo) {
          validToDates.push(new Date(this.finalDestinationLeadTime.validTo))
        }

        if (this.transitLeadTime.validTo) {
          validToDates.push(new Date(this.transitLeadTime.validTo))
        }

        if (this.haulageLeadTime.validTo) {
          validToDates.push(new Date(this.haulageLeadTime.validTo))
        }

        return min(validToDates)
      }
      return ''
    },
    validFromDateAsString () {
      const validFromDate = this.calculateValidFromDate
      if (validFromDate) {
        return validFromDate.toISOString().split('T')[0]
      }
      return ''
    },
    validToDateAsString () {
      const validToDate = this.calculateValidToDate
      if (validToDate) {
        return validToDate.toISOString().split('T')[0]
      }
      return ''
    },
    minDateForValidTo () {
      if (!this.logisticsLeadTime.validFrom) {
        return this.logisticLeadTimeValidFromDate
      } else {
        return this.logisticsLeadTime.validFrom
      }
    },
    logisticLeadTimeValidFromDate () {
      if (this.shippingLeadTime.id && this.finalDestinationLeadTime.id) {
        return this.validFromDateAsString
      } else {
        return ''
      }
    },
    logisticLeadTimeValidToDate () {
      if (this.shippingLeadTime.id && this.finalDestinationLeadTime.id) {
        return this.validToDateAsString
      } else {
        return ''
      }
    },
    validToValidationRules () {
      const rules = [this.rules.fromDateIsBeforeToDate]
      if (this.logisticLeadTimeValidToDate) {
        rules.push(this.rules.toDateRequired)
      }
      return rules
    }
  },
  methods: {
    getTransitLeadTimes (data) {
      this.transitLeadTimes.splice(0, this.transitLeadTimes.length)
      axios.post('/api/touchpoint/transit-lead-time/logistic-lead-time', {
        availablePointId: data.placeOfDelivery.id,
        inboundFreightServiceId: data.freightService.id,
        validFrom: this.validFromDateAsString,
        validTo: this.validToDateAsString,
        active: true
      }).then(response => ([...response.data]).forEach(lt => this.transitLeadTimes.push(lt))).catch(e => console.log(e))
    },
    getHaulageLeadTimes (placeOfDeliveryId, freightServiceId) {
      this.haulageLeadTimes.splice(0, this.haulageLeadTimes.length)
      axios.post('/api/touchpoint/haulage-lead-time/logistic-lead-time', {
        arrivalPointId: placeOfDeliveryId,
        inboundFreightServiceIds: [freightServiceId],
        validFrom: this.validFromDateAsString,
        validTo: this.validToDateAsString,
        active: true
      }).then(response => ([...response.data]).forEach(data => this.haulageLeadTimes.push(data))).catch(e => console.log(e))
    },
    getFinalDestinationLeadTimes (destinationId, inboundFreightService) {
      this.finalDestinationLeadTimes.splice(0, this.finalDestinationLeadTimes.length)
      axios.post('/api/touchpoint/final-destination-lead-time/logistic-lead-time', {
        locationId: destinationId,
        inboundFreightServiceIds: [inboundFreightService],
        validFrom: this.validFromDateAsString,
        validTo: this.validToDateAsString,
        active: true
      }).then(response => ([...response.data]).forEach(data => this.finalDestinationLeadTimes.push(data)))
        .catch(e => console.log(e))
    },
    setTransitLtAndPopulateHaulageOrFinalDestinationLt (data) {
      this.transitLtSummary = data.availablePoint.unlocode + ' --> ' + data.placeOfDelivery.unlocode
      this.logisticsLeadTime.transitLeadTimeId = data.id
      this.transitLeadTime = data
      this.transitSelected = true
      this.haulageLeadTime = {}
      if (this.enableHaulage) {
        this.getHaulageLeadTimes(data.placeOfDelivery.id, data.outboundFreightService.id)
      } else {
        this.getFinalDestinationLeadTimes(data.placeOfDelivery.id, data.outboundFreightService.id)
      }
    },
    setHaulageLtAndPopulateFinalDestinationLt (data) {
      this.haulageLtSummary = data.arrivalPoint.unlocode + ' --> ' + data.destinationPoint.unlocode
      this.logisticsLeadTime.haulageLeadTimeId = data.id
      this.haulageLeadTime = data
      this.haulageSelected = true
      this.finalDestinationLeadTime = {}
      if (this.enableTransit) {
        this.getFinalDestinationLeadTimes(data.destinationPoint.id, this.transitLeadTime.outboundFreightService.id)
      } else {
        this.getFinalDestinationLeadTimes(data.destinationPoint.id, this.shippingLeadTime.freightService.id)
      }
    },
    toggleTransit (toggledOn) {
      if (toggledOn) {
        this.logisticsLeadTime.transitLeadTimeId = null
        this.cleanHaulage()
        this.cleanFinalDestination()
        this.getTransitLeadTimes(this.shippingLeadTime)
      } else {
        if (this.enableHaulage) {
          this.logisticsLeadTime.haulageLeadTimeId = null
          this.cleanTransit()
          this.cleanFinalDestination()
          this.getHaulageLeadTimes(this.shippingLeadTime.placeOfDelivery.id, this.shippingLeadTime.freightService.id)
        } else {
          this.logisticsLeadTime.finalDestinationLeadTimeId = null
          this.cleanTransit()
          this.cleanHaulage()
          this.getFinalDestinationLeadTimes(this.shippingLeadTime.placeOfDelivery.id, this.shippingLeadTime.freightService.id)
        }
      }
    },
    toggleHaulage (toggledOn) {
      if (toggledOn) {
        if (this.enableTransit) {
          this.finalDestinationLeadTimes.splice(0, this.finalDestinationLeadTimes.length)
          if (this.logisticsLeadTime.transitLeadTimeId) {
            this.getHaulageLeadTimes(this.transitLeadTime.placeOfDelivery.id, this.transitLeadTime.outboundFreightService.id)
          }
        } else {
          this.cleanFinalDestination()
          this.logisticsLeadTime.transitLeadTimeId = null
          this.logisticsLeadTime.haulageLeadTimeId = null
          this.getHaulageLeadTimes(this.shippingLeadTime.placeOfDelivery.id, this.shippingLeadTime.freightService.id)
        }
      } else {
        if (this.enableTransit) {
          this.cleanHaulage()
          this.cleanFinalDestination()
          if (this.logisticsLeadTime.transitLeadTimeId) {
            this.getFinalDestinationLeadTimes(this.transitLeadTime.placeOfDelivery.id, this.transitLeadTime.outboundFreightService.id)
          }
        } else {
          this.cleanTransit()
          this.cleanHaulage()
          this.logisticsLeadTime.finalDestinationLeadTimeId = null
          this.getFinalDestinationLeadTimes(this.shippingLeadTime.placeOfDelivery.id, this.shippingLeadTime.freightService.id)
        }
      }
    },
    cleanHaulage () {
      this.haulageLeadTime = {}
      this.haulageLeadTimes.splice(0, this.haulageLeadTimes.length)
      this.logisticsLeadTime.haulageLeadTimeId = null
    },
    cleanFinalDestination () {
      this.finalDestinationLeadTime = {}
      this.finalDestinationLeadTimes.splice(0, this.finalDestinationLeadTimes.length)
      this.logisticsLeadTime.finalDestinationLeadTimeId = null
    },
    cleanTransit () {
      this.transitLeadTime = {}
      this.transitLeadTimes.splice(0, this.transitLeadTimes.length)
      this.logisticsLeadTime.transitLeadTimeId = null
    },
    showViewTransitDialog (leadtime) {
      this.viewDialogTransitLeadTime = leadtime
      this.viewTransitDialog = true
    },
    showViewHaulageDialog (leadtime) {
      this.viewDialogHaulageLeadTime = leadtime
      this.viewHaulageDialog = true
    },
    closeViewHaulageDialog () {
      this.viewHaulageDialog = false
    },
    closeViewTransitDialog () {
      this.viewTransitDialog = false
    },
    showViewFinalDestinationDialog (leadtime) {
      this.viewDialogFinalDestinationLeadTime = leadtime
      this.viewFinalDestinationDialog = true
    },
    closeViewFinalDestinationDialog () {
      this.viewFinalDestinationDialog = false
    },
    setFinalDestinationLt (data) {
      this.logisticsLeadTime.finalDestinationLeadTimeId = data.id
      this.finalDestinationLeadTime = data
    },
    save () {
      this.isValid().then(() => {
        if (this.validationErrorMessages.length === 0) {
          this.publishLeadTimeDialog = true
        }
      })
    },
    withoutPublishLeadTime () {
      this.logisticsLeadTime.logisticsLeadTime = ''
      this.send()
    },
    send () {
      this.publishLeadTimeDialog = false
      if (this.validationErrorMessages.length === 0) {
        axios.post('/api/touchpoint/logistic-lead-time', this.logisticsLeadTime)
          .then((response) => {
            this.sendDialog = true
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    cancel () {
      this.$router.push({ name: 'SearchShippingLeadTime' })
    },
    createNew () {
      this.sendDialog = false
      this.$router.push({ name: 'SearchShippingLeadTime' })
    },
    copy () {
      this.sendDialog = false
    },
    search () {
      this.$router.push({ name: 'SearchLogisticsLeadTime' })
    },
    async isValid () {
      this.validationErrorMessages = []
      this.haulageSelected = true
      this.finalDestinationSelected = true
      if (!this.haulageLeadTime.id && this.enableHaulage) {
        this.validationErrorMessages.push('Please select haulage lead time')
        this.haulageSelected = false
      }

      if (!this.finalDestinationLeadTime.id) {
        this.validationErrorMessages.push('Please select final destination lead time')
        this.finalDestinationSelected = false
      }

      if (!this.$refs['logistics-lead-time-create-form'].validate()) {
        this.validationErrorMessages.push("Please select 'from date'")
      }

      if (this.validationErrorMessages.length === 0) {
        await axios.post('/api/touchpoint/logistic-lead-time/validate', this.logisticsLeadTime)
          .then((response) => {
            if (response.data.length > 0) {
              this.validationErrorMessages.push('Similar logistics lead time exists with overlapping intervals')
            }
            return response.data.length === 0
          })
          .catch(e => console.log(e))
      }

      return this.validationErrorMessages.length === 0
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1300px;
}

.v-card__text {
  padding-top: 0;
}

.v-expansion-panel {
  margin-top: 10px;
}

.v-expansion-panel-header:before {
  background-color: white;
  border-bottom: 1px solid;
}

</style>
