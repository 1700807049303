var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.replaceShippingLeadTimes.length > 0)?_c('v-form',{ref:"shipping-lead-time-replace-step3-form"},[_c('v-card',{staticClass:"mb-6",attrs:{"elevation":"0"}},[_c('div',{staticClass:"mb-5"},[_vm._v("Verify the new shipping lead time versus the current ones.")]),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Place of loading ")]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.placeOfLoading)?_c('base-label-with-flag',{attrs:{"item-label":_vm.placeOfLoading.description,"item-country":_vm.placeOfLoading.location.code}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Freight service ")]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.freightService)?_c('base-label-with-freight-service',{attrs:{"item-label":_vm.freightService.description,"freight-service":_vm.freightService}}):_vm._e()],1)],1)],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.tableHeaders,"items":_vm.replaceShippingLeadTimes,"items-per-page":-1,"hide-default-footer":true,"item-class":_vm.isExcludeOrEnding},scopedSlots:_vm._u([{key:"item.placeOfDelivery",fn:function(ref){
var item = ref.item;
return [_c('base-tool-tip2',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('base-label-with-flag',{attrs:{"item-country":item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code,"item-label":item.shippingLeadTimeCreateCommand.placeOfDelivery.description}})]},proxy:true}],null,true)},[_c('base-label-with-flag',{attrs:{"item-country":item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code,"item-label":item.shippingLeadTimeCreateCommand.placeOfDelivery.unlocode}})],1)]}},{key:"item.default",fn:function(ref){
var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.defaultForCountryOfDelivery)?_c('label',[_vm._v("Yes")]):_c('label',[_vm._v("No")])]}},{key:"item.serviceProvider",fn:function(ref){
var item = ref.item;
return [(_vm.twoValuesAreNotEqual(item.shippingLeadTimeUpdateCommand.serviceProvider.code, item.shippingLeadTimeCreateCommand.serviceProvider.code))?_c('label',{staticClass:"yellow"},[_vm._v(" "+_vm._s(item.shippingLeadTimeUpdateCommand.serviceProvider.code)+" -> "+_vm._s(item.shippingLeadTimeCreateCommand.serviceProvider.code)+" ")]):_c('label',[_vm._v(_vm._s(item.shippingLeadTimeCreateCommand.serviceProvider.code))])]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [_c('label',{class:_vm.getClass(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand, 'carrier.code')},[_vm._v(" "+_vm._s(_vm.getCarrier(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand))+" ")])]}},{key:"item.serviceCode",fn:function(ref){
var item = ref.item;
return [_c('label',{class:_vm.getClass(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand, 'serviceCode.name')},[_vm._v(" "+_vm._s(_vm.getServiceCode(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand))+" ")])]}},{key:"item.carrierServiceString",fn:function(ref){
var item = ref.item;
return [_c('label',{class:_vm.getClass(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand, 'service')},[_vm._v(" "+_vm._s(_vm.getCarrierServiceString(item.shippingLeadTimeUpdateCommand, item.shippingLeadTimeCreateCommand))+" ")])]}},{key:"item.events",fn:function(ref){
var item = ref.item;
return [(_vm.hasAnyEventChanged(item.shippingLeadTimeUpdateCommand.shippingEvents, item.shippingLeadTimeCreateCommand.shippingEvents))?_c('label',{staticClass:"yellow"},[_vm._v(" Updated ")]):_c('label',[_vm._v("No change")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [(!item.shippingLeadTimeCreateCommand.end)?_c('label',[_vm._v(_vm._s(item.shippingLeadTimeCreateCommand.validFrom))]):_vm._e()]}},{key:"item.days",fn:function(ref){
var item = ref.item;
return [(_vm.twoValuesAreNotEqual(item.shippingLeadTimeUpdateCommand.totalDays, item.shippingLeadTimeCreateCommand.totalDays))?_c('label',{staticClass:"yellow"},[_vm._v(" "+_vm._s(item.shippingLeadTimeUpdateCommand.totalDays)+" -> "+_vm._s(item.shippingLeadTimeCreateCommand.totalDays)+" ")]):_c('label',[_vm._v(_vm._s(item.shippingLeadTimeCreateCommand.totalDays))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.end)?_c('label',[_vm._v(_vm._s(item.shippingLeadTimeCreateCommand.validFrom))]):_vm._e()]}}],null,false,4250954876)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }