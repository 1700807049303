<template>
  <v-container>
    <v-form ref="haulage-lead-time-update-form">
      <v-row>
        <v-col class="mt-8">
          <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
            <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
              <li>{{validationErrorMessage.message}}</li>
            </ul>
          </v-alert>
          <v-card class="mb-6">
            <base-card-title
              label="Update Haulage lead time"
              icon="mdi-truck-delivery-outline"/>
            <div class="text-h6 mb-3 mt-4 ml-4">Location points</div>
            <v-row >
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Arrival point"
                  :items="transportLocations"
                  item-country="location.code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="haulageLeadTime.arrivalPointId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Destination point"
                  :items="destinationPoints"
                  item-country="location.code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="haulageLeadTime.destinationPointId"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 mt-4 ml-4">Transport modes</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-multi-select-with-freight-service
                  item-label="Inbound Freight Service"
                  :grouped-freight-services="groupedFreightServices"
                  :freight-services="freightServices"
                  :selected-value="haulageLeadTime.freightServiceIds"
                  :rules="[rules.selected]"
                  @change="haulageLeadTime.freightServiceIds = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-freight-service-read-only
                  :freight-services="freightServices"
                  item-label="Haulage MoT"
                  :selected-value="haulageLeadTime.freightServiceId"
                  :rules="[rules.required]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 mt-4 ml-4">Lead times</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-number-field
                  item-label="Arrival to available"
                  :value="haulageLeadTime.arrivalToAvailable"
                  @blur="haulageLeadTime.arrivalToAvailable = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Available to destination"
                  :value="haulageLeadTime.availableToAtDestination"
                  @blur="haulageLeadTime.availableToAtDestination = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Quick demand deduction</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Quick demand deduction"
                  :value="haulageLeadTime.quickDemandDeduction"
                  @blur="haulageLeadTime.quickDemandDeduction = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Valid period</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker-read-only v-if="!replaceHlt"
                  item-label="Valid from"
                  :value="haulageLeadTime.validFrom"
                  @change="haulageLeadTime.validFrom = arguments[0]"/>

                <base-input-select-date-picker v-if="replaceHlt"
                   item-label="Valid from"
                   :selected-value="haulageLeadTime.validFrom"
                   :rules="[rules.required , rules.fromDateIsBeforeMaxLLtFromDate]"
                   @change="haulageLeadTime.validFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :rules="[rules.fromDateIsBeforeToDate]"
                  :selected-value="haulageLeadTime.validTo"
                  @change="haulageLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            :label="replaceHlt ? 'Replace' : 'Update'"
            icon="mdi-arrow-right-bold-circle"
            @click="update"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>
        <v-col cols="5" class="mt-8">
          <create-haulage-lead-time-time-line
            :haulage-lead-time="haulageLeadTime"
            :arrival-points="transportLocations"
            :destination-points="transportLocations"
            :freightServices="freightServices"/>
        </v-col>
      </v-row>
    </v-form>
    <base-dialog-send
      label="Haulage lead time has been saved successfully!"
      @create="createNew"
      @copy="copy"
      @search="search"
      :send-dialog="sendDialog"/>
    <affected-logistic-lead-times-view-dialog
      :view-dialog="viewDialog"
      :view-affected-logistic-lead-times="viewAffectedLogisticLeadTimes"
      @cancel="viewDialog = false"
      @confirm="updateHaulageLeadTime"/>
    <base-dialog-ok :label="warningMessage"
                    :show-icon="false"
                    :show-warning-icon="true"
                    :dialog="warningDialog"
                    @ok="() => this.warningDialog = false"/>
    <replace-haulage-lead-time-dialog :dialog="dialog"
                                             :createLLtsDialog="createLLtsDialog"
                                             @replaceHltAndSllt="replaceHltAndSllt"
                                             @closeSaveAndCreateLLtsDialog="closeSaveAndCreateLLtsDialog"
                                             :create-l-lts-result-dialog="createLLtsResultDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import CreateHaulageLeadTimeTimeLine from '@/components/haulageleadtime/createhaulageleadtime/CreateHaulageLeadTimeTimeLine'
import BaseInputNumberField from '@/base/input/BaseInputNumberField'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import AffectedLogisticLeadTimesViewDialog from '@/views/logisticsleadtime/AffectedLogisticLeadTimesViewDialog'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import BaseInputSelectWithFlagReadOnly from '@/base/input/BaseInputSelectWithFlagReadOnly'
import BaseInputSelectWithFreightServiceReadOnly from '@/base/input/BaseInputSelectWithFreightServiceReadOnly'
import { validateDate } from '@/validation/customvalidation'
import { max } from 'lodash'
import BaseDialogOk from '@/base/dialog/BaseDialogOk.vue'
import ReplaceHaulageLeadTimeDialog
  from '@/components/haulageleadtime/replacehaulageleadtime/ReplaceHaulageLeadTimeDialog.vue'
import moment from 'moment'

export default {
  name: 'UpdateHaulageLeadTime',
  components: {
    ReplaceHaulageLeadTimeDialog,
    BaseDialogOk,
    AffectedLogisticLeadTimesViewDialog,
    BaseDialogSend,
    BaseCardTitle,
    BaseInputSelectDatePicker,
    BaseInputMultiSelectWithFreightService,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    CreateHaulageLeadTimeTimeLine,
    BaseInputNumberField,
    BaseInputSelectDatePickerReadOnly,
    BaseInputSelectWithFlagReadOnly,
    BaseInputSelectWithFreightServiceReadOnly
  },
  data () {
    return {
      sendDialog: false,
      viewDialog: false,
      dialog: false,
      createLLtsDialog: false,
      createLLtsResultDialog: false,
      validationErrorMessages: [],
      transportLocations: [],
      destinationPoints: [],
      freightServices: [],
      groupedFreightServices: [],
      viewAffectedLogisticLeadTimes: [],
      affectedMaxLltValidFromDate: '',
      warningDialog: false,
      warningMessage: '',
      oldHaulageLeadTime: {},
      haulageLeadTime: {
        id: '',
        arrivalPointId: '',
        destinationPointId: '',
        freightServiceIds: [],
        freightServiceId: '',
        arrivalToAvailable: '',
        availableToAtDestination: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      },
      affectedLogisticsLeadTimeCount: 0,
      watchComplete: false,
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.haulageLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.',
        fromDateIsBeforeMaxLLtFromDate: value => {
          if (this.replaceHlt) {
            const msg = 'Valid from date must be greater than connected max Logistic lead time from date.'
            if (this.affectedMaxLltValidFromDate === value) {
              return msg
            }
            return validateDate(this.affectedMaxLltValidFromDate, value) || msg
          }
        }
      }
    }
  },
  props: {
    replaceHlt: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  mounted () {
    axios.get('/api/touchpoint/transportLocations')
      .then(response => ([...response.data]).forEach(data => {
        this.transportLocations.push(data)
      }))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transport-location/place-of-discharge-or-delivery')
      .then(response => ([...response.data]).forEach(data => this.destinationPoints.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/grouped-freight-services')
      .then(response => ([...response.data]).forEach(data => this.groupedFreightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/haulage-lead-time/' + this.$route.params.id)
      .then((response) => {
        this.transformData(response.data)
      })
    axios.get('/api/touchpoint/logistic-lead-time/haulage-lead-time/' + this.$route.params.id)
      .then((response) => {
        if (this.replaceHlt) {
          this.viewAffectedLogisticLeadTimes = response.data.filter(sllt => sllt.validTo == null)
        } else {
          this.viewAffectedLogisticLeadTimes = response.data
        }
        const allValidFromDates = [...response.data.map(sllt => sllt.validFrom)]
        this.affectedMaxLltValidFromDate = max(allValidFromDates)
        if (this.replaceHlt) {
          this.haulageLeadTime.validFrom = moment(this.affectedMaxLltValidFromDate).add(1, 'days').format('YYYY-MM-DD')
        }
      })
      .catch(e => console.log(e))
  },
  methods: {
    transformData: function (data) {
      this.haulageLeadTime.id = this.$route.params.id
      this.haulageLeadTime.arrivalPointId = data.arrivalPoint.id
      this.haulageLeadTime.destinationPointId = data.destinationPoint.id
      this.haulageLeadTime.arrivalToAvailable = data.arrivalToAvailable
      this.haulageLeadTime.availableToAtDestination = data.availableToAtDestination
      this.haulageLeadTime.validFrom = data.validFrom
      this.haulageLeadTime.validTo = data.validTo
      this.haulageLeadTime.quickDemandDeduction = data.quickDemandDeduction
      this.haulageLeadTime.main = data.main
      this.haulageLeadTime.freightServiceId = data.freightService.id
      data.inboundFreightServices.forEach(freightService => this.haulageLeadTime.freightServiceIds.push(freightService.id))
      if (this.replaceHlt) {
        Object.assign(this.oldHaulageLeadTime, this.haulageLeadTime)
      }
    },
    validate () {
      return this.$refs['haulage-lead-time-update-form'].validate()
    },
    updateHaulageLeadTime () {
      this.viewDialog = false
      if (!this.replaceHlt) {
        axios.put('/api/touchpoint/haulage-lead-time', this.haulageLeadTime)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        this.createLLtsDialog = true
        this.dialog = true
      }
    },
    replaceHltAndSllt () {
      this.dialog = false
      this.createLLtsDialog = false
      axios.put('/api/touchpoint/haulage-lead-time/replace-and-create-logistic-lead-time', {
        createHaulageLeadTimeCommand: this.haulageLeadTime,
        updateHaulageLeadTimeCommand: this.haulageLeadTime
      })
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.dialog = true
            this.createLLtsResultDialog = true
          } else {
            this.validationErrorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },
    update () {
      if (this.validate()) {
        if (this.viewAffectedLogisticLeadTimes.length === 0) {
          this.updateHaulageLeadTime()
        } else {
          if (this.haulageLeadTime.validTo) {
            this.validateActiveSllt(this.haulageLeadTime.validTo)
          }
          if (this.replaceHlt && this.haulageLeadTime.validFrom) {
            this.validateActiveSllt(moment(this.haulageLeadTime.validFrom).subtract(1, 'days').format('YYYY-MM-DD'))
          }
          this.viewAffectedLLtsDialog(this.warningDialog)
        }
      } else {
        window.scrollTo(0, 0)
      }
    },
    cancel () {
      this.$router.push({ name: 'SearchHaulageLeadTime' })
    },
    createNew () {
      this.$router.push({ name: 'CreateHaulageLeadTime' })
    },
    copy () {
      this.sendDialog = false
    },
    search () {
      this.$router.push({ name: 'SearchHaulageLeadTime' })
    },
    saveAndCreateLLts () {
      this.validationErrorMessages = []
      this.createLLtsDialog = true
      this.dialog = true
    },
    closeSaveAndCreateLLtsDialog () {
      this.createLLtsDialog = false
      this.dialog = false
    },
    viewAffectedLLtsDialog () {
      if (!this.warningDialog) {
        this.viewDialog = true
      }
    },
    validateActiveSllt: function (date) {
      const haulageEndDate = new Date(date)
      const lltExistWithFutureStartDate = this.viewAffectedLogisticLeadTimes.some(sllt => new Date(sllt.validFrom) > haulageEndDate)
      if (lltExistWithFutureStartDate) {
        this.warningMessage = `Haulage lead time cannot end on '${date}' as there are connected SLLT valid from '${this.affectedMaxLltValidFromDate}'`
        this.warningDialog = true
        this.viewDialog = false
      }
    }
  }
}
</script>

<style scoped>
</style>
