<template>
  <v-container v-if="isLoaded">
    <v-toolbar flat>
      <base-input-primary-button v-if="canUserCreateUpdate"
          label="Create packing mode"
          @click="openCreateUpdateDialog"/>
      <base-table-excel-exporter
        v-if="packingModes.length"
        :excel-data="this.excelData"
        :fetch-all="true"
        file-name="PackingModesList"
        @fetchAll="downLoadExcel"/>
    </v-toolbar>
    <base-table-paged-with-search
      :items="this.packingModes"
      :table-headers="headers"
      :table-heading="this.heading"
    >
      <template v-slot:item.name="{ item }">
        <base-label-with-packing-mode :packing-mode="item"/>
      </template>
      <template v-slot:item.creationTime="{ item }">
        {{ item.creationTime | formatDate }}
      </template>
      <template v-slot:item.modificationTime="{ item }">
        {{ item.modificationTime | formatDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <base-tool-tip v-if="canUserCreateUpdate"
            icon="mdi-pencil"
            label="Edit"
            @click="editItem(item, true)">
        </base-tool-tip>
      </template>
    </base-table-paged-with-search>
    <v-form ref='create-update-form'>
      <base-dialog-plain
        :dialog="dialog"
        :ok-action-label="!this.packingModesInContext.id ? 'Save' : 'Update'"
        :persistent="true"
        @cancel="close"
        @ok="save">
        <v-alert v-if="errorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="errorMessages in errorMessages" v-bind:key="errorMessages">
            <li>{{errorMessages}}</li>
          </ul>
        </v-alert>
        <base-card-title
          :label="formTitle"/>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="11">
                <v-text-field v-if="this.packingModesInContext.id"
                  filled
                  id="externalId"
                  v-model="packingModesInContext.externalId"
                  :disabled="!!this.packingModesInContext.id"
                  label="Id"
                ></v-text-field>
                <v-text-field
                  filled
                  id="name"
                  v-model="packingModesInContext.name"
                  :rules="[rules.required]"
                  hint="Name must not exceed 100 characters"
                  label="Name"
                  :counter="100"
                  maxlength="100"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </base-dialog-plain>
    </v-form>
    <base-dialog-ok
      :dialog="successMessageDialog"
      :persistent="true"
      label="Packing mode saved successfully."
      @ok="() => this.successMessageDialog = false"
    />
  </v-container>

</template>

<script>
import BaseTablePagedWithSearch from '@/base/table/BaseTablePagedWithSearch'
import BaseTableExcelExporter from '@/base/table/BaseTableExcelExporter'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import axios from 'axios'
import BaseDialogPlain from '@/base/dialog/BaseDialogPlain'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseLabelWithPackingMode from '@/base/label/BaseLabelWithPackingMode'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewCreateOrUpdatePackingMode',
  components: {
    BaseDialogPlain,
    BaseTablePagedWithSearch,
    BaseTableExcelExporter,
    BaseDialogOk,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseToolTip,
    BaseLabelWithPackingMode
  },
  data: () => ({
    isLoaded: false,
    dialog: false,
    excelData: [],
    errorMessages: [],
    successMessageDialog: false,
    heading: 'Packing Modes',
    headers: [
      { text: 'Id', sortable: true, value: 'externalId' },
      { text: 'Name', sortable: true, value: 'name' },
      { text: 'Created', sortable: true, value: 'creationTime' },
      { text: 'Last modified', sortable: true, value: 'modificationTime' },
      { text: 'Action', align: 'center', sortable: false, value: 'action' }
    ],
    packingModesInContext: {},
    rules: {
      required: value => !!value || 'Required.'
    }
  }),

  computed: {
    formTitle () {
      return this.packingModesInContext.id ? 'Update Packing Mode' : 'Create Packing Mode'
    },
    ...mapGetters([
      'isMemberOfAny',
      'getUserRoles',
      'packingModes'
    ]),
    canUserCreateUpdate () {
      const rolesAllowed = ['ROLE_TECH_ADMIN', 'ROLE_ADMIN']
      return this.isMemberOfAny(rolesAllowed)
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllPackingModes'),
      this.$store.dispatch('getUser')
    ]).then(() => { this.isLoaded = true })
  },
  watch: {
    dialog (val) {
      this.errorMessages = []
      val || this.close()
    }
  },
  methods: {

    editItem (item, editDialogPopUp) {
      this.packingModesInContext = {
        ...item
      }
      editDialogPopUp ? this.dialog = editDialogPopUp : this.updatePackingMode()
    },

    save () {
      if (this.$refs['create-update-form'].validate()) {
        this.errorMessages = []
        this.packingModesInContext.id ? this.updatePackingMode() : this.createPackingMode()
      }
    },

    createPackingMode () {
      if (!this.errorMessages.length) {
        axios.post('/api/touchpoint/logistics-master-data/packing-modes/create', {
          name: this.packingModesInContext.name
        })
          .then(response => {
            if (response.data.status === 'SUCCESS') {
              this.success()
            } else {
              this.errorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      }
    },

    updatePackingMode () {
      axios.put('/api/touchpoint/logistics-master-data/packing-modes/update', {
        id: this.packingModesInContext.id,
        externalId: this.packingModesInContext.externalId,
        name: this.packingModesInContext.name
      })
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.success()
          } else {
            this.errorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },

    success () {
      this.$store.commit('reloadPackingModes')
      this.close()
      this.successMessageDialog = true
    },

    close () {
      this.packingModesInContext = {}
      this.dialog = false
      this.$refs['create-update-form'].resetValidation()
    },

    openCreateUpdateDialog () {
      this.packingModesInContext = {}
      this.dialog = true
    },

    downLoadExcel () {
      this.excelData = []
      this.packingModes.forEach(data => {
        this.excelData.push({
          Id: data.externalId,
          Name: data.name,
          Created: this.$options.filters.formatDate(data.creationTime),
          'Last modified': this.$options.filters.formatDate(data.modificationTime)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
