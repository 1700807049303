<template>
    <v-dialog
      v-model="viewDialog"
      scrollable
      max-width="750px">
      <v-card v-if="viewDialog">
        <base-card-title label="Additional shipping lead time details"/>
        <v-card-text style="height: 900px;" class="text-body-1 text--primary">
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Freight services
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-freight-services
                :freight-services="viewDialogAdditionalShippingLeadTime.freightServices"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Place of loading
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag v-if="viewDialogAdditionalShippingLeadTime.placeOfLoading"
                :item-country="viewDialogAdditionalShippingLeadTime.placeOfLoading.location.code"
                :item-label="viewDialogAdditionalShippingLeadTime.placeOfLoading.description"/>
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Place of delivery
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag v-if="viewDialogAdditionalShippingLeadTime.placeOfDelivery"
                :item-country="viewDialogAdditionalShippingLeadTime.placeOfDelivery.location.code"
                :item-label="viewDialogAdditionalShippingLeadTime.placeOfDelivery.description"/>
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Place of loading country
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag v-if="viewDialogAdditionalShippingLeadTime.placeOfLoadingCountry"
                :item-country="viewDialogAdditionalShippingLeadTime.placeOfLoadingCountry.code"
                :item-label="viewDialogAdditionalShippingLeadTime.placeOfLoadingCountry.description"/>
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Place of delivery country
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag v-if="viewDialogAdditionalShippingLeadTime.placeOfDeliveryCountry"
                :item-country="viewDialogAdditionalShippingLeadTime.placeOfDeliveryCountry.code"
                :item-label="viewDialogAdditionalShippingLeadTime.placeOfDeliveryCountry.description"/>
            </v-col>
          </v-row>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-row class="mt-2 mr-0" >
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Additional lead time
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogAdditionalShippingLeadTime.additionalLeadTimeInDays }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Valid from
            </v-col>
            <v-col class="pt-2 pb-2 pr-0" v-if="viewDialogAdditionalShippingLeadTime.validFrom">
              <v-icon medium>mdi-clock</v-icon> {{ viewDialogAdditionalShippingLeadTime.validFrom }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Valid to
            </v-col>
            <v-col class="pt-2 pb-2 pr-0" v-if="viewDialogAdditionalShippingLeadTime.validTo">
              <v-icon medium>mdi-clock</v-icon> {{ viewDialogAdditionalShippingLeadTime.validTo }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseLabelWithFreightServices from '@/base/label/BaseLabelWithFreightServices'

export default {
  name: 'SearchShippingLeadTimeViewAdditionalLeadTimeDetailsDialog',
  components: {
    BaseLabelWithFreightServices,
    BaseInputSecondaryButton,
    BaseLabelWithFlag,
    BaseCardTitle
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogAdditionalShippingLeadTime: {
      type: Object,
      require: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
