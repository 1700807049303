<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth">
    <v-card>
      <v-card-text>
        <v-row justify="center" class="pt-10 mb-5">
          <v-icon v-if="showIcon" size="80" color="green">mdi-checkbox-marked-circle</v-icon>
          <v-icon v-if="showWarningIcon" size="50" color="red">mdi-alert</v-icon>
        </v-row>
        <v-row justify="center" class="mb-5">
          {{ label }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button icon="mdi-check-decagram" @click="$emit('ok')" label="Ok"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'

export default {
  name: 'BaseDialogOk',
  components: {
    BaseInputPrimaryButton
  },
  props: {
    dialog: {
      type: Boolean,
      require: true
    },
    label: {
      type: String,
      require: true
    },
    maxWidth: {
      type: Number,
      require: false,
      default: 750
    },
    showIcon: {
      type: Boolean,
      require: false,
      default: true
    },
    showWarningIcon: {
      type: Boolean,
      require: false,
      default: false
    },
    persistent: {
      type: Boolean,
      require: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
