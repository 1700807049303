<template>
    <v-row>
      <v-col class="mt-8">
        <v-alert v-if="validationErrorMessages && validationErrorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
            <li>{{validationErrorMessage.message}}</li>
          </ul>
        </v-alert>
        <v-card class="mb-6">
          <base-card-title
            label="Create Haulage lead time"
            icon="mdi-truck-delivery-outline"/>
          <div class="text-h6 mb-3 mt-4 ml-4">Location points</div>
          <v-row>
            <v-col cols="10" class="pb-0 pt-4 ml-6">
              <base-input-select-with-flag
                item-label="Arrival point"
                :items="transportLocations"
                item-country="location.code"
                item-text="description"
                item-value="id"
                :rules="[rules.required]"
                :selected-value="haulageLeadTime.arrivalPointId"
                @change="haulageLeadTime.arrivalPointId = arguments[0]"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="pb-0 pt-0 ml-6">
              <base-input-select-with-flag
                item-label="Destination point"
                :items="destinationPoints"
                item-country="location.code"
                item-text="description"
                item-value="id"
                :rules="[rules.required]"
                :selected-value="haulageLeadTime.destinationPointId"
                @change="haulageLeadTime.destinationPointId = arguments[0]"/>
            </v-col>
          </v-row>
          <div class="text-h6 mb-3 mt-0 ml-4">Transport modes</div>
          <v-row>
            <v-col cols="10" class="pb-0 pt-4 ml-6">
              <base-input-multi-select-with-freight-service
                item-label="Inbound freight service"
                :grouped-freight-services="groupedFreightServices"
                :freight-services="freightServices"
                :selected-value="haulageLeadTime.freightServiceIds"
                :rules="[rules.selected]"
                @change="haulageLeadTime.freightServiceIds = arguments[0]"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="pb-0 pt-0 ml-6">
              <base-input-select-with-freight-service
                item-label="Haulage MoT"
                :rules="[rules.required]"
                :freight-services="freightServices"
                :selected-value="haulageLeadTime.freightServiceId"
                @change="haulageLeadTime.freightServiceId = arguments[0]"/>
            </v-col>
          </v-row>
          <div class="text-h6 mb-3 mt-0 ml-4">Lead times</div>
          <v-row>
            <v-col cols="10" class="pb-0 pt-4 ml-6">
              <base-input-number-field
                item-label="Arrival to available"
                :value="haulageLeadTime.arrivalToAvailable"
                @blur="haulageLeadTime.arrivalToAvailable = arguments[0]"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="pb-0 pt-0 ml-6">
              <base-input-number-field
                item-label="Available to destination"
                :value="haulageLeadTime.availableToAtDestination"
                @blur="haulageLeadTime.availableToAtDestination = arguments[0]"/>
            </v-col>
          </v-row>
          <div class="text-h6 mb-3 ml-4 mb-6">Quick demand deduction</div>
          <v-row>
            <v-col cols="10" class="pb-0 pt-0 ml-6">
              <base-input-number-field
                item-label="Quick demand deduction"
                :value="haulageLeadTime.quickDemandDeduction"
                @blur="haulageLeadTime.quickDemandDeduction = arguments[0]"/>
            </v-col>
          </v-row>
          <div class="text-h6 mb-3 mt-0 ml-4">Valid</div>
          <v-row>
            <v-col cols="10" class="pb-0 pt-4 ml-6">
              <base-input-select-date-picker
                item-label="Valid from"
                :selected-value="haulageLeadTime.validFrom"
                :rules="[rules.required]"
                @change="haulageLeadTime.validFrom = arguments[0]"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="pb-0 pt-0 ml-6">
              <base-input-select-date-picker
                item-label="Valid to"
                :selected-value="haulageLeadTime.validTo"
                :rules="[rules.fromDateIsBeforeToDate]"
                @change="haulageLeadTime.validTo = arguments[0]"/>
            </v-col>
          </v-row>
        </v-card>
        <base-input-primary-button
          label="save"
          icon="mdi-arrow-right-bold-circle"
          @click="save"/>

        <base-input-secondary-button
          label="cancel"
          icon="mdi-cancel"
          @click="cancel"/>
      </v-col>
      <v-col v-if="timelineRequired" cols="5" class="mt-8">
        <create-haulage-lead-time-time-line
          :haulage-lead-time="haulageLeadTime"
          :arrival-points="transportLocations"
          :custom-clearance-points="transportLocations"
          :destination-points="transportLocations"
          :freightServices="freightServices"/>
      </v-col>
    </v-row>
</template>

<script>
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import CreateHaulageLeadTimeTimeLine from '@/components/haulageleadtime/createhaulageleadtime/CreateHaulageLeadTimeTimeLine'
import BaseInputNumberField from '@/base/input/BaseInputNumberField'

export default {
  name: 'CreateHaulageOrProposedHaulageLeadtime',
  components: {
    BaseInputSelectWithFlag,
    BaseInputSelectDatePicker,
    BaseInputMultiSelectWithFreightService,
    BaseInputSelectWithFreightService,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    CreateHaulageLeadTimeTimeLine,
    BaseInputNumberField,
    BaseCardTitle
  },
  props: {
    haulageLeadTime: {
      type: Object,
      require: true
    },
    transportLocations: {
      type: Array,
      require: true
    },
    destinationPoints: {
      type: Array,
      require: true
    },
    groupedFreightServices: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    rules: {
      type: Object,
      require: false
    },
    timelineRequired: {
      type: Boolean,
      require: true
    },
    validationErrorMessages: {
      type: Array,
      require: false
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
