<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" >
                <base-label-with-flag v-if="moreThanOne"
                                      :item-label="morePlanningMarketLabel()"
                                      :item-country="firstPlanningMarket.logisticCountry.code"/>

                <base-label-with-flag v-else
                                      :item-label="firstPlanningMarket.abbreviation"
                                      :item-country="firstPlanningMarket.logisticCountry.code"/>
              </span>
    </template>
    <span>
      <div v-for="planningMarket in planningMarkets"  v-bind:key="planningMarket.id">
        <base-label-with-flag :item-country="planningMarket.logisticCountry.code" :item-label="planningMarket.description"/>
      </div>
    </span>
  </v-tooltip>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'

export default {
  name: 'BasePlanningMarketWithToolTip',
  components: {
    BaseLabelWithFlag
  },
  props: {
    planningMarkets: {
      type: Array,
      require: true
    }
  },
  computed: {
    moreThanOne () {
      return this.planningMarkets.length > 1
    },
    firstPlanningMarket () {
      return this.planningMarkets[0]
    }
  },
  methods: {
    morePlanningMarketLabel () {
      return this.firstPlanningMarket.abbreviation + ' (+' + (this.planningMarkets.length - 1) + ' others)'
    }
  }
}
</script>

<style scoped>

</style>
