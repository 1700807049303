import { render, staticRenderFns } from "./BaseInputSelectWithFlag.vue?vue&type=template&id=6fbaf6d1&scoped=true&"
import script from "./BaseInputSelectWithFlag.vue?vue&type=script&lang=js&"
export * from "./BaseInputSelectWithFlag.vue?vue&type=script&lang=js&"
import style0 from "./BaseInputSelectWithFlag.vue?vue&type=style&index=0&id=6fbaf6d1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbaf6d1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
