<template>
  <v-container>
    <v-form ref="haulage-lead-time-create-form">
      <create-haulage-or-proposed-haulage-leadtime
        :haulage-lead-time="haulageLeadTime"
        :transport-locations="transportLocations"
        :destination-points="destinationPoints"
        :freight-services="freightServices"
        :grouped-freight-services="groupedFreightServices"
        :validation-error-messages="validationErrorMessages"
        :rules="rules"
        :timelineRequired="true"
        @save="save"
        @cancel="cancel"/>
    </v-form>
    <base-dialog-send
        label="Haulage lead time has been saved successfully!"
        @create="createNew"
        @copy="copy"
        @search="search"
        :send-dialog="sendDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import CreateHaulageOrProposedHaulageLeadtime from '@/components/haulageleadtime/createhaulageleadtime/CreateHaulageOrProposedHaulageLeadtime'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import { validateDate } from '@/validation/customvalidation'

export default {
  name: 'CreateHaulageLeadTime',
  components: { CreateHaulageOrProposedHaulageLeadtime, BaseDialogSend },
  data () {
    return {
      sendDialog: false,
      validationErrorMessages: [],
      transportLocations: [],
      destinationPoints: [],
      freightServices: [],
      groupedFreightServices: [],
      serviceProviders: [],
      carriers: [],
      haulageLeadTime: {
        availablePointId: '',
        customsClearancePointId: '',
        destinationPointId: '',
        freightServiceIds: [],
        freightServiceId: '',
        arrivalToAvailable: '',
        availableToAtDestination: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.haulageLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  mounted () {
    if (this.$route.params.id != null) {
      axios.get('/api/touchpoint/haulage-lead-time/' + this.$route.params.id)
        .then((response) => this.mapHaulageLeadTimeResponse(response.data))
        .catch(e => console.log(e))
    }

    axios.get('/api/touchpoint/transportLocations')
      .then(response => ([...response.data]).forEach(data => {
        this.transportLocations.push(data)
      }))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transport-location/place-of-discharge-or-delivery')
      .then(response => ([...response.data]).forEach(data => this.destinationPoints.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/grouped-freight-services')
      .then(response => ([...response.data]).forEach(data => this.groupedFreightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))
  },
  methods: {
    validate () {
      return this.$refs['haulage-lead-time-create-form'].validate()
    },
    mapHaulageLeadTimeResponse (data) {
      this.haulageLeadTime.arrivalPointId = data.arrivalPoint.id
      this.haulageLeadTime.destinationPointId = data.destinationPoint.id
      data.inboundFreightServices
        .forEach(freightService => this.haulageLeadTime.freightServiceIds.push(freightService.id))
      this.haulageLeadTime.freightServiceId = data.freightService.id
      this.haulageLeadTime.arrivalToAvailable = data.arrivalToAvailable
      this.haulageLeadTime.availableToAtDestination = data.availableToAtDestination
      this.haulageLeadTime.validFrom = data.validFrom
      this.haulageLeadTime.validTo = data.validTo
    },
    save () {
      if (this.validate()) {
        axios.post('/api/touchpoint/haulage-lead-time', this.haulageLeadTime)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    cancel () {
      this.$router.push({ name: 'SearchHaulageLeadTime' })
    },
    createNew () {
      this.sendDialog = false
      this.haulageLeadTime = {
        availablePointId: '',
        customsClearancePointId: '',
        destinationPointId: '',
        freightServiceIds: [],
        freightServiceId: '',
        arrivalToAvailable: '',
        availableToAtDestination: '',
        validFrom: '',
        validTo: ''
      }
    },
    copy () {
      this.sendDialog = false
    },
    search () {
      this.$router.push({ name: 'SearchHaulageLeadTime' })
    }
  }
}
</script>

<style scoped>
</style>
