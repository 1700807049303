<template>
  <v-container v-if="isLoaded">
    <v-toolbar flat>
      <base-input-primary-button v-if="canUserCreateUpdate"
          label="Create Inco term"
          @click="openCreateUpdateDialog"/>
      <base-table-excel-exporter
        v-if="this.incoTermStatus ? this.activeIncoTerms.length : this.incoTerms.length"
        :excel-data="this.excelData"
        :fetch-all="true"
        file-name="IncoTermsList"
        @fetchAll="downLoadExcel"/>
      <v-spacer></v-spacer>
      <v-checkbox
        class="mt-0"
        v-model="incoTermStatus"
        @change="fetchIncoTerms()"
        label="Only active Inco terms"/>
    </v-toolbar>
    <base-table-paged-with-search
      :items="this.incoTermStatus ? this.activeIncoTerms : this.incoTerms"
      :table-headers="headers"
      :table-heading="this.heading"
    >
      <template v-slot:item.creationTime="{ item }">
        {{ item.creationTime | formatDate }}
      </template>
      <template v-slot:item.modificationTime="{ item }">
        {{ item.modificationTime | formatDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <base-tool-tip v-if="canUserCreateUpdate"
                       icon="mdi-pencil"
                       label="Edit"
                       @click="editItem(item, true)">
        </base-tool-tip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
          v-model="item.active"
          @change="updateIncoTermStatus(item)">
        </v-switch>
      </template>
    </base-table-paged-with-search>
    <v-form ref='create-update-form'>
      <base-dialog-plain
        :dialog="dialog"
        :ok-action-label="!this.incoTermsInContext.id ? 'Save' : 'Update'"
        :persistent="true"
        @cancel="close"
        @ok="save">
        <v-alert v-if="errorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="errorMessages in errorMessages" v-bind:key="errorMessages">
            <li>{{errorMessages}}</li>
          </ul>
        </v-alert>
        <base-card-title
          :label="formTitle"/>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="11">
                <v-text-field v-if="this.incoTermsInContext.id"
                  filled
                  id="externalId"
                  v-model="incoTermsInContext.externalId"
                  :disabled="!!this.incoTermsInContext.id"
                  label="Id"
                ></v-text-field>
                <v-text-field
                  filled
                  id="code"
                  v-model="incoTermsInContext.code"
                  :disabled="!!this.incoTermsInContext.id"
                  :rules="[rules.required, rules.code]"
                  :counter="3"
                  maxlength="3"
                  @keyup="incoTermsInContext.code = incoTermsInContext.code.toUpperCase()"
                  label="Code"
                ></v-text-field>
                <v-text-field
                  filled
                  id="name"
                  v-model="incoTermsInContext.name"
                  :rules="[rules.required]"
                  hint="Name must not exceed 100 characters"
                  label="Name"
                  :counter="100"
                  maxlength="100"
                ></v-text-field>
                <v-row>
                  <v-card-text class="pl-3 pb-0 mb-0 subtitle-1">Active ?</v-card-text>
                  <v-switch class="pl-3 pt-1 mt-1"
                            v-model="incoTermsInContext.active">
                  </v-switch>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </base-dialog-plain>
    </v-form>
    <base-dialog-ok
      :dialog="successMessageDialog"
      :persistent="true"
      label="Inco term saved successfully."
      @ok="() => this.successMessageDialog = false"
    />
  </v-container>

</template>

<script>
import BaseTablePagedWithSearch from '@/base/table/BaseTablePagedWithSearch'
import BaseTableExcelExporter from '@/base/table/BaseTableExcelExporter'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import axios from 'axios'
import BaseDialogPlain from '@/base/dialog/BaseDialogPlain'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewCreateOrUpdateIncoTerm',
  components: {
    BaseDialogPlain,
    BaseTablePagedWithSearch,
    BaseTableExcelExporter,
    BaseDialogOk,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseToolTip
  },
  data: () => ({
    isLoaded: false,
    dialog: false,
    excelData: [],
    errorMessages: [],
    successMessageDialog: false,
    incoTermStatus: true,
    heading: 'Inco Terms',
    headers: [
      { text: 'Id', sortable: true, value: 'externalId' },
      { text: 'Code', sortable: true, value: 'code' },
      { text: 'Name', sortable: true, value: 'name' },
      { text: 'Created', sortable: true, value: 'creationTime' },
      { text: 'Last modified', sortable: true, value: 'modificationTime' },
      { text: 'Active?', sortable: true, value: 'active' },
      { text: 'Action', align: 'center', sortable: false, value: 'action' }
    ],
    incoTermsInContext: {},
    rules: {
      required: value => !!value || 'Required.',
      code: value => {
        const regexPattern = /^([A-Z]{3})$/
        return regexPattern.test(value) || 'Code must be of three upper characters.'
      },
      externalId: value => {
        const regexPattern = /^([1-9]|[1-9][0-9]|[1-9][0-9][0-9])$/
        return regexPattern.test(value) || 'Id must be in the range 1 to 999'
      }
    }
  }),

  computed: {
    formTitle () {
      return this.incoTermsInContext.id ? 'Update Inco Term' : 'Create Inco Term'
    },
    ...mapGetters([
      'isMemberOfAny',
      'getUserRoles',
      'activeIncoTerms',
      'incoTerms'
    ]),
    canUserCreateUpdate () {
      const rolesAllowed = ['ROLE_TECH_ADMIN', 'ROLE_ADMIN']
      return this.isMemberOfAny(rolesAllowed)
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getUser'),
      this.$store.dispatch('getAllIncoTerms')
    ]).then(() => {
      this.isLoaded = true
    })
  },
  watch: {
    dialog (val) {
      this.errorMessages = []
      val || this.close()
    }
  },
  methods: {

    editItem (item, editDialogPopUp) {
      this.incoTermsInContext = {
        ...item
      }
      editDialogPopUp ? this.dialog = editDialogPopUp : this.updateIncoTerm()
    },

    save () {
      if (this.$refs['create-update-form'].validate()) {
        this.errorMessages = []
        this.incoTermsInContext.id ? this.updateIncoTerm() : this.createIncoTerm()
      }
    },

    createIncoTerm () {
      this.duplicateCodeCheck()
      if (!this.errorMessages.length) {
        axios.post('/api/touchpoint/logistics-master-data/inco-terms/create', {
          externalId: this.incoTermsInContext.externalId,
          code: this.incoTermsInContext.code,
          name: this.incoTermsInContext.name,
          active: this.incoTermsInContext.active
        })
          .then(response => {
            if (response.data.status === 'SUCCESS') {
              this.success()
            } else {
              this.errorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      }
    },

    updateIncoTerm () {
      axios.put('/api/touchpoint/logistics-master-data/inco-terms/update', {
        id: this.incoTermsInContext.id,
        externalId: this.incoTermsInContext.externalId,
        code: this.incoTermsInContext.code,
        name: this.incoTermsInContext.name,
        active: this.incoTermsInContext.active
      })
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.success()
          } else {
            this.errorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },

    success () {
      this.$store.commit('reloadIncoTerms')
      this.close()
      this.fetchIncoTerms()
      this.successMessageDialog = true
    },

    close () {
      this.incoTermsInContext = {}
      this.dialog = false
      this.$refs['create-update-form'].resetValidation()
    },

    duplicateCodeCheck () {
      this.incoTerms.forEach((item) => {
        if (item.code === this.incoTermsInContext.code) {
          this.errorMessages.push('Code: ' + this.incoTermsInContext.code + ' already exists.')
        }
      })
    },

    openCreateUpdateDialog () {
      this.incoTermsInContext = {
        active: true
      }
      this.dialog = true
    },

    downLoadExcel () {
      this.excelData = []
      this.incoTerms.forEach(data => {
        this.excelData.push({
          Id: data.externalId,
          Code: data.code,
          Name: data.name,
          Active: data.active.toString().toLowerCase(),
          Created: this.$options.filters.formatDate(data.creationTime),
          'Last modified': this.$options.filters.formatDate(data.modificationTime)
        })
      })
    },

    updateIncoTermStatus (item) {
      axios.put('/api/touchpoint/logistics-master-data/inco-terms/update', item)
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.success()
          } else {
            this.errorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },

    fetchIncoTerms () {
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>
