var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"filled":"","items":_vm.items,"item-text":_vm.itemText,"item-value":_vm.itemValue,"label":_vm.itemLabel,"multiple":"","disabled":_vm.isDisabled,"clearable":""},on:{"change":function($event){return _vm.$emit('change', _vm.theSelectValueItems)}},scopedSlots:_vm._u([(_vm.selectAllOption)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.selectAll}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.theSelectValueItems.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.logisticLeadTimeToDisplay === 'mot')?_c('div',[_c('base-label-with-mode-of-transport',{attrs:{"mode-of-transport":item}})],1):(_vm.logisticLeadTimeToDisplay === 'motCategory')?_c('div',[_c('base-label-with-mode-of-transport-category',{attrs:{"mode-of-transport-category":item}})],1):(_vm.logisticLeadTimeToDisplay === 'packingMode')?_c('div',[_c('base-label-with-packing-mode',{attrs:{"packing-mode":item}})],1):_c('div',[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item[_vm.itemText]))]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-2 grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.theSelectValueItems.length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.theSelectValueItems),callback:function ($$v) {_vm.theSelectValueItems=$$v},expression:"theSelectValueItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }