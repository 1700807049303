<template>
  <v-container>
    <v-card class="elevation-2 pa-5 home-img">
      <v-img alt="Shipping & Logistic Lead Time Flow"
             class="shrink mr-2"
             contain
             src="@/assets/home.png"/>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'Home',
  methods: {
    goto (routeName) {
      this.$router.push({ name: routeName })
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1300px;
}

.sheet {
  width: 100%;
  transition: opacity .4s ease-in-out;
}

.sheet:not(.on-hover) {
  opacity: 0.8;
}

.title-center {
  margin: auto;
  width: 50%;
  text-align: center;
  opacity: 0.7;
  font-size: 1.8rem;
}

.home-img {
  display: block;
  vertical-align: middle;
  max-height: 100%;
  margin: 10% auto auto;
}

.center {
  margin: 10% auto auto;
  width: 50%;
}

.home-icon {
  margin: 20px 10px 10px;
  font-size: 50px;
  color: #006994;
  position: relative;
}

.connection-icon {
  margin-left: 5px;
}

.right-text {
  float: right;
  width: 15%;
  margin-top: 10px;
}

.left-text {
  float: left;
  width: 12%;
  margin-top: 10px;
}

.title {
  margin: auto;
  width: 50%;
  text-align: center;
}
</style>
