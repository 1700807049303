<template>
  <v-container>
    <v-card class="elevation-2">
      <div class="center">
        <h3>You have successfully logged out of SLLT application.</h3>
        <div class="center">
          <base-input-primary-button
              label="Login again"
              icon="mdi-arrow-right-bold-circle"
              @click="login" />
        </div>

      </div>
    </v-card>
  </v-container>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
export default {
  name: 'Logout.vue',
  components: { BaseInputPrimaryButton },
  mounted () {
    localStorage.clear()
  },
  methods: {
    login () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
.center {
  width: 50%;
  margin: 0 auto;
  padding: 20px 0;
}
</style>
