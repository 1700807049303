import { render, staticRenderFns } from "./BaseInputMultiSelectWithFreightService.vue?vue&type=template&id=75958ad5&scoped=true&"
import script from "./BaseInputMultiSelectWithFreightService.vue?vue&type=script&lang=js&"
export * from "./BaseInputMultiSelectWithFreightService.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75958ad5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VSelect,VTreeview})
