<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
        Haulage lead time details
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text style="height: 900px;" class="text-body-1 text--primary">

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Arrival point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" >
            <base-label-with-flag
              v-if="viewDialogHaulageLeadTime.arrivalPoint"
              :item-country="viewDialogHaulageLeadTime.arrivalPoint.location.code"
              :item-label="viewDialogHaulageLeadTime.arrivalPoint.description"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Destination point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" >
            <base-label-with-flag
              v-if="viewDialogHaulageLeadTime.destinationPoint"
              :item-country="viewDialogHaulageLeadTime.destinationPoint.location.code"
              :item-label="viewDialogHaulageLeadTime.destinationPoint.description"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Inbound freight services
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-services :freight-services="viewDialogHaulageLeadTime.inboundFreightServices"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Haulage mode of transport
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freightService="viewDialogHaulageLeadTime.freightService"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Arrival to available (days)
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogHaulageLeadTime.arrivalToAvailable }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Available to at destination (days)
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogHaulageLeadTime.availableToAtDestination }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Quick demand deduction days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogHaulageLeadTime.quickDemandDeduction }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogHaulageLeadTime.totalDays }}
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid from:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogHaulageLeadTime.validFrom" >
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogHaulageLeadTime.validFrom | formatDate }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid to:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogHaulageLeadTime.validTo">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogHaulageLeadTime.validTo | formatDate }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button v-if="canUpdate" label="Update" icon="mdi-pencil" @click="update"/>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseLabelWithFreightServices from '@/base/label/BaseLabelWithFreightServices'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'

export default {
  name: 'SearchHaulageLeadTimeViewDetailsDialog',
  components: {
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseLabelWithFreightServices,
    BaseLabelWithFlag,
    BaseLabelWithFreightService
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogHaulageLeadTime: {
      type: Object,
      require: true
    },
    canUpdate: {
      type: Boolean,
      require: true,
      default: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    update (item) {
      this.$router.push({ path: '/HaulageLeadTime/Update/' + this.viewDialogHaulageLeadTime.id })
    }
  }
}
</script>

<style scoped>

</style>
