<template>
  <v-dialog persistent v-model="dialog" max-width="750">
    <v-card>
      <v-card-text>
        <v-row justify="center" class="pt-16 mb-5">
          {{ label }}
        </v-row>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button @click="$emit('yes')" label="Yes"/>
        <base-input-secondary-button @click="$emit('no')" label="No"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
export default {
  name: 'BaseDialogYesNo',
  components: {
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  props: {
    dialog: {
      type: Boolean,
      require: true
    },
    label: {
      type: String,
      require: true
    }
  }
}
</script>

<style scoped>

</style>
