var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[(_vm.canUserCreateUpdate)?_c('base-input-primary-button',{attrs:{"label":"Create packing mode"},on:{"click":_vm.openCreateUpdateDialog}}):_vm._e(),(_vm.packingModes.length)?_c('base-table-excel-exporter',{attrs:{"excel-data":this.excelData,"fetch-all":true,"file-name":"PackingModesList"},on:{"fetchAll":_vm.downLoadExcel}}):_vm._e()],1),_c('base-table-paged-with-search',{attrs:{"items":this.packingModes,"table-headers":_vm.headers,"table-heading":this.heading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('base-label-with-packing-mode',{attrs:{"packing-mode":item}})]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.creationTime))+" ")]}},{key:"item.modificationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.modificationTime))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canUserCreateUpdate)?_c('base-tool-tip',{attrs:{"icon":"mdi-pencil","label":"Edit"},on:{"click":function($event){return _vm.editItem(item, true)}}}):_vm._e()]}}],null,false,1858603932)}),_c('v-form',{ref:"create-update-form"},[_c('base-dialog-plain',{attrs:{"dialog":_vm.dialog,"ok-action-label":!this.packingModesInContext.id ? 'Save' : 'Update',"persistent":true},on:{"cancel":_vm.close,"ok":_vm.save}},[(_vm.errorMessages.length > 0)?_c('v-alert',{attrs:{"color":"red lighten-2","dark":""}},_vm._l((_vm.errorMessages),function(errorMessages){return _c('ul',{key:errorMessages},[_c('li',[_vm._v(_vm._s(errorMessages))])])}),0):_vm._e(),_c('base-card-title',{attrs:{"label":_vm.formTitle}}),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"11"}},[(this.packingModesInContext.id)?_c('v-text-field',{attrs:{"filled":"","id":"externalId","disabled":!!this.packingModesInContext.id,"label":"Id"},model:{value:(_vm.packingModesInContext.externalId),callback:function ($$v) {_vm.$set(_vm.packingModesInContext, "externalId", $$v)},expression:"packingModesInContext.externalId"}}):_vm._e(),_c('v-text-field',{attrs:{"filled":"","id":"name","rules":[_vm.rules.required],"hint":"Name must not exceed 100 characters","label":"Name","counter":100,"maxlength":"100"},model:{value:(_vm.packingModesInContext.name),callback:function ($$v) {_vm.$set(_vm.packingModesInContext, "name", $$v)},expression:"packingModesInContext.name"}})],1)],1)],1)],1)],1)],1),_c('base-dialog-ok',{attrs:{"dialog":_vm.successMessageDialog,"persistent":true,"label":"Packing mode saved successfully."},on:{"ok":function () { return this$1.successMessageDialog = false; }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }