<template>
  <v-container>
    <v-form ref="transit-lead-time-update-form">
      <v-row>
        <v-col class="mt-8">
          <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
            <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
              <li>{{validationErrorMessage.message}}</li>
            </ul>
          </v-alert>
          <v-card class="mb-6">
            <base-card-title
              label="Update Transit lead time"
              icon="mdi-calendar-import"/>
            <div class="text-h6 mb-3 mt-4 ml-4">Location points</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-with-flag-read-only
                  :items="transitWarehouses"
                  item-value="id"
                  item-text="description"
                  item-country="geographicalCountry.code"
                  item-label="Transit warehouse"
                  :rules="[rules.required]"
                  :selected-value="transitLeadTime.transitWarehouseId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Available point"
                  :items="transportLocations"
                  item-country="location.code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="transitLeadTime.availablePointId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Place of delivery"
                  :items="transportLocations"
                  item-country="location.code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="transitLeadTime.placeOfDeliveryId"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 mt-4 ml-4">Transport modes</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-with-freight-service v-if="!replaceTlt"
                  :freight-services="freightServices"
                  item-label="Inbound freight service"
                  :rules="[rules.required]"
                  :selected-value="transitLeadTime.inboundFreightServiceId"
                  @change="transitLeadTime.inboundFreightServiceId = arguments[0]"/>
                <base-input-select-with-freight-service-read-only v-if="replaceTlt"
                  :freight-services="freightServices"
                  item-label="Inbound freight service"
                  :selected-value="transitLeadTime.inboundFreightServiceId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-with-freight-service-read-only
                  :freight-services="freightServices"
                  item-label="Outbound freight service"
                  :rules="[rules.required]"
                  :selected-value="transitLeadTime.outboundFreightServiceId"/>
              </v-col>
            </v-row>

            <div class="text-h6 mb-3 mt-4 ml-4">Lead times</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-number-field
                  item-label="Arrival to available"
                  :value="transitLeadTime.arrivalToAvailable"
                  @blur="transitLeadTime.arrivalToAvailable = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Available to in-transit"
                  :value="transitLeadTime.availableToInTransit"
                  @blur="transitLeadTime.availableToInTransit = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="In-transit to departure"
                  :value="transitLeadTime.inTransitToDeparture"
                  @blur="transitLeadTime.inTransitToDeparture = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Departure to place of delivery"
                  :value="transitLeadTime.departureToPlaceOfDelivery"
                  @blur="transitLeadTime.departureToPlaceOfDelivery = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 mt-0 ml-4">Valid</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-date-picker-read-only v-if="!replaceTlt"
                  item-label="Valid from"
                  :value="transitLeadTime.validFrom"
                  @change="transitLeadTime.validFrom = arguments[0]"/>
                <base-input-select-date-picker v-if="replaceTlt"
                  item-label="Valid from"
                  :selected-value="transitLeadTime.validFrom"
                  :rules="[rules.required , rules.fromDateIsBeforeMaxLLtFromDate]"
                  @change="transitLeadTime.validFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :rules="[rules.fromDateIsBeforeToDate]"
                  :selected-value="transitLeadTime.validTo"
                  @change="transitLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            :label="replaceTlt ? 'Replace' : 'Update'"
            icon="mdi-arrow-right-bold-circle"
            @click="update"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>
        <v-col cols="5" class="mt-8">
          <create-transit-lead-time-time-line
            :transit-lead-time="transitLeadTime"
            :warehouses="transitWarehouses"
            :locations="transportLocations"
            :freightServices="freightServices"/>
        </v-col>
      </v-row>
    </v-form>
    <affected-logistic-lead-times-view-dialog
      :view-dialog="viewDialog"
      :view-affected-logistic-lead-times="viewAffectedLogisticLeadTimes"
      @cancel="viewDialog = false"
      @confirm="updateTransitLeadTime"/>

    <base-dialog-send
      label="Transit lead time has been saved successfully!"
      @create="createNew"
      @search="search"
      :send-dialog="sendDialog"/>
    <base-dialog-ok :label="warningMessage"
                    :show-icon="false"
                    :show-warning-icon="true"
                    :dialog="warningDialog"
                    @ok="() => this.warningDialog = false"/>
    <replace-transit-lead-time-dialog :dialog="dialog"
                                      :createLLtsDialog="createLLtsDialog"
                                      @replaceTltAndSllt="replaceTltAndSllt"
                                      @closeSaveAndCreateLLtsDialog="closeSaveAndCreateLLtsDialog"
                                      :create-l-lts-result-dialog="createLLtsResultDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputNumberField from '@/base/input/BaseInputNumberField'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import CreateTransitLeadTimeTimeLine from '@/components/transitleadtime/createtransitleadtime/CreateTransitLeadTimeTimeLine'
import AffectedLogisticLeadTimesViewDialog from '@/views/logisticsleadtime/AffectedLogisticLeadTimesViewDialog'
import { validateDate } from '@/validation/customvalidation'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import BaseInputSelectWithFlagReadOnly from '@/base/input/BaseInputSelectWithFlagReadOnly'
import BaseInputSelectWithFreightServiceReadOnly from '@/base/input/BaseInputSelectWithFreightServiceReadOnly'
import { max } from 'lodash'
import BaseDialogOk from '@/base/dialog/BaseDialogOk.vue'
import ReplaceTransitLeadTimeDialog
  from '@/components/transitleadtime/replacetransitleadtime/ReplaceTransitLeadTimeDialog.vue'
import moment from 'moment'

export default {
  name: 'UpdateTransitLeadTime',
  components: {
    BaseDialogOk,
    BaseInputSelectDatePickerReadOnly,
    AffectedLogisticLeadTimesViewDialog,
    CreateTransitLeadTimeTimeLine,
    BaseDialogSend,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    BaseInputSelectDatePicker,
    BaseInputNumberField,
    BaseCardTitle,
    BaseInputSelectWithFreightServiceReadOnly,
    BaseInputSelectWithFlagReadOnly,
    BaseInputSelectWithFreightService,
    ReplaceTransitLeadTimeDialog
  },
  data () {
    return {
      sendDialog: false,
      viewDialog: false,
      dialog: false,
      createLLtsDialog: false,
      createLLtsResultDialog: false,
      validationErrorMessages: [],
      transitWarehouses: [],
      transportLocations: [],
      freightServices: [],
      serviceProviders: [],
      carriers: [],
      affectedMaxLltValidFromDate: '',
      warningDialog: false,
      warningMessage: '',
      oldTransitLeadTime: {},
      transitLeadTime: {
        id: this.$route.params.id,
        transitWarehouseId: '',
        availablePointId: '',
        placeOfDeliveryId: '',
        inboundFreightServiceId: '',
        outboundFreightServiceId: '',
        arrivalToAvailable: '',
        availableToInTransit: '',
        inTransitToDeparture: '',
        departureToPlaceOfDelivery: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      },
      affectedLogisticsLeadTimeCount: 0,
      watchComplete: false,
      viewAffectedLogisticLeadTimes: [],
      viewAssociatedLogisticLeadTimes: [],
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.transitLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.',
        fromDateIsBeforeMaxLLtFromDate: value => {
          if (this.replaceTlt) {
            const msg = 'Valid from date must be greater than connected max Logistic lead time from date.'
            if (this.associatedMaxLltValidFromDate === value) {
              return msg
            }
            return validateDate(this.associatedMaxLltValidFromDate, value) || msg
          }
        }
      }
    }
  },
  props: {
    replaceTlt: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  beforeCreate () {
    axios.get('/api/touchpoint/logical-warehouse')
      .then(response => ([...response.data])
        .filter(value => value.warehouseType === 'TR')
        .forEach(data => this.transitWarehouses.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transportLocations')
      .then(response => ([...response.data]).forEach(data => {
        this.transportLocations.push(data)
      }))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transit-lead-time/' + this.$route.params.id)
      .then((response) => {
        this.transitLeadTime.transitWarehouseId = response.data.transitWarehouse.id
        this.transitLeadTime.availablePointId = response.data.availablePoint.id
        this.transitLeadTime.placeOfDeliveryId = response.data.placeOfDelivery.id
        this.transitLeadTime.inboundFreightServiceId = response.data.inboundFreightService.id
        this.transitLeadTime.outboundFreightServiceId = response.data.outboundFreightService.id
        this.transitLeadTime.arrivalToAvailable = response.data.arrivalToAvailable
        this.transitLeadTime.availableToInTransit = response.data.availableToInTransit
        this.transitLeadTime.inTransitToDeparture = response.data.inTransitToDeparture
        this.transitLeadTime.departureToPlaceOfDelivery = response.data.departureToPlaceOfDelivery
        this.transitLeadTime.validFrom = response.data.validFrom
        this.transitLeadTime.validTo = response.data.validTo
        this.transitLeadTime.main = response.data.main
        if (this.replaceTlt) {
          Object.assign(this.oldTransitLeadTime, this.transitLeadTime)
        }
      }).catch(e => console.log(e))

    axios.get('/api/touchpoint/logistic-lead-time/transit-lead-time/' + this.$route.params.id)
      .then((response) => {
        this.viewAssociatedLogisticLeadTimes = response.data
        this.viewAffectedLogisticLeadTimes = response.data.filter(sllt => sllt.validTo == null)
        const allValidFromDates = [...response.data.map(sllt => sllt.validFrom)]
        this.associatedMaxLltValidFromDate = max(allValidFromDates)
        if (this.replaceTlt) {
          this.transitLeadTime.validFrom = moment(this.associatedMaxLltValidFromDate).add(1, 'days').format('YYYY-MM-DD')
        }
      })
      .catch(e => console.log(e))
  },
  methods: {
    validate () {
      return this.$refs['transit-lead-time-update-form'].validate()
    },
    save () {
      if (this.validate()) {
        axios.get('/api/touchpoint/logistic-lead-time/transit-lead-time/' + this.transitLeadTime.id)
          .then(response => {
            if (response.data.length === 0) {
              this.updateTransitLeadTime()
            } else {
              if (this.transitLeadTime.validTo) {
                const transitEndDate = new Date(this.transitLeadTime.validTo)
                const lltExistWithFutureStartDate = response.data.some(sllt => new Date(sllt.validFrom) > transitEndDate)
                if (lltExistWithFutureStartDate) {
                  const allValidFromDates = [...response.data.map(sllt => new Date(sllt.validFrom))]
                  const maxValidConnectedSLLT = max(allValidFromDates).toISOString().split('T')[0]
                  this.warningMessage = `Transit lead time cannot end on '${this.transitLeadTime.validTo}' as there are connected SLLT valid from '${maxValidConnectedSLLT}'`
                  this.warningDialog = true
                  return
                }
              }
              this.viewAffectedLogisticLeadTimes = response.data
              this.viewDialog = true
            }
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    update () {
      if (this.validate()) {
        if (this.viewAffectedLogisticLeadTimes.length === 0) {
          this.updateTransitLeadTime()
        } else {
          if (this.transitLeadTime.validTo) {
            this.validateActiveSllt(this.transitLeadTime.validTo)
          }
          if (this.replaceTlt && this.transitLeadTime.validFrom) {
            this.validateActiveSllt(moment(this.transitLeadTime.validFrom).subtract(1, 'days').format('YYYY-MM-DD'))
          }
          this.viewAffectedLLtsDialog(this.warningDialog)
        }
      } else {
        window.scrollTo(0, 0)
      }
    },
    updateTransitLeadTime () {
      this.viewDialog = false
      if (!this.replaceTlt) {
        axios.put('/api/touchpoint/transit-lead-time', this.transitLeadTime)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        this.createLLtsDialog = true
        this.dialog = true
      }
    },
    replaceTltAndSllt () {
      this.dialog = false
      this.createLLtsDialog = false
      axios.put('/api/touchpoint/transit-lead-time/replace-and-create-logistic-lead-time', {
        createTransitLeadTimeCommand: this.transitLeadTime,
        updateTransitLeadTimeCommand: this.transitLeadTime
      })
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.dialog = true
            this.createLLtsResultDialog = true
          } else {
            this.validationErrorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },
    cancel () {
      this.$router.push({ name: 'SearchTransitLeadTime' })
    },
    createNew () {
      this.$router.push({ name: 'CreateTransitLeadTime' })
    },
    search () {
      this.$router.push({ name: 'SearchTransitLeadTime' })
    },
    saveAndCreateLLts () {
      this.validationErrorMessages = []
      this.createLLtsDialog = true
      this.dialog = true
    },
    closeSaveAndCreateLLtsDialog () {
      this.createLLtsDialog = false
      this.dialog = false
    },
    viewAffectedLLtsDialog () {
      if (!this.warningDialog) {
        this.viewDialog = true
      }
    },
    validateActiveSllt: function (date) {
      const transitEndDate = new Date(date)
      const lltExistWithFutureStartDate = this.viewAssociatedLogisticLeadTimes.some(sllt => new Date(sllt.validFrom) > transitEndDate)
      if (lltExistWithFutureStartDate) {
        this.warningMessage = `Transit lead time cannot end on '${date}' as there are connected SLLT valid from '${this.associatedMaxLltValidFromDate}'`
        this.warningDialog = true
        this.viewDialog = false
      }
    },
    watch: {
      'transitLeadTime.validFrom': function () {
        if (this.replaceTlt) {
          if (!this.watchComplete) {
            this.transitLeadTime.validFrom = moment(this.transitLeadTime.validFrom).add(1, 'days').format('YYYY-MM-DD')
            this.watchComplete = true
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
