const initState = () => {
  return {
    transitLeadTimeSearchCriteria: {
      transitWarehouseId: '',
      availablePointId: '',
      placeOfDeliveryId: '',
      inboundFreightServiceId: '',
      outboundFreightServiceId: '',
      active: true
    },
    transitLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchTransitLeadTime = {
  state: initState(),
  mutations: {
    updateTransitLeadTimeSearchResults (state, searchResults) {
      state.transitLeadTimeSearchResults = searchResults
    },
    resetTransitLeadTimeSearchCriteria (state) {
      Object.assign(state.transitLeadTimeSearchCriteria, initState().transitLeadTimeSearchCriteria)
    }
  },
  getters: {
    transitLeadTimeSearchCriteria: state => state.transitLeadTimeSearchCriteria,
    transitLeadTimeSearchResults: state => state.transitLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchTransitLeadTime
