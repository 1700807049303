import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import moment from 'moment'

Vue.config.productionTip = false

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

Vue.filter('formatDate', function (date) {
  return date ? moment(date).format('YYYY-MM-DD') : ''
})

Vue.filter('formatDateTimeFromInstant', function (dateTime) {
  return dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') : ''
})

Vue.filter('formatBoolean', function (value) {
  return value ? 'Yes' : 'No'
})

new Vue({
  router,
  store,
  vuetify,

  render: h => h(App)
}).$mount('#app')
