<template>
  <div>
    <div v-if="hasRunningBaseLoad">
      <v-card class="mb-6" v-for="(baseLoadStatus, id) in baseLoadStatuses" v-bind:key="id">
        <base-card-title :label="id"/>

        <v-card-text>
          <v-progress-linear :value="progress(baseLoadStatus)" :color="progressColor(baseLoadStatus)" height="25">
            <strong>{{ Math.ceil(progress(baseLoadStatus)) }}%</strong>
          </v-progress-linear>
        </v-card-text>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Status</v-col>
          <v-col class="pt-1 pb-1 pr-0 text--black"> {{ baseLoadStatus.status }}</v-col>
        </v-row>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Started time</v-col>
          <v-col class="pt-1 pb-1 pr-0 text--black"> {{ id }}</v-col>
        </v-row>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Completed items</v-col>
          <v-col class="pt-1 pb-1 pr-0 text--black"> {{ baseLoadStatus.numberOfComplete }}</v-col>
        </v-row>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Total items</v-col>
          <v-col class="pt-1 pb-1 pr-0 text--black"> {{ baseLoadStatus.totalItems }}</v-col>
        </v-row>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Page number</v-col>
          <v-col class="pt-1 pb-1 pr-0 text--black"> {{ baseLoadStatus.currentPage }}</v-col>
        </v-row>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Total pages</v-col>
          <v-col class="pt-1 pb-1 pr-0 text--black"> {{ baseLoadStatus.totalPages }}</v-col>
        </v-row>

        <v-row class="mt-0 mr-0" dense>
          <v-col cols="4" class="ml-4 pt-1 pb-1">Exceptions</v-col>
          <v-col v-if="baseLoadStatus.exceptions.length > 0" class="pt-1 pb-4 pr-0 text--black">
            <a  @click.prevent="showExceptions(baseLoadStatus.exceptions)" href=""> {{ baseLoadStatus.exceptions.length }} </a>
          </v-col>
          <v-col v-else class="pt-1 pb-4 pr-0 text--black"> {{ baseLoadStatus.exceptions.length }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <base-input-primary-button v-if="!baseLoadStatus.finished" @click="showCancelDialog(id)" icon="mdi-cancel" label="Cancel"/>
          <base-input-secondary-button v-if="baseLoadStatus.finished" @click="complete(id)" icon="mdi-check-bold" label="Complete"/>
        </v-card-actions>
      </v-card>
    </div>
    <v-card v-else>
      <base-card-title label="running base loads"/>
      <v-card-text class="pa-12">
        <v-row class="justify-center">No running base loads</v-row>
      </v-card-text>
    </v-card>

    <base-dialog-confirm
      label="Are you sure you want to cancel the base load?"
      @confirm="cancel(confirmCancelId)"
      @cancel="() => this.confirmCancelDialog = false"
      :confirm-dialog="confirmCancelDialog"/>

    <base-load-exception-dialog
      :dialog="exceptionsDialog"
      :exceptions="exceptions"
      @close="() => this.exceptionsDialog = false"/>

  </div>

</template>

<script>

import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import { isEmpty } from 'lodash'
import axios from 'axios'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseLoadExceptionDialog from '@/components/baseload/BaseLoadExceptionDialog'

export default {
  name: 'BaseLoadStatus',
  components: {
    BaseLoadExceptionDialog,
    BaseDialogConfirm,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    BaseCardTitle
  },
  props: {
    baseLoadStatuses: {
      type: Object,
      require: true
    },
    completeUrl: {
      type: String,
      require: true
    },
    cancelUrl: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      confirmCancelId: '',
      confirmCancelDialog: false,
      exceptionsDialog: false,
      exceptions: []
    }
  },
  computed: {
    hasRunningBaseLoad () {
      return !isEmpty(this.baseLoadStatuses)
    }
  },
  mounted () {},
  methods: {
    progress (baseLoadStatus) {
      if (baseLoadStatus.totalItems === 0) {
        return 0
      }
      return baseLoadStatus.numberOfComplete / baseLoadStatus.totalItems * 100
    },
    progressColor (baseLoadStatus) {
      if (baseLoadStatus.exceptions.length > 0) {
        return 'red'
      }
      return baseLoadStatus.finished ? 'green' : 'blue'
    },
    complete (id) {
      axios.post(this.completeUrl + '/' + id)
        .then((response) => { this.$emit('baseLoadUpdate', response.data) })
        .catch(e => console.log(e))
    },
    cancel (id) {
      this.confirmCancelDialog = false
      axios.post(this.cancelUrl + '/' + id)
        .then((response) => { this.$emit('baseLoadUpdate', response.data) })
        .catch(e => console.log(e))
    },
    showCancelDialog (id) {
      this.confirmCancelId = id
      this.confirmCancelDialog = true
    },
    showExceptions (exception) {
      this.exceptions = exception
      this.exceptionsDialog = true
    }
  }
}
</script>

<style scoped>

</style>
