<template>
  <v-container v-if="isLoaded">
    <v-form ref="difference-report-form">
      <v-card class="mt-5">
        <base-card-title
          label="Shipping & Logistic lead time difference report"
          icon="mdi-compare"/>
        <v-card-text class="pl-8 pr-8">
          <v-row class="pt-3">
            <v-col md="4" class="pb-0 pt-0">
              <base-input-multi-select-with-flag
                :items="placesOfLoading"
                item-value="id"
                item-text="description"
                item-country="location.code"
                item-label="Shipping places of loading"
                :selected-values="searchCriteria.shippingPlaceOfLoadingIds"
                @change="searchCriteria.shippingPlaceOfLoadingIds = arguments[0]"/>
            </v-col>
            <v-col md="4" class="pb-0 pt-0">
              <base-input-multi-select-with-freight-service
                :grouped-freight-services="groupedFreightServices"
                :freight-services="freightServices"
                item-label="Shipping mode of transport"
                :selected-value="searchCriteria.freightServiceIds"
                @change="searchCriteria.freightServiceIds = arguments[0]"/>
            </v-col>
            <v-col md="4" class="pb-0 pt-0">
              <base-input-select-date-picker
                item-label="First compare date"
                :selected-value="searchCriteria.firstCompareDate"
                :rules="[rules.required]"
                @change="searchCriteria.firstCompareDate = arguments[0]"/>
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col md="4" class="pb-0 pt-0">
              <base-input-multi-select-with-flag
                :items="transportLocations"
                item-value="id"
                item-text="description"
                item-country="location.code"
                item-label="Shipping places of delivery"
                :selected-values="searchCriteria.placeOfDeliveryIds"
                @change="searchCriteria.placeOfDeliveryIds = arguments[0]"/>
            </v-col>
            <v-col md="4" class="pb-0 pt-0">
              <base-input-multi-select-with-flag
                :items="planningMarkets"
                item-value="id"
                item-text="description"
                item-label="Planning market"
                item-country="logisticCountry.code"
                :selected-values="searchCriteria.planningMarketIds"
                @change="searchCriteria.planningMarketIds = arguments[0]"/>
            </v-col>
            <v-col md="4" class="pb-0 pt-0">
              <base-input-select-date-picker
                item-label="Second compare date"
                :selected-value="searchCriteria.secondCompareDate"
                :rules="[rules.required, rules.fromDateIsBeforeToDate]"
                @change="searchCriteria.secondCompareDate = arguments[0]"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <div class="mt-2">
      <base-input-search-and-clear-buttons
        class="d-inline"
        @search="search"
        @reset="reset"/>
      <base-input-primary-button
        label="run report"
        icon="mdi-microsoft-excel"
        class="ml-2"
        @click="downloadTotalReport"/>
    </div>

    <div class="mt-2">
      <v-data-table
        :headers="headers"
        :items="leadTimeDifferences"
        :items-per-page="15"
        :footer-props="{
        }">
        <!-- slots -->
        <template v-slot:item.placeOfLoading="{ item }">
          <base-label-with-flag :item-country="item.placeOfLoading.location.code" :item-label="item.placeOfLoading.description"/>
        </template>
        <template v-slot:item.freightService="{ item }">
          <div class="mt-2">
            <base-label-with-freight-service :freight-service="item.shippingFreightService"/>
          </div>
        </template>
        <template v-slot:item.placeOfDelivery="{ item }">
          <base-label-with-flag :item-country="item.placeOfDelivery.location.code" :item-label="item.placeOfDelivery.description"/>
        </template>
        <template v-slot:item.transit="{ item }">
          {{ item.transit ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.haulage="{ item }">
          {{ item.haulage ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.differenceDays="{ item }">
          {{ difference(item.firstCompareDays, item.secondCompareDays) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
        </template>
      </v-data-table>
    </div>

    <search-lead-time-difference-view-details-dialog
      :view-dialog="viewDialog"
      :view-dialog-lead-time-difference="viewDialogLeadTimeDifference"
      :search-criteria="searchCriteria"
      @close="closeViewDialog"/>

  </v-container>
</template>

<script>
import axios from 'axios'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import { mapGetters } from 'vuex'
import { arraysUtils, mathUtils } from '@/mixins/utilsMixin'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import { validateDate } from '@/validation/customvalidation'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import SearchLeadTimeDifferenceViewDetailsDialog from '@/views/logisticsleadtime/SearchLeadTimeDifferenceViewDetailsDialog'

export default {
  name: 'LogisticsLeadTimeDifferenceReport',
  components: {
    SearchLeadTimeDifferenceViewDetailsDialog,
    BaseToolTip,
    BaseLabelWithFreightService,
    BaseLabelWithFlag,
    BaseInputSearchAndClearButtons,
    BaseInputPrimaryButton,
    BaseCardTitle,
    BaseInputMultiSelectWithFreightService,
    BaseInputMultiSelectWithFlag,
    BaseInputSelectDatePicker
  },
  mixins: [arraysUtils, mathUtils],
  data () {
    return {
      isLoaded: false,
      searchCriteria: {
        shippingPlaceOfLoadingIds: [],
        freightServiceIds: [],
        planningMarketIds: [],
        placeOfDeliveryIds: [],
        firstCompareDate: '',
        secondCompareDate: ''
      },
      viewDialog: false,
      viewDialogLeadTimeDifference: {},
      headers: [
        {
          text: 'Place of loading',
          align: 'start',
          sortable: false,
          value: 'placeOfLoading'
        },
        { text: 'Shipping MoT', value: 'freightService' },
        { text: 'Place of delivery', sortable: false, value: 'placeOfDelivery' },
        { text: 'Via transit?', value: 'transit' },
        { text: 'Has haulage?', value: 'haulage' },
        { text: 'Warehouse', value: 'warehouse.currentWarehouseCode' },
        { text: 'PM', value: 'planningMarket.abbreviation' },
        { text: 'First compare days', value: 'firstCompareDays' },
        { text: 'Second compare days', value: 'secondCompareDays' },
        { text: 'Difference days', value: 'differenceDays' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      leadTimeDifferences: [],
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.searchCriteria.firstCompareDate, value) || 'first compare date cannot be before second compare date.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'planningMarkets',
      'transportLocations',
      'placesOfLoading'
    ])
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllPlanningMarkets'),
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllPlacesOfLoading')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    downloadTotalReport () {
      if (this.$refs['difference-report-form'].validate()) {
        const fileName = 'LogisticsLeadTimeDifferenceReport.xlsx'
        axios.post('/api/touchpoint/logistic-lead-time/download-difference-report',
          this.removeEmptyArrays(this.searchCriteria), {
            headers: { 'Content-Disposition': 'attachment; filename=' + 'test.xlsx' },
            responseType: 'arraybuffer'
          }
        ).then((response) => {
          if (response.status === 204) { // no content
            this.okDialogMessage = 'No published logistic lead times found for selected criteria.'
            this.showOkDialog = true
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          }
        }).catch((error) => console.log(error))
      }
    },
    search () {
      if (this.$refs['difference-report-form'].validate()) {
        axios.post('/api/touchpoint/logistic-lead-time/search-difference-report', this.removeEmptyArrays(this.searchCriteria))
          .then((response) => {
            this.leadTimeDifferences = response.data
          })
          .catch(e => console.log(e))
      }
    },
    showViewDialog (item) {
      this.viewDialogLeadTimeDifference = item
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    reset () {
      this.searchCriteria = {
        shippingPlaceOfLoadingIds: [],
        freightServiceIds: [],
        planningMarketIds: [],
        placeOfDeliveryIds: [],
        firstCompareDate: '',
        secondCompareDate: ''
      }
      this.$refs['difference-report-form'].resetValidation()
    }
  }

}
</script>

<style scoped>
.container {
  max-width: 100%;
}

</style>
