<template>
  <UpdateTransitLeadTime :replace-tlt=true></UpdateTransitLeadTime>
</template>

<script>
import UpdateTransitLeadTime from '@/views/transitleadtime/UpdateTransitLeadTime'

export default {
  name: 'ReplaceTransitLeadTime',
  components: {
    UpdateTransitLeadTime
  }
}
</script>

<style scoped>

</style>
