<template>
  <v-form ref="shipping-lead-time-update-step1-form">
    <v-card
      elevation="0"
      class="mb-3">

      <div class="text-h6 mb-3">Shipping from</div>

      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
          <base-input-select-with-flag-read-only
            item-label="Country of delivery"
            :items="geographicalCountries"
            item-country="code"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="shippingLeadTime.countryOfDeliveryId"
            @change="shippingLeadTime.countryOfDeliveryId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-with-flag-read-only
            item-label="Place of loading"
            :items="placesOfLoading"
            item-country="location.code"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="shippingLeadTime.placeOfLoadingId"
            :country-first-in-select="shippingLeadTime.countryOfDeliveryId"
            @change="shippingLeadTime.placeOfLoadingId = arguments[0]"/>
        </v-col>
      </v-row>

      <div class="text-h6 mt-3 mb-3">Shipping to</div>
      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
          <base-input-select-with-flag-read-only
            item-label="Place of delivery"
            :items="placesOfDelivery"
            item-country="location.code"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="shippingLeadTime.placeOfDeliveryId"
            @change="shippingLeadTime.placeOfDeliveryId = arguments[0]"/>
        </v-col>
      </v-row>

      <div class="text-h6 mt-3 mb-3">Shipping details</div>
      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
          <base-input-select-with-freight-service-read-only
            :freight-services="freightServices"
            item-label="Mode of transport"
            :rules="[rules.required]"
            :selected-value="shippingLeadTime.freightServiceId"
            @change="shippingLeadTime.freightServiceId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-read-only v-if="!replaceSlt"
            item-label="Consolidator"
            :items="serviceProviders"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="shippingLeadTime.serviceProviderId"
            @change="shippingLeadTime.serviceProviderId = arguments[0]"/>

          <base-input-select v-if="replaceSlt"
            item-label="Consolidator"
            :items="serviceProviders"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="shippingLeadTime.serviceProviderId"
            @change="shippingLeadTime.serviceProviderId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select
            item-label="Carrier"
            :items="carriers"
            item-country="code"
            item-text="name"
            item-value="id"
            :rules="[rules.requiredIfSea]"
            :selected-value="shippingLeadTime.carrierId"
            hint="Carrier is optional, unless mode of transport is Sea"
            @change="shippingLeadTime.carrierId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-text-field
            item-label="Carrier service string"
            :value="shippingLeadTime.service"
            hint="Carrier service code is optional"
            @blur="shippingLeadTime.service = arguments[0]"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select
            :items="serviceCodes"
            item-value="name"
            item-text="description"
            item-label="Service code"
            :rules="[rules.requiredIfSea]"
            :selected-value="shippingLeadTime.serviceCode"
            hint="Service code is optional, unless mode of transport is Sea"
            @change="shippingLeadTime.serviceCode = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-2 pt-0 ml-2">
          <v-checkbox
            class="mt-0"
            v-model="shippingLeadTime.main"
            label="Main shipping lead time"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <v-checkbox
            class="mt-0"
            v-model="shippingLeadTime.defaultForCountryOfDelivery"
            label="Default for country of delivery"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-date-picker-read-only v-if="!replaceSlt"
            item-label="Valid from"
            :value="shippingLeadTime.validFrom"
            @change="shippingLeadTime.validFrom = arguments[0]"/>

          <base-input-select-date-picker v-if="replaceSlt"
            item-label="Valid from"
            :selected-value="shippingLeadTime.validFrom"
            :rules="[rules.required , rules.fromDateIsBeforeObsoleteSltFromDate]"
            @change="shippingLeadTime.validFrom = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-date-picker
            item-label="Valid to"
            :selected-value="shippingLeadTime.validTo"
            :rules="[rules.fromDateIsBeforeToDate]"
            @change="shippingLeadTime.validTo = arguments[0]"/>
        </v-col>
      </v-row>

    </v-card>
  </v-form>
</template>

<script>
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import BaseInputTextField from '@/base/input/BaseInputTextField'
import BaseInputSelectReadOnly from '@/base/input/BaseInputSelectReadOnly'
import BaseInputSelectWithFreightServiceReadOnly from '@/base/input/BaseInputSelectWithFreightServiceReadOnly'
import BaseInputSelectWithFlagReadOnly from '@/base/input/BaseInputSelectWithFlagReadOnly'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import { validateDate } from '@/validation/customvalidation'
import moment from 'moment'

export default {
  name: 'UpdateShippingLeadTimeStep1',
  components: {
    BaseInputSelectDatePickerReadOnly,
    BaseInputSelectWithFlagReadOnly,
    BaseInputSelectWithFreightServiceReadOnly,
    BaseInputSelectReadOnly,
    BaseInputTextField,
    BaseInputSelect,
    BaseInputSelectDatePicker
  },
  data () {
    return {
      watchComplete: false,
      rules: {
        required: value => !!value || 'Required.',
        requiredIfSea: value => this.validateRequiredIfSea(value) || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.shippingLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.',
        fromDateIsBeforeObsoleteSltFromDate: value => {
          if (this.replaceSlt) {
            const msg = 'Valid from date must be greater than obsolete shipping lead time from date.'
            if (this.oldShippingLeadTime.validFrom === value) {
              return msg
            }
            return validateDate(this.oldShippingLeadTime.validFrom, value) || msg
          }
        }
      }
    }
  },
  props: {
    shippingLeadTime: {
      type: Object,
      require: true
    },
    geographicalCountries: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    placesOfLoading: {
      type: Array,
      require: true
    },
    placesOfDelivery: {
      type: Array,
      require: true
    },
    serviceProviders: {
      type: Array,
      require: true
    },
    carriers: {
      type: Array,
      require: true
    },
    serviceCodes: {
      type: Array,
      require: true
    },
    oldShippingLeadTime: {
      type: Object,
      require: false,
      default: () => {}
    },
    replaceSlt: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  methods: {
    validate () {
      return this.$refs['shipping-lead-time-update-step1-form'].validate()
    },
    validateRequiredIfSea (value) {
      const freightService = this.freightServices.find(item => item.id === this.shippingLeadTime.freightServiceId)
      if (freightService) {
        const transportMode = freightService.transportModes[freightService.transportModes.length - 1].name
        if (transportMode === 'SEA') {
          return !!value
        }
      }
      return true
    }
  },
  watch: {
    'shippingLeadTime.validFrom': function () {
      if (this.replaceSlt) {
        if (!this.watchComplete) {
          this.shippingLeadTime.validFrom = moment(this.shippingLeadTime.validFrom).add(1, 'days').format('YYYY-MM-DD')
          this.watchComplete = true
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
