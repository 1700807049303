import axios from 'axios'

export const arraysUtils = {
  methods: {
    removeEmptyArrays (object) {
      // remove empty arrays [] of search criteria,
      // or else backend repo will search for "in (null)"
      const emptyArrayObject = { ...object }
      for (const name in object) {
        if (Array.isArray(object[name]) && object[name].length === 0) {
          delete emptyArrayObject[name]
        }
      }
      return emptyArrayObject
    }
  }
}

export const mathUtils = {
  methods: {
    difference (value1, value2) {
      if (!isNaN(value1) && !isNaN(value2)) {
        return value2 - value1
      } else {
        return null // cannot differentiate
      }
    }
  }
}

export const security = {
  methods: {
    hasAccess (allowedRoles, currentUserRoles) {
      if (!currentUserRoles) {
        return true
      }
      return allowedRoles.some(userRole => currentUserRoles.indexOf(userRole) >= 0)
    },
    isAdmin (currentUserRoles) {
      return this.hasAccess(['ROLE_TECH_ADMIN', 'ROLE_ADMIN'], currentUserRoles)
    },
    isTechAdmin (currentUserRoles) {
      return this.hasAccess(['ROLE_TECH_ADMIN'], currentUserRoles) && this.hasAccess(['ROLE_ACCESS_ELEVATOR'], currentUserRoles)
    },
    canElevateAccess (currentUserRoles) {
      return this.hasAccess(['ROLE_ACCESS_ELEVATOR'], currentUserRoles)
    }
  }
}

export const download = {
  methods: {
    downloadToExcel (url, postData, fileName) {
      axios.post(url, arraysUtils.methods.removeEmptyArrays(postData), {
        headers: { 'Content-Disposition': 'attachment; filename=' + fileName },
        responseType: 'arraybuffer'
      }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
