<template>
  <v-form
    v-if="replaceShippingLeadTimes.length > 0"
    ref="shipping-lead-time-replace-step2-form" >
    <v-card
      elevation="0"
      class="mb-6">
      <v-row>
        <v-col cols="2">
          Place of loading
        </v-col>
        <v-col cols="4">
          <base-label-with-flag
            v-if="placeOfLoading"
            :item-label="placeOfLoading.description"
            :item-country="placeOfLoading.location.code"/>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          Set start date for all
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          Freight service
        </v-col>
        <v-col cols="4">
          <base-label-with-freight-service
            v-if="freightService"
            :item-label="freightService.description"
            :freight-service="freightService"/>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" class="pt-0">
          <base-input-select-date-picker-thin
            :selected-value="startDateForAll"
            @change="updateStartDateForAll"/>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-col class="d-flex flex-row-reverse">
          <v-switch
            label="Exclude all"
            dense
            color="red darken-2"
            v-model="excludeAll"
            @change="excludeAllSllts"
            />

      </v-col>
      <v-row>

      </v-row>
    </v-card>
    <v-divider class="mb-6"></v-divider>

    <v-data-table
      :headers="tableHeaders"
      :items="replaceShippingLeadTimes"
      :items-per-page="-1"
      :hide-default-footer="true"
      :item-class="item => item.shippingLeadTimeCreateCommand.exclude ? 'grey' : ''"
      class="elevation-0">
      <template v-slot:item.countryOfDelivery="{ item }">
        <base-tool-tip2>
            <base-label-with-flag
              :class="isEnding(item)"
              :item-country="item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code"
              :item-label="item.shippingLeadTimeCreateCommand.placeOfDelivery.unlocode"/>
          <template v-slot:tooltip>
            <base-label-with-flag
              :class="isEnding(item)"
              :item-country="item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code"
              :item-label="item.shippingLeadTimeCreateCommand.placeOfDelivery.description"/>
          </template>
        </base-tool-tip2>
      </template>

      <template v-slot:item.default="{ item }">
        <v-icon :class="isEnding(item)" v-if="item.shippingLeadTimeCreateCommand.defaultForCountryOfDelivery" color="green darken-2">mdi-check</v-icon>
      </template>

      <template v-slot:item.main="{ item }">
        <v-icon :class="isEnding(item)" v-if="item.shippingLeadTimeCreateCommand.main" color="green darken-2">mdi-check</v-icon>
      </template>

      <template v-slot:item.serviceProvider="{ item }">
        <label v-if="item.shippingLeadTimeCreateCommand.exclude">{{item.shippingLeadTimeCreateCommand.serviceProvider.code}}</label>

        <base-input-select-thin-service-providers
          v-if="!item.shippingLeadTimeCreateCommand.exclude"
          :class="isEnding(item)"
          :service-providers="serviceProviders"
          :clearable="false"
          :rules="[rules.required({
            id: item.shippingLeadTimeCreateCommand.serviceProvider && item.shippingLeadTimeCreateCommand.serviceProvider.id,
            createCommand: item.shippingLeadTimeCreateCommand
          })]"
          :selected-value="item.shippingLeadTimeCreateCommand.serviceProvider && item.shippingLeadTimeCreateCommand.serviceProvider.id"
          @change="updateServiceProvider(item.shippingLeadTimeCreateCommand, $event)"/>
      </template>

      <template v-slot:item.carrier="{ item }">
        <label v-if="item.shippingLeadTimeCreateCommand.exclude && item.shippingLeadTimeCreateCommand.carrier">
          {{item.shippingLeadTimeCreateCommand.carrier.code}}
        </label>

        <base-input-select-thin
          v-if="!item.shippingLeadTimeCreateCommand.exclude"
          :class="isEnding(item)"
          :items="carriers"
          item-text="description"
          item-value="id"
          :rules="[rules.requiredIfSea({
            id: item.shippingLeadTimeCreateCommand.carrier && item.shippingLeadTimeCreateCommand.carrier.id,
            createCommand: item.shippingLeadTimeCreateCommand
          })]"
          :selected-value="item.shippingLeadTimeCreateCommand.carrier && item.shippingLeadTimeCreateCommand.carrier.id"
          @change="updateCarrier(item.shippingLeadTimeCreateCommand, $event)"/>
      </template>

      <template v-slot:item.serviceCode="{ item }">
        <label v-if="item.shippingLeadTimeCreateCommand.exclude && item.shippingLeadTimeCreateCommand.serviceCode">
          {{item.shippingLeadTimeCreateCommand.serviceCode.name}}
        </label>

        <base-input-select-thin
          v-if="!item.shippingLeadTimeCreateCommand.exclude"
          :class="isEnding(item)"
          :items="serviceCodes"
          item-text="description"
          item-value="name"
          :rules="[rules.requiredIfSea({
            id: item.shippingLeadTimeCreateCommand.serviceCode && item.shippingLeadTimeCreateCommand.serviceCode.name,
            createCommand: item.shippingLeadTimeCreateCommand
          })]"
          :selected-value="item.shippingLeadTimeCreateCommand.serviceCode && item.shippingLeadTimeCreateCommand.serviceCode.name"
          @change="updateServiceCode(item.shippingLeadTimeCreateCommand, $event)"/>
      </template>

      <template v-slot:item.service="{ item }">
        <label v-if="item.shippingLeadTimeCreateCommand.exclude">{{item.shippingLeadTimeCreateCommand.service}}</label>

        <base-input-text-field-thin
          v-if="!item.shippingLeadTimeCreateCommand.exclude"
          :class="isEnding(item)"
          class="mt-1 mb-1"
          :value="item.shippingLeadTimeCreateCommand.service"
          @blur="item.shippingLeadTimeCreateCommand.service = arguments[0]"/>
      </template>
      <template v-slot:item.events="{ item }">
        <base-tool-tip
            label="Edit events"
            icon="mdi-pencil"
            @click="displayEventsDialog(item.shippingLeadTimeCreateCommand)">
        </base-tool-tip>
      </template>

      <template v-slot:item.startDate="{ item }">
        <base-input-select-date-picker-thin
          class="mt-1 mb-1"
          v-if="!item.shippingLeadTimeCreateCommand.exclude"
          :selected-value="item.shippingLeadTimeCreateCommand.validFrom"
          :validate-on-blur="true"
          :hint="item.shippingLeadTimeCreateCommand.end ? 'End date' : ''"
          :rules="[rules.requiredUnlessExclude({
            id: item.shippingLeadTimeCreateCommand.validFrom,
            createCommand: item.shippingLeadTimeCreateCommand
          }),
          rules.afterPreviousValidFrom(item)]"
          @change="item.shippingLeadTimeCreateCommand.validFrom = arguments[0]"/>
      </template>

      <template v-slot:item.exclude="{ item }">
        <base-tool-tip2>
          <v-switch
            dense
            color="red darken-2"
            v-model="item.shippingLeadTimeCreateCommand.exclude"/>
          <template slot="tooltip">
            No new shipping lead time will be created
            and the existing will be kept as it is.
          </template>
        </base-tool-tip2>
      </template>

      <template v-slot:item.end="{ item }">
        <base-tool-tip2>
        <v-switch v-if="!item.shippingLeadTimeCreateCommand.exclude"
          dense
          color="red darken-2"
          v-model="item.shippingLeadTimeCreateCommand.end"/>
          <template slot="tooltip">
            No new shipping lead time will be created
            but the existing will be closed with the selected date.
          </template>
        </base-tool-tip2>
      </template>
    </v-data-table>

    <replace-shipping-lead-time-events-dialog :key="shippingLeadTimeInContext && shippingLeadTimeInContext.id"
      :shipping-lead-time-events="shippingLeadTimeInContext.shippingEvents"
      :freight-services="freightServices"
      :selected-freight-service-id="freightService.id"
      :show-dialog="showEventsDialog"
      @updateEvents="updateEvents"
      @close="hideEventsDialog"/>
  </v-form>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import { mapGetters } from 'vuex'
import BaseInputSelectThin from '@/base/input/BaseInputSelectThin'
import BaseInputSelectDatePickerThin from '@/base/input/BaseInputSelectDatePickerThin'
import BaseInputTextFieldThin from '@/base/input/BaseInputTextFieldThin'
import BaseToolTip2 from '@/base/tooltip/BaseToolTip2'
import { validateDateIsBefore } from '@/validation/customvalidation'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import ReplaceShippingLeadTimeEventsDialog from '@/components/shippingleadtime/replaceshippingleadtime/ReplaceShippingLeadTimeEventsDialog'
import BaseInputSelectThinServiceProviders from '@/base/input/BaseInputSelectThinServiceProviders'

export default {
  name: 'ReplaceShippingLeadTimeStep2',
  components: {
    BaseInputSelectThinServiceProviders,
    ReplaceShippingLeadTimeEventsDialog,
    BaseToolTip,
    BaseToolTip2,
    BaseInputTextFieldThin,
    BaseInputSelectDatePickerThin,
    BaseInputSelectThin,
    BaseLabelWithFreightService,
    BaseLabelWithFlag
  },
  props: {
    shippingLeadTimes: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      replaceShippingLeadTimes: [],
      shippingLeadTimeInContext: {},
      showEventsDialog: false,
      startDateForAll: undefined,
      excludeAll: false,
      tableHeaders: [
        { text: 'Place of delivery', align: 'start', sortable: false, value: 'countryOfDelivery' },
        { text: 'Default for del. country.', align: 'start', sortable: false, value: 'default' },
        { text: 'Main', align: 'start', sortable: false, value: 'main' },
        { text: 'Service provider', sortable: false, value: 'serviceProvider' },
        { text: 'Carrier', sortable: false, value: 'carrier' },
        { text: 'Service code', sortable: false, value: 'serviceCode' },
        { text: 'Carrier service string', sortable: false, value: 'service' },
        { text: 'Events', sortable: false, value: 'events' },
        { text: 'Start date', sortable: false, value: 'startDate' },
        { text: 'Exclude', sortable: false, value: 'exclude' },
        { text: 'End', sortable: false, value: 'end' }
      ],
      rules: {
        required: validation => (this.validateExcludeOrEnd(validation.createCommand) || !!validation.id) || 'Required.',
        requiredUnlessExclude: validation => (this.validateExclude(validation.createCommand) || !!validation.id) || 'Required.',
        requiredIfSea: validation => (this.validateExcludeOrEnd(validation.createCommand) || this.validateRequiredIfSea(validation.id)) || 'Required for freight service sea.',
        afterPreviousValidFrom: replace => (this.validateExclude(replace.shippingLeadTimeCreateCommand) || validateDateIsBefore(replace.shippingLeadTimeUpdateCommand.validFrom, replace.shippingLeadTimeCreateCommand.validFrom)) ||
          'Start date has to be after valid from date ' + replace.shippingLeadTimeUpdateCommand.validFrom
      }
    }
  },
  computed: {
    ...mapGetters([
      'serviceProviders',
      'carriers',
      'serviceCodes',
      'freightServices'
    ]),
    placeOfLoading () {
      return this.shippingLeadTimes[0].placeOfLoading
    },
    freightService () {
      return this.shippingLeadTimes[0].freightService
    }
  },
  watch: {
    shippingLeadTimes (shippingLeadTimes) {
      this.excludeAll = false
      this.replaceShippingLeadTimes = []
      shippingLeadTimes.forEach((leadTime) => {
        this.replaceShippingLeadTimes.push({
          shippingLeadTimeUpdateCommand: leadTime,
          shippingLeadTimeCreateCommand: Object.assign({}, leadTime)
        })
      })
      this.replaceShippingLeadTimes.forEach((leadtime) => {
        leadtime.shippingLeadTimeCreateCommand.validFrom = ''
      })
      this.$emit('updateReplaceShippingLeadTimes', this.replaceShippingLeadTimes)
    }
  },
  mounted () {
    this.$store.dispatch('getAllFreightServices')
  },
  methods: {
    updateStartDateForAll (date) {
      this.startDateForAll = date
      this.replaceShippingLeadTimes.forEach(replace => { replace.shippingLeadTimeCreateCommand.validFrom = date })
    },
    updateServiceProvider (createCommand, id) {
      createCommand.serviceProvider = this.serviceProviders.find(item => item.id === id)
    },
    updateCarrier (createCommand, id) {
      createCommand.carrier = this.carriers.find(item => item.id === id)
    },
    updateServiceCode (createCommand, name) {
      createCommand.serviceCode = this.serviceCodes.find(item => item.name === name)
    },
    updateTotalDays (createCommand) {
      const firstEvent = createCommand.shippingEvents[0]
      const lastEvent = createCommand.shippingEvents[createCommand.shippingEvents.length - 1]
      createCommand.totalDays = ((lastEvent.weekNumber * 7) + lastEvent.dayOfWeek) - ((firstEvent.weekNumber * 7) + firstEvent.dayOfWeek)
    },
    isEnding (item) {
      return item.shippingLeadTimeCreateCommand.end ? 'text-decoration-line-through' : ''
    },
    validateFirstEventIsClosing (events) {
      if (events[0].weekNumber > events[1].weekNumber) return false
      return !(events[0].weekNumber === events[1].weekNumber && events[0].dayOfWeek >= events[1].dayOfWeek)
    },
    validateLastEventIsFinal (events) {
      const lastEvent = events.length - 1
      const secondsToLastEvent = events.length - 2

      if (events[secondsToLastEvent].weekNumber > events[lastEvent].weekNumber) return false
      return !(events[secondsToLastEvent].weekNumber === events[lastEvent].weekNumber &&
        events[secondsToLastEvent].dayOfWeek >= events[lastEvent].dayOfWeek)
    },
    validateRequiredIfSea (value) {
      const transportMode = this.freightService.transportModes[this.freightService.transportModes.length - 1].name
      if (transportMode === 'SEA') {
        return !!value
      }
      return true
    },
    validateExcludeOrEnd (createCommand) {
      return createCommand.exclude || createCommand.end
    },
    validateExclude (createCommand) {
      return createCommand.exclude
    },
    validate () {
      return this.$refs['shipping-lead-time-replace-step2-form'].validate()
    },
    clearValidation () {
      this.$refs['shipping-lead-time-replace-step2-form'].resetValidation()
    },
    clear () {
      this.startDateForAll = undefined
    },
    displayEventsDialog (shippingLeadTime) {
      this.shippingLeadTimeInContext = shippingLeadTime
      this.showEventsDialog = true
    },
    hideEventsDialog () {
      this.showEventsDialog = false
    },
    updateEvents (events) {
      const shippingLeadTimeToBeUpdated = this.replaceShippingLeadTimes
        .find(replaceShippingLeadTime => replaceShippingLeadTime.shippingLeadTimeCreateCommand.id === this.shippingLeadTimeInContext.id)
      shippingLeadTimeToBeUpdated.shippingLeadTimeCreateCommand.shippingEvents = [...events]
      this.updateTotalDays(shippingLeadTimeToBeUpdated.shippingLeadTimeCreateCommand)
      this.hideEventsDialog()
    },
    excludeAllSllts () {
      this.replaceShippingLeadTimes.forEach((leadtime) => {
        leadtime.shippingLeadTimeCreateCommand.exclude = this.excludeAll
        leadtime.shippingLeadTimeCreateCommand = Object.assign({}, leadtime.shippingLeadTimeCreateCommand)
      })
    }
  }
}
</script>

<style scoped>

</style>
