import axios from 'axios'

const initState = () => {
  return {
    incoTerms: [],
    packingModes: [],
    modeOfTransports: [],
    modeOfTransportCategories: []
  }
}

const logisticsMasterData = {
  state: initState(),
  mutations: {
    populateIncoTerms (state, incoTerms) {
      state.incoTerms = incoTerms
    },
    reloadIncoTerms (state) {
      state.incoTerms = []
      this.dispatch('getAllIncoTerms')
    },
    populatePackingModes (state, packingModes) {
      state.packingModes = packingModes
    },
    reloadPackingModes (state) {
      state.packingModes = []
      this.dispatch('getAllPackingModes')
    },
    populateModeOfTransports (state, modeOfTransports) {
      state.modeOfTransports = modeOfTransports
    },
    reloadModeOfTransports (state) {
      state.modeOfTransports = []
      this.dispatch('getAllModeOfTransports')
    },
    populateModeOfTransportCategories (state, modeOfTransportCategories) {
      state.modeOfTransportCategories = modeOfTransportCategories
    },
    reloadModeOfTransportCategories (state) {
      state.modeOfTransportCategories = []
      this.dispatch('getAllModeOfTransportCategories')
    }
  },
  getters: {
    incoTerms: state => state.incoTerms,
    activeIncoTerms: state => state.incoTerms.filter(item => item.active),
    packingModes: state => state.packingModes,
    modeOfTransports: state => state.modeOfTransports,
    activeModeOfTransports: state => state.modeOfTransports.filter(item => item.active),
    modeOfTransportCategories: state => state.modeOfTransportCategories,
    activeModeOfTransportCategories: state => state.modeOfTransportCategories.filter(item => item.active)
  },
  actions: {
    getAllIncoTerms ({ commit, state }) {
      if (state.incoTerms.length === 0) {
        const incoTerms = []
        axios.get('/api/touchpoint/logistics-master-data/inco-terms')
          .then(response => {
            response.data.forEach(data => incoTerms.push(data))
          })
          .then(response => commit('populateIncoTerms', incoTerms))
          .catch(e => console.log(e))
      }
    },

    getAllPackingModes ({ commit, state }) {
      if (state.packingModes.length === 0) {
        const packingModes = []
        axios.get('/api/touchpoint/logistics-master-data/packing-modes')
          .then(response => {
            response.data.forEach(data => packingModes.push(data))
          })
          .then(response => commit('populatePackingModes', packingModes))
          .catch(e => console.log(e))
      }
    },

    getAllModeOfTransports ({ commit, state }) {
      if (state.modeOfTransports.length === 0) {
        const modeOfTransports = []
        axios.get('/api/touchpoint/logistics-master-data/mode-of-transports')
          .then(response => {
            response.data.forEach(data => modeOfTransports.push(data))
          })
          .then(response => commit('populateModeOfTransports', modeOfTransports))
          .catch(e => console.log(e))
      }
    },

    getAllModeOfTransportCategories ({ commit, state }) {
      if (state.modeOfTransportCategories.length === 0) {
        const modeOfTransportCategories = []
        axios.get('/api/touchpoint/logistics-master-data/mode-of-transport-categories')
          .then(response => {
            response.data.forEach(data => modeOfTransportCategories.push(data))
          })
          .then(response => commit('populateModeOfTransportCategories', modeOfTransportCategories))
          .catch(e => console.log(e))
      }
    }
  }
}

export default logisticsMasterData
