var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.replaceShippingLeadTimes.length > 0)?_c('v-form',{ref:"shipping-lead-time-replace-step2-form"},[_c('v-card',{staticClass:"mb-6",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Place of loading ")]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.placeOfLoading)?_c('base-label-with-flag',{attrs:{"item-label":_vm.placeOfLoading.description,"item-country":_vm.placeOfLoading.location.code}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Set start date for all ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Freight service ")]),_c('v-col',{attrs:{"cols":"4"}},[(_vm.freightService)?_c('base-label-with-freight-service',{attrs:{"item-label":_vm.freightService.description,"freight-service":_vm.freightService}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"3"}},[_c('base-input-select-date-picker-thin',{attrs:{"selected-value":_vm.startDateForAll},on:{"change":_vm.updateStartDateForAll}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex flex-row-reverse"},[_c('v-switch',{attrs:{"label":"Exclude all","dense":"","color":"red darken-2"},on:{"change":_vm.excludeAllSllts},model:{value:(_vm.excludeAll),callback:function ($$v) {_vm.excludeAll=$$v},expression:"excludeAll"}})],1),_c('v-row')],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.tableHeaders,"items":_vm.replaceShippingLeadTimes,"items-per-page":-1,"hide-default-footer":true,"item-class":function (item) { return item.shippingLeadTimeCreateCommand.exclude ? 'grey' : ''; }},scopedSlots:_vm._u([{key:"item.countryOfDelivery",fn:function(ref){
var item = ref.item;
return [_c('base-tool-tip2',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('base-label-with-flag',{class:_vm.isEnding(item),attrs:{"item-country":item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code,"item-label":item.shippingLeadTimeCreateCommand.placeOfDelivery.description}})]},proxy:true}],null,true)},[_c('base-label-with-flag',{class:_vm.isEnding(item),attrs:{"item-country":item.shippingLeadTimeCreateCommand.placeOfDelivery.location.code,"item-label":item.shippingLeadTimeCreateCommand.placeOfDelivery.unlocode}})],1)]}},{key:"item.default",fn:function(ref){
var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.defaultForCountryOfDelivery)?_c('v-icon',{class:_vm.isEnding(item),attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.main",fn:function(ref){
var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.main)?_c('v-icon',{class:_vm.isEnding(item),attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.serviceProvider",fn:function(ref){
var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.exclude)?_c('label',[_vm._v(_vm._s(item.shippingLeadTimeCreateCommand.serviceProvider.code))]):_vm._e(),(!item.shippingLeadTimeCreateCommand.exclude)?_c('base-input-select-thin-service-providers',{class:_vm.isEnding(item),attrs:{"service-providers":_vm.serviceProviders,"clearable":false,"rules":[_vm.rules.required({
          id: item.shippingLeadTimeCreateCommand.serviceProvider && item.shippingLeadTimeCreateCommand.serviceProvider.id,
          createCommand: item.shippingLeadTimeCreateCommand
        })],"selected-value":item.shippingLeadTimeCreateCommand.serviceProvider && item.shippingLeadTimeCreateCommand.serviceProvider.id},on:{"change":function($event){return _vm.updateServiceProvider(item.shippingLeadTimeCreateCommand, $event)}}}):_vm._e()]}},{key:"item.carrier",fn:function(ref){
        var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.exclude && item.shippingLeadTimeCreateCommand.carrier)?_c('label',[_vm._v(" "+_vm._s(item.shippingLeadTimeCreateCommand.carrier.code)+" ")]):_vm._e(),(!item.shippingLeadTimeCreateCommand.exclude)?_c('base-input-select-thin',{class:_vm.isEnding(item),attrs:{"items":_vm.carriers,"item-text":"description","item-value":"id","rules":[_vm.rules.requiredIfSea({
          id: item.shippingLeadTimeCreateCommand.carrier && item.shippingLeadTimeCreateCommand.carrier.id,
          createCommand: item.shippingLeadTimeCreateCommand
        })],"selected-value":item.shippingLeadTimeCreateCommand.carrier && item.shippingLeadTimeCreateCommand.carrier.id},on:{"change":function($event){return _vm.updateCarrier(item.shippingLeadTimeCreateCommand, $event)}}}):_vm._e()]}},{key:"item.serviceCode",fn:function(ref){
        var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.exclude && item.shippingLeadTimeCreateCommand.serviceCode)?_c('label',[_vm._v(" "+_vm._s(item.shippingLeadTimeCreateCommand.serviceCode.name)+" ")]):_vm._e(),(!item.shippingLeadTimeCreateCommand.exclude)?_c('base-input-select-thin',{class:_vm.isEnding(item),attrs:{"items":_vm.serviceCodes,"item-text":"description","item-value":"name","rules":[_vm.rules.requiredIfSea({
          id: item.shippingLeadTimeCreateCommand.serviceCode && item.shippingLeadTimeCreateCommand.serviceCode.name,
          createCommand: item.shippingLeadTimeCreateCommand
        })],"selected-value":item.shippingLeadTimeCreateCommand.serviceCode && item.shippingLeadTimeCreateCommand.serviceCode.name},on:{"change":function($event){return _vm.updateServiceCode(item.shippingLeadTimeCreateCommand, $event)}}}):_vm._e()]}},{key:"item.service",fn:function(ref){
        var item = ref.item;
return [(item.shippingLeadTimeCreateCommand.exclude)?_c('label',[_vm._v(_vm._s(item.shippingLeadTimeCreateCommand.service))]):_vm._e(),(!item.shippingLeadTimeCreateCommand.exclude)?_c('base-input-text-field-thin',{staticClass:"mt-1 mb-1",class:_vm.isEnding(item),attrs:{"value":item.shippingLeadTimeCreateCommand.service},on:{"blur":function($event){item.shippingLeadTimeCreateCommand.service = arguments[0]}}}):_vm._e()]}},{key:"item.events",fn:function(ref){
        var item = ref.item;
return [_c('base-tool-tip',{attrs:{"label":"Edit events","icon":"mdi-pencil"},on:{"click":function($event){return _vm.displayEventsDialog(item.shippingLeadTimeCreateCommand)}}})]}},{key:"item.startDate",fn:function(ref){
        var item = ref.item;
return [(!item.shippingLeadTimeCreateCommand.exclude)?_c('base-input-select-date-picker-thin',{staticClass:"mt-1 mb-1",attrs:{"selected-value":item.shippingLeadTimeCreateCommand.validFrom,"validate-on-blur":true,"hint":item.shippingLeadTimeCreateCommand.end ? 'End date' : '',"rules":[_vm.rules.requiredUnlessExclude({
          id: item.shippingLeadTimeCreateCommand.validFrom,
          createCommand: item.shippingLeadTimeCreateCommand
        }),
        _vm.rules.afterPreviousValidFrom(item)]},on:{"change":function($event){item.shippingLeadTimeCreateCommand.validFrom = arguments[0]}}}):_vm._e()]}},{key:"item.exclude",fn:function(ref){
        var item = ref.item;
return [_c('base-tool-tip2',[_c('v-switch',{attrs:{"dense":"","color":"red darken-2"},model:{value:(item.shippingLeadTimeCreateCommand.exclude),callback:function ($$v) {_vm.$set(item.shippingLeadTimeCreateCommand, "exclude", $$v)},expression:"item.shippingLeadTimeCreateCommand.exclude"}}),_c('template',{slot:"tooltip"},[_vm._v(" No new shipping lead time will be created and the existing will be kept as it is. ")])],2)]}},{key:"item.end",fn:function(ref){
        var item = ref.item;
return [_c('base-tool-tip2',[(!item.shippingLeadTimeCreateCommand.exclude)?_c('v-switch',{attrs:{"dense":"","color":"red darken-2"},model:{value:(item.shippingLeadTimeCreateCommand.end),callback:function ($$v) {_vm.$set(item.shippingLeadTimeCreateCommand, "end", $$v)},expression:"item.shippingLeadTimeCreateCommand.end"}}):_vm._e(),_c('template',{slot:"tooltip"},[_vm._v(" No new shipping lead time will be created but the existing will be closed with the selected date. ")])],2)]}}],null,false,3421357281)}),_c('replace-shipping-lead-time-events-dialog',{key:_vm.shippingLeadTimeInContext && _vm.shippingLeadTimeInContext.id,attrs:{"shipping-lead-time-events":_vm.shippingLeadTimeInContext.shippingEvents,"freight-services":_vm.freightServices,"selected-freight-service-id":_vm.freightService.id,"show-dialog":_vm.showEventsDialog},on:{"updateEvents":_vm.updateEvents,"close":_vm.hideEventsDialog}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }