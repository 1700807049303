<template>
  <div v-if="shippingLeadTime">
    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Country of delivery
      </v-col>
      <v-col class="pt-2 pb-2 pr-0 text--black" >
        <base-label-with-flag
          v-if="countryOfDelivery"
          :item-country="countryOfDelivery.code"
          :item-label="countryOfDelivery.name"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
       Place of loading
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        <base-label-with-flag
          v-if="placeOfLoading"
          :item-country="placeOfLoading.location.code"
          :item-label="placeOfLoading.description"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Mode of transport
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        <base-label-with-freight-service
          v-if="freightService"
          :freight-service="freightService"/>
      </v-col>
    </v-row>

    <v-row >
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Place of delivery
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        <base-label-with-flag
          v-if="placeOfDelivery"
          :item-country="placeOfDelivery.location.code"
          :item-label="placeOfDelivery.description"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Service provider
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ serviceProvider && serviceProvider.description }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Carrier
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ carrier && carrier.description }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Service code
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ serviceCode && serviceCode.description }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Carrier service string
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ shippingLeadTime.service }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Main
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ shippingLeadTime.main ? 'Yes' : 'No' }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Default for country of delivery
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ shippingLeadTime.defaultForCountryOfDelivery ? 'Yes' : 'No' }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Valid from
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ shippingLeadTime.validFrom  }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="ml-4 pt-2 pb-2">
        Valid to
      </v-col>
      <v-col class="pt-2 pb-2 pr-0">
        {{ shippingLeadTime.validTo  }}
      </v-col>
    </v-row>

    <shipping-lead-time-events
      :shipping-lead-time-events="shippingLeadTime.shippingLeadTimeEvents"/>
  </div>
</template>

<script>

import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import ShippingLeadTimeEvents from '@/components/shippingleadtime/ShippingLeadTimeEvents'

export default {
  name: 'CreateShippingLeadTimeStep3',
  components: {
    ShippingLeadTimeEvents,
    BaseLabelWithFreightService,
    BaseLabelWithFlag
  },
  props: {
    shippingLeadTime: {
      type: Object,
      require: true
    },
    geographicalCountries: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    placesOfLoading: {
      type: Array,
      require: true
    },
    placesOfDelivery: {
      type: Array,
      require: true
    },
    serviceProviders: {
      type: Array,
      require: true
    },
    carriers: {
      type: Array,
      require: true
    },
    serviceCodes: {
      type: Array,
      require: true
    }
  },
  computed: {
    countryOfDelivery () {
      return this.geographicalCountries.find(item => item.id === this.shippingLeadTime.countryOfDeliveryId)
    },
    placeOfLoading () {
      return this.placesOfLoading.find(item => item.id === this.shippingLeadTime.placeOfLoadingId)
    },
    placeOfDelivery () {
      return this.placesOfDelivery.find(item => item.id === this.shippingLeadTime.placeOfDeliveryId)
    },
    freightService () {
      return this.freightServices.find(item => item.id === this.shippingLeadTime.freightServiceId)
    },
    carrier () {
      return this.carriers.find(item => item.id === this.shippingLeadTime.carrierId)
    },
    serviceProvider () {
      return this.serviceProviders.find(item => item.id === this.shippingLeadTime.serviceProviderId)
    },
    serviceCode () {
      return this.serviceCodes.find(item => item.name === this.shippingLeadTime.serviceCode)
    }
  }
}
</script>

<style scoped>

</style>
