<template>
  <div v-if="hasAdditionalShippingLeadTimes">
    <v-divider class="mt-4 mb-2"></v-divider>
    <v-row class="mt-2 mr-0">
      <v-col class="ml-8 pt-2 pb-2">
        Additional lead times
      </v-col>
    </v-row>
    <v-row class="mt-2 mr-0" v-for="interval in additionalShippingLeadTimeIntervals" v-bind:key="interval.validFrom">
      <v-col cols="4" class="ml-8 pt-2 pb-2 ">
        {{ interval.validFrom }} - {{ interval.validTo }}
      </v-col>
      <v-col cols="2" class="pt-2 pb-2 pl-1 pr-1" >
        <base-label-with-flag v-if="interval.loadingDescription" :item-label="interval.loadingDescription" :item-country="interval.loadingCountryCode"/>
        <span v-else>-</span>
      </v-col>
      <v-col cols="2" class="pt-2 pb-2 pl-1 pr-1">
        <base-label-with-flag v-if="interval.deliveryDescription" :item-label="interval.deliveryDescription" :item-country="interval.deliveryCountryCode"/>
        <span v-else>-</span>
      </v-col>
      <v-col class="pt-2 pb-2 pl-1 pr-1">
        {{ totalDays }}
        <span v-for="additionalShippingLeadTime in interval.additionalShippingLeadTimes" v-bind:key="additionalShippingLeadTime.id">
                     +
          <v-tooltip bottom color="red">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <a href="#" @click="viewAdditionalShippingLeadTimes(additionalShippingLeadTime)">{{ additionalShippingLeadTime.additionalLeadTimeInDays }}</a>
              </span>
            </template>
            <span>View additional shipping lead time</span>
          </v-tooltip>
        </span>

        <span> = {{totalDays + interval.additionalLeadTimeInDays}}</span>
      </v-col>
    </v-row>
    <search-shipping-lead-time-view-additional-lead-time-details-dialog
      :view-dialog="viewAdditionalShippingLeadTimeDialog"
      :view-dialog-additional-shipping-lead-time="viewAdditionalShippingLeadTime"
      @close="() => this.viewAdditionalShippingLeadTimeDialog = false"/>
  </div>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import SearchShippingLeadTimeViewAdditionalLeadTimeDetailsDialog from '@/components/shippingleadtime/searchshippingleadtime/SearchShippingLeadTimeViewAdditionalLeadTimeDetailsDialog'
export default {
  name: 'SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime',
  components: {
    SearchShippingLeadTimeViewAdditionalLeadTimeDetailsDialog,
    BaseLabelWithFlag
  },
  props: {
    hasAdditionalShippingLeadTimes: {
      type: Boolean,
      require: true
    },
    totalDays: {
      type: Number,
      require: true
    },
    additionalShippingLeadTimeIntervals: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      shippingEvents: [],
      viewAdditionalShippingLeadTimeDialog: false,
      viewAdditionalShippingLeadTime: {}
    }
  },
  methods: {
    viewAdditionalShippingLeadTimes (additionalShippingLeadTime) {
      this.viewAdditionalShippingLeadTime = additionalShippingLeadTime
      this.viewAdditionalShippingLeadTimeDialog = true
    }
  }
}
</script>

<style scoped>

</style>
