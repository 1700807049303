<template>
  <div>
    <v-select
      filled
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="itemLabel"
      v-model="selectValueItem"
      :hint="hint"
      persistent-hint
      disabled
      @change="$emit('change', selectValueItem)">
      <template v-slot:item="{ item } ">
        <country-flag :country="resolve(itemCountry, item)" class="mr-0 mt-1 mb-1" />
        {{ item[itemText] }}
      </template>
      <template v-slot:prepend v-if="selectedCountryCode">
          <country-flag :country="selectedCountryCode" />
      </template>
    </v-select>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'BaseInputSelectWithFlagReadOnly',
  components: { CountryFlag },
  props: {
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      require: true
    },
    itemValue: {
      type: String,
      require: true
    },
    itemCountry: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      type: String,
      require: false
    },
    countryFirstInSelect: {
      // Add a country that you want to be shown
      // first in the select
      type: String,
      require: false
    }
  },
  data () {
    return {
      selectValueItem: ''
    }
  },
  computed: {
    selectedCountryCode () {
      const selectedItem = this.items.find(item => item[this.itemValue] === this.selectValueItem)
      if (selectedItem) {
        return this.resolve(this.itemCountry, selectedItem)
      } else {
        return null
      }
    },
    preAppendedItems () {
      return this.items.filter(item => item.location.id === this.countryFirstInSelect)
    },
    hint () {
      return this.itemLabel + ' is not allowed to be updated'
    }
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  },
  methods: {
    resolve (path, obj) {
      return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>

<style scoped>
  .v-list-item {
    min-height: 48px;
  }
  .item-text {
    margin-left: 10px;
    margin-top: 5px;
  }
</style>
