<template>
  <div>
    <v-data-table v-if="!this.isInit"
      dense
      :headers="tableHeaders"
      :options.sync="options"
      :page.sync="page"
      :items="items"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      @update:options="performSearch">

      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>

      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>

    </v-data-table>
    <v-divider></v-divider>
    <v-row class="ma-3" v-if="!this.isInit">
      <p class="mb-0 mt-3 ml-2 body-2">{{footerInfo}}</p>
      <v-spacer></v-spacer>
      <v-pagination
        elevation-0
        class="mb-3 elevation-0"
        color="red darken-2 elevation-0"
        v-model="page"
        :value="page"
        :total-visible="10"
        :length="pageCount">
      </v-pagination>
    </v-row>
    <v-row class="ma-3" v-if="this.isInit">
      <p class="mb-2 mt-0 ml-1 body-2">Nothing found to display</p>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { arraysUtils } from '@/mixins/utilsMixin'

export default {
  name: 'BaseTablePagedServerSide',
  mixins: [arraysUtils],
  props: {
    searchCriteria: {
      type: Object,
      require: true
    },
    tableHeaders: {
      type: Array,
      require: true
    },
    url: {
      type: String,
      require: true
    },
    searchResultName: {
      type: String,
      require: true
    },
    displayFooterText: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data () {
    return {
      itemsPerPage: 50,
      options: {},
      page: 1,
      pageCount: 0
    }
  },
  computed: {
    items () {
      return this.$store.getters[this.searchResultName].items
    },
    totalItems () {
      return this.$store.getters[this.searchResultName].totalItems
    },
    isInit () {
      return this.$store.getters[this.searchResultName].isInit
    },
    footerInfo () {
      if (!this.totalItems) {
        return ''
      } else if (this.totalItems === 1 && this.displayFooterText) {
        return 'Showing one record'
      } else if (this.totalItems > 1 && this.displayFooterText) {
        return 'Showing ' + this.pageStart + ' to ' + this.pageStop + ' of ' + this.totalItems + ' records'
      } else {
        return ''
      }
    },
    pageStart () {
      return this.itemsPerPage * this.page - this.itemsPerPage + 1
    },
    pageStop () {
      if (this.page === this.pageCount) {
        return this.totalItems
      } else {
        return this.itemsPerPage * this.page
      }
    }
  },
  methods: {
    performSearch () {
      if (!this.isInit) {
        axios.post(this.url, {
          searchCriteria: this.removeEmptyArrays(this.searchCriteria),
          page: this.options.page,
          limit: this.options.itemsPerPage,
          orderBy: this.options.sortBy[0] ? this.options.sortBy[0] : null,
          ascending: this.options.sortDesc[0] ? this.options.sortDesc[0] : null
        }).then(response => {
          this.$store.commit(this.getUpdateSearchResultMutationName(), {
            items: response.data.data,
            totalItems: response.data.count,
            isInit: !response.data.count > 0
          })
        }).catch(e => console.log(e))
      } else {
        const searchResults = this.$store.getters[this.searchResultName]
        searchResults.isInit = false
        this.$store.commit(this.getUpdateSearchResultMutationName(), searchResults)
      }
    },
    doSearch () {
      this.page = 1
      this.performSearch()
    },
    doReset () {
      this.page = 1
      this.$store.commit(this.getUpdateSearchResultMutationName(), {
        items: [],
        totalItems: 0,
        isInit: true
      })
    },
    getTotalItems () {
      return this.totalItems
    },
    getUpdateSearchResultMutationName () {
      return 'update' + this.nameCapitalized(this.searchResultName)
    },
    nameCapitalized (name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    }
  }
}
</script>

<style>
.v-data-table >
.v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {

  padding: 0 0px 0 10px !important;
}
</style>
