<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
        Lead time difference details
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text style="height: 900px;" class="text-body-1 text--primary">

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Country of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.countryOfDelivery.code"
                                  :item-label="viewDialogLeadTimeDifference.countryOfDelivery.name"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping place of loading
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.placeOfLoading.location.code"
                                  :item-label="viewDialogLeadTimeDifference.placeOfLoading.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping place of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.placeOfDelivery.location.code"
                                  :item-label="viewDialogLeadTimeDifference.placeOfDelivery.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping mode of transport
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freightService="viewDialogLeadTimeDifference.shippingFreightService"/>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Is via transit?
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.transit ? 'Yes' : 'No' }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="viewDialogLeadTimeDifference.transit">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Transit available point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.transitAvailablePoint.location.code"
                                  :item-label="viewDialogLeadTimeDifference.transitAvailablePoint.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="viewDialogLeadTimeDifference.transit">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Transit place of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.transitPlaceOfDelivery.location.code"
                                  :item-label="viewDialogLeadTimeDifference.transitPlaceOfDelivery.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="viewDialogLeadTimeDifference.transit">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Transit mode of transport
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freightService="viewDialogLeadTimeDifference.transitFreightService"/>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Has haulage?
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.haulage ? 'Yes' : 'No' }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="viewDialogLeadTimeDifference.haulage">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Haulage arrival point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.haulageArrivalPoint.location.code"
                                  :item-label="viewDialogLeadTimeDifference.haulageArrivalPoint.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="viewDialogLeadTimeDifference.haulage">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Haulage destination point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-flag :item-country="viewDialogLeadTimeDifference.haulageDestinationPoint.location.code"
                                  :item-label="viewDialogLeadTimeDifference.haulageDestinationPoint.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="viewDialogLeadTimeDifference.haulage">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Transit mode of transport
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freightService="viewDialogLeadTimeDifference.haulageFreightService"/>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Warehouse
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{viewDialogLeadTimeDifference.warehouse.currentWarehouseCode}}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Planning market
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{viewDialogLeadTimeDifference.planningMarket.description}}
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid on:
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black font-weight-bold">
            {{ searchCriteria.firstCompareDate }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black font-weight-bold">
            {{ searchCriteria.secondCompareDate }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            Difference
          </v-col>
        </v-row>
        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareShippingDays, viewDialogLeadTimeDifference.secondCompareShippingDays)"
               dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total shipping days:
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.firstCompareShippingDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.secondCompareShippingDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareShippingDays, viewDialogLeadTimeDifference.secondCompareShippingDays) }}
          </v-col>
        </v-row>

        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareTransitDays, viewDialogLeadTimeDifference.secondCompareTransitDays)"
               dense
               v-if="viewDialogLeadTimeDifference.transit">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total transit days
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.firstCompareTransitDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.secondCompareTransitDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareTransitDays, viewDialogLeadTimeDifference.secondCompareTransitDays) }}
          </v-col>
        </v-row>

        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareHaulageDays, viewDialogLeadTimeDifference.secondCompareHaulageDays)"
               dense
               v-if="viewDialogLeadTimeDifference.haulage">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total haulage days
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.firstCompareHaulageDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.secondCompareHaulageDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareHaulageDays, viewDialogLeadTimeDifference.secondCompareHaulageDays) }}
          </v-col>
        </v-row>

        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareFinalDestinationDays, viewDialogLeadTimeDifference.secondCompareFinalDestinationDays)"
               dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total final destinations days
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.firstCompareFinalDestinationDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.secondCompareFinalDestinationDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareFinalDestinationDays, viewDialogLeadTimeDifference.secondCompareFinalDestinationDays) }}
          </v-col>
        </v-row>

        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareDaysWhenWarehouseIsClosed, viewDialogLeadTimeDifference.secondCompareDaysWhenWarehouseIsClosed)"
               dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total warehouse closed days
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.firstCompareDaysWhenWarehouseIsClosed }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.secondCompareDaysWhenWarehouseIsClosed }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareDaysWhenWarehouseIsClosed, viewDialogLeadTimeDifference.secondCompareDaysWhenWarehouseIsClosed) }}
          </v-col>
        </v-row>

        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareDaysWhenWarehouseIsUnDeliverableToStore, viewDialogLeadTimeDifference.secondCompareDaysWhenWarehouseIsUnDeliverableToStore)"
               dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total undeliverable days
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.firstCompareDaysWhenWarehouseIsUnDeliverableToStore }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLeadTimeDifference.secondCompareDaysWhenWarehouseIsUnDeliverableToStore }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareDaysWhenWarehouseIsUnDeliverableToStore, viewDialogLeadTimeDifference.secondCompareDaysWhenWarehouseIsUnDeliverableToStore) }}
          </v-col>
        </v-row>

        <v-row :class="rowHighlight(viewDialogLeadTimeDifference.firstCompareDays, viewDialogLeadTimeDifference.secondCompareDays)"
               dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total lead time days
          </v-col>
          <v-col cols="2" class="pt-2 pb-2 pr-0 text--black font-weight-bold">
            {{ viewDialogLeadTimeDifference.firstCompareDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black font-weight-bold">
            {{ viewDialogLeadTimeDifference.secondCompareDays }}
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black font-weight-bold">
            {{ differenceInDays(viewDialogLeadTimeDifference.firstCompareDays, viewDialogLeadTimeDifference.secondCompareDays) }}
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import { mathUtils } from '@/mixins/utilsMixin'

export default {
  name: 'SearchLeadTimeDifferenceViewDetailsDialog',
  components: {
    BaseInputSecondaryButton,
    BaseLabelWithFlag,
    BaseLabelWithFreightService
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogLeadTimeDifference: {
      type: Object,
      require: true
    },
    searchCriteria: {
      type: Object,
      require: true
    }
  },
  mixins: [mathUtils],
  methods: {
    close () {
      this.$emit('close')
    },
    rowHighlight (value1, value2) {
      return ['mt-1', 'mr-0', value1 !== value2 ? 'red lighten-2' : '']
    },
    differenceInDays (val1, val2) {
      return this.difference(val1, val2)
    }
  }
}
</script>

<style scoped>

</style>
