<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
        <template v-if="proposedLogisticLeadTime">
          Proposed shipping & logistic lead time details
        </template>
        <template v-if="!proposedLogisticLeadTime">
          Shipping & logistic lead time details
        </template>
      </v-card-title>

      <v-divider class="mb-2"></v-divider>

      <v-card-text style="height: 900px;" class="text-body-1 text--primary">
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Country of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="shippingLeadTime.countryOfDelivery.code"
                                  :item-label="shippingLeadTime.countryOfDelivery.name"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping place of loading
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="shippingLeadTime.placeOfLoading.location.code"
                                  :item-label="shippingLeadTime.placeOfLoading.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping place of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="shippingLeadTime.placeOfDelivery.location.code"
                                  :item-label="shippingLeadTime.placeOfDelivery.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping mode of transport
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freightService="shippingLeadTime.freightService"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping service provider
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ shippingLeadTime.serviceProvider.description }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Shipping carrier
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ shippingLeadTime.carrier && shippingLeadTime.carrier.description }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Service
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ shippingLeadTime.service }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Default for delivery country
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ shippingLeadTime.defaultForCountryOfDelivery ? 'Yes' : 'No' }}
          </v-col>
        </v-row>

        <div v-if="transitLeadTime">
          <v-divider class="mb-2"></v-divider>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Transit warehouse
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black">
              <base-label-with-flag :item-country="transitLeadTime.transitWarehouse.geographicalCountry.code"
                                    :item-label="transitLeadTime.transitWarehouse.description"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Available point
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black">
              <base-label-with-flag :item-country="transitLeadTime.availablePoint.location.code"
                                    :item-label="transitLeadTime.availablePoint.description"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Place of delivery
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              <base-label-with-flag :item-country="transitLeadTime.placeOfDelivery.location.code"
                                    :item-label="transitLeadTime.placeOfDelivery.description"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Transit mode of transport
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              <base-label-with-freight-service :freightService="transitLeadTime.outboundFreightService"/>
            </v-col>
          </v-row>
        </div>

        <div v-if="haulageLeadTime">
          <v-divider class="mb-2"></v-divider>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Haulage arrival point
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black">
              <base-label-with-flag :item-country="haulageLeadTime.arrivalPoint.location.code"
                                    :item-label="haulageLeadTime.arrivalPoint.description"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Haulage destination point
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black">
              <base-label-with-flag :item-country="haulageLeadTime.destinationPoint.location.code"
                                    :item-label="haulageLeadTime.destinationPoint.description"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Haulage mode of transport
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              <base-label-with-freight-service :freightService="haulageLeadTime.freightService"/>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Final destination
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag :item-country="finalDestinationLeadTime.location.location.code"
                                  :item-label="finalDestinationLeadTime.location.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Final destination warehouses
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <div class="pt-1 pb-1">
              <span class="text-body-2 ml-1 d-flex align-center padding"> {{ formatFinalDestinationWarehouse }} </span>
            </div>
          </v-col>
        </v-row>

        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total shipping days:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ shippingLeadTime.totalDays }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="transitLeadTime">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total transit days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ transitLeadTime.totalDays }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="haulageLeadTime">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total haulage days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ haulageLeadTime.totalDays }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total quick demand deduction days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLogisticLeadTime.totalQuickDemandDeductionInDays }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total final destinations days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ finalDestinationLeadTime.totalDays }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total warehouse closed days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLogisticLeadTime.totalDaysWhenWarehouseIsClosed }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total undeliverable days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ viewDialogLogisticLeadTime.totalDaysWhenWarehouseIsUnDeliverableToStore }}
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total days
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
              {{ viewDialogLogisticLeadTime.totalDays }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Total weeks
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
              {{ viewDialogLogisticLeadTime.totalWeeks }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="!proposedLogisticLeadTime">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Published on:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogLogisticLeadTime.publishTime">
            <v-icon medium>mdi-clock</v-icon>
            {{ viewDialogLogisticLeadTime.publishTime | formatDate }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="!proposedLogisticLeadTime">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid from:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogLogisticLeadTime.validFrom">
            <v-icon medium>mdi-clock</v-icon>
            {{ viewDialogLogisticLeadTime.validFrom | formatDate }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense v-if="!proposedLogisticLeadTime">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid to:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogLogisticLeadTime.validTo">
            <v-icon medium>mdi-clock</v-icon>
            {{ viewDialogLogisticLeadTime.validTo | formatDate }}
          </v-col>
        </v-row>
        <search-shipping-lead-time-view-details-dialog-additional-shipping-lead-time
          :total-days="viewDialogLogisticLeadTime.totalDays"
          :has-additional-shipping-lead-times="shippingLeadTime.hasAdditionalShippingLeadTimes"
          :additional-shipping-lead-time-intervals="viewDialogLogisticLeadTime.additionalShippingLeadTimeIntervals"/>
        <v-row class="mt-0">
          <v-col cols="12" class="pt-0 pl-3 pr-3">
            <logistic-lead-time-events :lead-time-events="allLeadTimeEvents"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button v-if="canUpdate" label="Update" icon="mdi-pencil" @click="update"/>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import LogisticLeadTimeEvents from '@/components/logisticsleadtime/LogisticLeadTimeEvents'
import SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime from '@/components/shippingleadtime/searchshippingleadtime/SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime'

export default {
  name: 'SearchLogisticLeadTimeViewDetailsDialog',
  components: {
    SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime,
    LogisticLeadTimeEvents,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    BaseLabelWithFlag,
    BaseLabelWithFreightService
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogLogisticLeadTime: {
      type: Object,
      require: true
    },
    proposedLogisticLeadTime: {
      type: Boolean,
      require: false,
      default: false
    },
    canUpdate: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data () {
    return {
      allLeadTimeEvents: [],
      shippingLeadTime: {},
      transitLeadTime: {},
      haulageLeadTime: {},
      finalDestinationLeadTime: {}
    }
  },
  watch: {
    viewDialogLogisticLeadTime () {
      this.shippingLeadTime = this.viewDialogLogisticLeadTime.shippingLeadTime
      this.transitLeadTime = this.viewDialogLogisticLeadTime.transitLeadTime
      this.haulageLeadTime = this.viewDialogLogisticLeadTime.haulageLeadTime
      this.finalDestinationLeadTime = this.viewDialogLogisticLeadTime.finalDestinationLeadTime
      this.allLeadTimeEvents = this.viewDialogLogisticLeadTime.allEvents
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    update () {
      this.$router.push({ path: '/LogisticsLeadTime/Update/' + this.viewDialogLogisticLeadTime.id })
    }
  },
  computed: {
    formatFinalDestinationWarehouse () {
      return this.finalDestinationLeadTime.logicalWarehouses.map(warehouse => warehouse.currentWarehouseCode).join(', ')
    }
  }
}
</script>

<style scoped>

</style>
