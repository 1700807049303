<template>
  <v-card outlined elevation="0" class="mt-8 v-card__text">
    <v-row>
          <v-col cols="2">Week</v-col>
          <v-col cols="1">Day#</v-col>
          <v-col cols="2">Days</v-col>
          <v-col cols="3">Event type</v-col>
          <v-col cols="3">Shipping/Arrival point</v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>
    <v-row class="mr-1 mb-1 mt week-details" v-for="(n, eventDay) in numberOfDays" v-bind:key="eventDay">
      <v-col cols="2" class="mt-0 mb-0 pt-0 pb-0">
        <v-list-item-avatar v-if="startOfWeek(eventDay)">
          <v-avatar color="red darken-2" size="50" class="white--text">
            W{{ weekNumber(eventDay) }}
          </v-avatar>
        </v-list-item-avatar>
      </v-col>
      <v-col cols="1" align-self="center" class="mt-0 mb-0 pt-0 pb-0">{{ eventDay + 1 }}</v-col>
      <v-col cols="2" align-self="center" class="mt-0 mb-0 pt-0 pb-0">{{ weeks[dayOfWeek(eventDay)] }}</v-col>
      <!-- See if there is any event added -->
      <v-col cols="4" align-self="center" class="pl-4 pt-0 pb-0" v-if="leadTimeEvents.find(event => eventDay === (event.daysSinceStart - 1))">
        {{ leadTimeEvents.find(event => eventDay === (event.daysSinceStart - 1)).name }}
      </v-col>
      <!-- notes -->
      <v-col cols="3" align-self="center" class="pl-4 pt-0 pb-0" v-if="leadTimeEvents.find(event => eventDay === (event.daysSinceStart - 1))">
        {{ leadTimeEvents.find(event => eventDay === (event.daysSinceStart - 1)).note }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'LogisticLeadTimeEvents',
  props: {
    leadTimeEvents: {
      type: Array,
      require: true
    }
  },
  computed: {
    numberOfDays () {
      return this.leadTimeEvents.length > 0 && this.leadTimeEvents[this.leadTimeEvents.length - 1].daysSinceStart
    }
  },
  data () {
    return {
      weeks: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    }
  },
  methods: {
    startOfWeek (day) {
      return day % 7 === 0 // Monday
    },
    weekNumber (day) {
      return day / 7
    },
    dayOfWeek (day) {
      return day % 7
    }
  }
}
</script>

<style scoped>
.week-details {
  border-bottom: 1px dotted lightgrey;
}

.week-details:first-child {
  margin-top: 12px;
}
</style>
