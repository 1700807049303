import * as msal from '@azure/msal-browser'

// MSAL object used for signing in users with MS identity platform
let msalApp
const isTestEnv = window.location.href.indexOf('sllt.az.hmgroup.com') === -1

const scopes = []
if (isTestEnv) {
  scopes.push('api://4073da2a-11b8-4157-be03-f5b6ae5eda10/SLLT.User')
} else {
  scopes.push('api://8e4aada8-7d4c-4dc9-820a-41b2180bab82/SLLT.User')
}

export default {
  async configure () {
    // Can only call configure once
    if (msalApp) {
      return
    }

    let clientId = '3a36dda5-de01-4e01-b38c-959ab1485cb3'
    if (isTestEnv) {
      clientId = '94043cac-7ca9-4576-9b43-1959a7701204'
    }

    const config = {
      auth: {
        clientId,
        redirectUri: window.location.origin,
        authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
        mainWindowRedirectUri: window.location.origin + '/#/logout',
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: '/#/logout'
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    }
    // Create our shared/static MSAL app object
    msalApp = new msal.PublicClientApplication(config)
  },

  //
  // Return the configured client id
  //
  clientId () {
    if (!msalApp) {
      return null
    }

    return msalApp.config.auth.clientId
  },

  // Logout any stored user
  async logout () {
    if (!msalApp) {
      return
    }

    return msalApp.logoutRedirect()
  },

  // Call to get user, probably cached and stored locally by MSAL
  user () {
    if (!msalApp) {
      return null
    }

    const currentAccounts = msalApp.getAllAccounts()
    if (!currentAccounts || currentAccounts.length === 0) {
      // No user signed in
      return null
    } else if (currentAccounts.length > 1) {
      return currentAccounts[0]
    } else {
      return currentAccounts[0]
    }
  },

  // Call through to acquireTokenSilent or acquireTokenPopup
  async acquireToken () {
    await this.configure()
    if (!msalApp) {
      return null
    }

    // Set scopes for token request
    const accessTokenRequest = {
      scopes,
      account: this.user()
    }

    try {
      let tokenResp = await msalApp.handleRedirectPromise()
      if (tokenResp) {
        return {
          accessToken: tokenResp.accessToken,
          expiresOn: tokenResp.expiresOn
        }
      } else {
        try {
          // 1. Try to acquire token silently
          // 1.1 See if the token has expired.
          if (msalApp.expiresOn && msalApp.expiresOn < new Date()) {
            try {
              tokenResp = await msalApp.acquireTokenByRefreshToken(accessTokenRequest)
            } catch (err) {
              tokenResp = await msalApp.acquireTokenSilent(accessTokenRequest)
            }
          } else {
            // 1.2 get new token silently
            tokenResp = await msalApp.acquireTokenSilent(accessTokenRequest)
          }
        } catch (err) {
          // 2. Silent process might have failed so try via popup
          tokenResp = await msalApp.acquireTokenRedirect(accessTokenRequest)
        }
        // Just in case check, probably never triggers
        if (!tokenResp.accessToken) {
          throw new Error("### accessToken not found in response, that's bad")
        }
        return {
          accessToken: tokenResp.accessToken,
          expiresOn: tokenResp.expiresOn
        }
      }
    } catch (error) {
      console.error('Failed to handleRedirectPromise()', error)
    }
  },

  // Check if we have been setup & configured
  isConfigured () {
    return msalApp != null
  }
}
