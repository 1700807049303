<template>
    <v-dialog
      v-model="viewDialog"
      scrollable
      max-width="750px">
      <v-card v-if="viewDialog">
        <base-card-title label="Shipping lead time details"/>
        <v-card-text style="height: 900px;" class="text-body-1 text--primary">

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Country of delivery
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag
                :item-country="viewDialogShippingLeadTime.countryOfDelivery.code"
                :item-label="viewDialogShippingLeadTime.countryOfDelivery.name"/>
            </v-col>
          </v-row>
          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Country of place of loading
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag
                :item-country="viewDialogShippingLeadTime.placeOfLoading.location.code"
                :item-label="viewDialogShippingLeadTime.placeOfLoading.description"/>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>

          <v-row class="mt-2 mr-0" >
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Mode of transport
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              <base-label-with-freight-service :freight-service="viewDialogShippingLeadTime.freightService"/>
            </v-col>
          </v-row>

          <v-row class="mt-2 mb-1 mr-0" >
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Place of delivery
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag
                :item-country="viewDialogShippingLeadTime.placeOfDelivery.location.code"
                :item-label="viewDialogShippingLeadTime.placeOfDelivery.description"/>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>

          <v-row class="mt-2 mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Service provider
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.serviceProvider.description }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Carrier
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.carrier && viewDialogShippingLeadTime.carrier.description }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Carrier service string
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.service }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Service code
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.serviceCode && viewDialogShippingLeadTime.serviceCode.description }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Main
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.main ? 'Yes' : 'No' }}
            </v-col>
          </v-row>

          <v-row class="mb-2 mr-0" >
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Default for country of delivery
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 ">
              {{ viewDialogShippingLeadTime.defaultForCountryOfDelivery ? 'Yes' : 'No' }}
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>

          <v-row class="mt-2 mr-0" >
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Total days
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.totalDays }}
            </v-col>
          </v-row>

          <v-row class="mt-2 mr-0" >
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Total weeks
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.totalWeeks }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Days to closing departure
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.totalDaysClosingToDeparture }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Days in transport
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.totalDaysInTransport }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Valid from
            </v-col>
            <v-col class="pt-2 pb-2 pr-0" v-if="viewDialogShippingLeadTime.validFrom">
              <v-icon medium>mdi-clock</v-icon> {{ viewDialogShippingLeadTime.validFrom }}
            </v-col>
          </v-row>

          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Valid to
            </v-col>
            <v-col class="pt-2 pb-2 pr-0" v-if="viewDialogShippingLeadTime.validTo">
              <v-icon medium>mdi-clock</v-icon> {{ viewDialogShippingLeadTime.validTo }}
            </v-col>
          </v-row>
          <v-row class="mr-0">
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Modified on
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ viewDialogShippingLeadTime.modificationTime | formatDate }}
            </v-col>
          </v-row>
          <search-shipping-lead-time-view-details-dialog-additional-shipping-lead-time
            :has-additional-shipping-lead-times="viewDialogShippingLeadTime.hasAdditionalShippingLeadTimes"
            :additional-shipping-lead-time-intervals="viewDialogShippingLeadTime.additionalShippingLeadTimeIntervals"
            :total-days="viewDialogShippingLeadTime.totalDays"/>
          <v-row class="mt-0" >
            <v-col cols="10" class="pl-7 pr-0">
              <shipping-lead-time-events :shipping-lead-time-events="shippingEvents"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <base-input-primary-button v-if="canUpdate" label="Update" icon="mdi-pencil" @click="update"/>
          <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import ShippingLeadTimeEvents from '@/components/shippingleadtime/ShippingLeadTimeEvents'
import axios from 'axios'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime from '@/components/shippingleadtime/searchshippingleadtime/SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime'

export default {
  name: 'SearchShippingLeadTimeViewDetailsDialog',
  components: {
    SearchShippingLeadTimeViewDetailsDialogAdditionalShippingLeadTime,
    BaseInputSecondaryButton,
    ShippingLeadTimeEvents,
    BaseInputPrimaryButton,
    BaseLabelWithFreightService,
    BaseLabelWithFlag,
    BaseCardTitle
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogShippingLeadTime: {
      type: Object,
      require: true
    },
    canUpdate: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data () {
    return {
      shippingEvents: []
    }
  },
  watch: {
    viewDialogShippingLeadTime (val) {
      if (val.shippingEvents && val.shippingEvents.length !== 0) {
        this.shippingEvents = val.shippingEvents
      } else {
        this.getShippingEvents()
      }
    }
  },
  mounted () {
    this.getShippingEvents()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    update () {
      this.$router.push({ path: '/ShippingLeadTime/Update/' + this.viewDialogShippingLeadTime.id })
    },
    getShippingEvents () {
      axios.get('/api/touchpoint/shipping-lead-time/' + this.viewDialogShippingLeadTime.id + '/shipping-event')
        .then(response => ([...response.data]).forEach(data => this.shippingEvents.push(data)))
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>
