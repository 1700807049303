<template>
  <v-text-field
    filled
    :label="itemLabel"
    v-model="theValue"
    disabled
    @blur="$emit('blur', theValue)">
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseInputReadOnlyTextField',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    value: {
      type: String,
      require: false
    }
  },
  data () {
    return {
      theValue: ''
    }
  },
  mounted () {
    this.theValue = this.value
  },
  watch: {
    value (val) {
      this.theValue = val
    }
  }
}
</script>

<style scoped>

</style>
