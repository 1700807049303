import axios from 'axios'
import _ from 'lodash'
import auth from '@/auth/auth-stateless'

const initState = () => {
  return {
    roles: [],
    username: '',
    abbreviatedUsername: '',
    accessToken: '',
    tokenExpiresOn: '',
    isAuthenticating: false
  }
}

const userInfo = {
  state: initState(),

  mutations: {
    populateUser (state, data) {
      state.roles = data.roles
      state.username = data.username
      state.abbreviatedUsername = data.abbreviatedUsername
    },
    updateToken (state, token) {
      state.accessToken = token.accessToken
      state.tokenExpiresOn = token.expiresOn
      state.isAuthenticating = false
    },
    clearToken (state) {
      state.accessToken = ''
      state.expiresOn = ''
    },
    elevateRole (state) {
      state.roles.push('ROLE_TECH_ADMIN')
    },
    resetUserInfo (state) {
      state.roles = []
      state.username = ''
      state.abbreviatedUsername = ''
      state.accessToken = ''
      state.isAuthenticating = false
    }
  },

  getters: {
    getUserRoles: state => state.roles,
    getUserName: state => state.username,
    getAbbreviatedUserName: state => state.abbreviatedUsername,
    isMemberOfAny: (state) => (roles) => {
      return state.roles.some(userRole => roles.indexOf(userRole) >= 0)
    },
    getAccessToken: state => state.accessToken,
    getTokenExpiresOn: state => state.tokenExpiresOn,
    isUserAuthenticated: state => !!state.accessToken && state.roles.length > 0,
    isAuthenticating: state => state.isAuthenticating
  },

  actions: {
    getUser: ({ commit, state }) => {
      if (!_.isEmpty(state.username) && !_.isEmpty(state.roles)) {
        return
      }
      return axios.get('/api/touchpoint/web-user')
        .then(response => {
          commit('populateUser', response.data)
        })
        .catch(e => console.log(e))
    },
    authenticateUser: ({ commit, state, dispatch }) => {
      state.isAuthenticating = true
      return auth.acquireToken()
        .then(response => {
          if (state.accessToken !== response) {
            commit('updateToken', response)
            return dispatch('getUser')
          }
        })
        .catch(e => console.log(e))
    },
    authenticateApiUser: ({ commit, state }) => {
      return auth.acquireToken()
        .then(response => {
          if (state.accessToken !== response) {
            commit('updateToken', response)
          }
        })
        .catch(e => console.log(e))
    }
  }
}

export default userInfo
