<template>
  <v-card outlined elevation="0" class="mt-8 v-card__text">
    <v-row class="pa-0">
      <v-card-text class="pa-3 pl-0 pt-0">
        <v-col class="ml-0">
          <span class="ml-0">Week</span>
          <span class="ml-3 mr-18">Day#</span>
          <span class="ml-3 mr-6">Days</span>
          <span class="ml-16 mr-10">Event type</span>
          <span class="ml-4">Shipping/Arrival point</span>
        </v-col>
      </v-card-text>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mr-0 mb-0 mt" v-for="(n, weekNumber) in numberOfWeeks" v-bind:key="weekNumber">
      <v-col cols="1"  class="ml-0 mt-3">
        <v-list-item-avatar>
          <v-avatar color="red darken-2" size="56" class="white--text">
            W{{ weekNumber }}
          </v-avatar>
        </v-list-item-avatar>
      </v-col>
      <v-col class="ml-3 mt-4">
        <div class="mt-3 mb-3" >
          <v-row class="week-details" v-for="weekDay in weeks" v-bind:key="weekDay.dayOfWeek">
            <v-col cols="1" class="pa-1">{{ weekNumber * 7 + weekDay.dayOfWeek }}</v-col>
            <v-col cols="3" class="pa-1">{{ weekDay.day }}</v-col>
            <!-- See if there is any event added -->
            <v-col cols="3" class="pa-1" v-if="shippingLeadTimeEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek)">
              {{ shippingLeadTimeEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek).type.name }}
            </v-col>
            <!-- notes -->
            <v-col class="pa-1" v-if="shippingLeadTimeEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek)">
              {{ shippingLeadTimeEvents.find(event => event.weekNumber === weekNumber && event.dayOfWeek === weekDay.dayOfWeek).note }}
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ShippingLeadTimeEvents',
  props: {
    shippingLeadTimeEvents: {
      type: Array,
      require: true
    }
  },
  computed: {
    numberOfWeeks () {
      // get the highest number for weekNumber to get number of weeks used.
      if (this.shippingLeadTimeEvents.length > 0) {
        return Math.max.apply(Math, this.shippingLeadTimeEvents.map(function (event) { return event.weekNumber + 1 }))
      } else {
        return 0
      }
    }
  },
  data () {
    return {
      weeks: [
        { day: 'Monday', dayOfWeek: 1 },
        { day: 'Tuesday', dayOfWeek: 2 },
        { day: 'Wednesday', dayOfWeek: 3 },
        { day: 'Thursday', dayOfWeek: 4 },
        { day: 'Friday', dayOfWeek: 5 },
        { day: 'Saturday', dayOfWeek: 6 },
        { day: 'Sunday', dayOfWeek: 7 }
      ]
    }
  }
}
</script>

<style scoped>
.row.week-details {
  border-bottom: 1px dotted lightgrey;
}
</style>
