import Vue from 'vue'
import Vuex from 'vuex'
import SupportData from '@/store/modules/supportdata'
import SearchShippingLeadTime from '@/store/modules/searchshippingleadtime'
import SearchAdditionalShippingLeadTime from '@/store/modules/searchadditionalshippingleadtime'
import SearchHaulageLeadTime from '@/store/modules/searchhaulageleadtime'
import SearchTransitLeadTime from '@/store/modules/searchtransitleadtime'
import SearchFinalDestinationLeadTime from '@/store/modules/searchfinaldestinationleadtime'
import SearchProposedHaulageLeadTime from '@/store/modules/searchproposedhaulageleadtime'
import SearchProposedFinalDestinationLeadTime from '@/store/modules/searchproposedfinaldestinationleadtime'
import ViewLogicalWarehouse from '@/store/modules/viewlogicalwarehouse'
import SearchProposedLogisticLeadTime from '@/store/modules/searchproposedlogisticleadtime'
import SearchLogisticLeadTime from '@/store/modules/searchlogisticleadtime'
import SearchProposedTransitLeadTime from '@/store/modules/searchproposedtransitleadtime'
import UserInfo from '@/store/modules/userInfo'
import SearchTermsOfDelivery from '@/store/modules/searchtermsofdelivery'
import LogisticsMasterData from '@/store/modules/logisticsmasterdata'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    UserInfo,
    SupportData,
    SearchShippingLeadTime,
    SearchAdditionalShippingLeadTime,
    SearchHaulageLeadTime,
    SearchTransitLeadTime,
    SearchProposedTransitLeadTime,
    SearchFinalDestinationLeadTime,
    SearchProposedHaulageLeadTime,
    SearchProposedFinalDestinationLeadTime,
    ViewLogicalWarehouse,
    SearchProposedLogisticLeadTime,
    SearchLogisticLeadTime,
    SearchTermsOfDelivery,
    LogisticsMasterData
  }
})
