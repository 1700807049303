<template>
  <v-form ref="shipping-lead-time-create-step1-form">
    <v-card
        elevation="0"
        class="mb-3">
      <div class="text-h6 mb-3">Shipping from</div>

      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
        <base-input-select-with-flag
          item-label="Country of delivery"
          :items="geographicalCountries"
          item-country="code"
          item-text="description"
          item-value="id"
          :rules="[rules.required]"
          :selected-value="shippingLeadTime.countryOfDeliveryId"
          @change="shippingLeadTime.countryOfDeliveryId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-with-flag
              item-label="Place of loading"
              :items="placesOfLoadingCountryOfDelivery"
              item-country="location.code"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="shippingLeadTime.placeOfLoadingId"
              :country-first-in-select="shippingLeadTime.countryOfDeliveryId"
              @change="shippingLeadTime.placeOfLoadingId = arguments[0]"/>
        </v-col>
      </v-row>

      <div class="text-h6 mb-3">Shipping to</div>
      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
          <base-input-select-with-flag
              item-label="Place of delivery"
              :items="placesOfDelivery"
              item-country="location.code"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="shippingLeadTime.placeOfDeliveryId"
              @change="shippingLeadTime.placeOfDeliveryId = arguments[0]"/>
        </v-col>
      </v-row>

      <div class="text-h6 mb-3">Shipping details</div>
      <v-row>
        <v-col cols="10" class="pb-0 ml-2">
          <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Mode of transport"
              :rules="[rules.required]"
              :selected-value="shippingLeadTime.freightServiceId"
              @change="updateFreightService(arguments[0])"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select
              item-label="Transport service provider"
              :items="serviceProviders"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="shippingLeadTime.serviceProviderId"
              @change="shippingLeadTime.serviceProviderId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select
              item-label="Carrier"
              :items="carriers"
              item-country="code"
              item-text="description"
              item-value="id"
              :rules="[rules.requiredIfSea]"
              :selected-value="shippingLeadTime.carrierId"
              hint="Carrier is optional, unless mode of transport is Sea"
              @change="shippingLeadTime.carrierId = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-text-field
              item-label="Carrier service string"
              :value="shippingLeadTime.service"
              hint="Carrier service string is optional"
              @blur="shippingLeadTime.service = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select
            :items="serviceCodes"
            item-value="name"
            item-text="description"
            item-label="Service code"
            :rules="[rules.requiredIfSea]"
            :selected-value="shippingLeadTime.serviceCode"
            hint="Service code is optional, unless mode of transport is Sea"
            @change="shippingLeadTime.serviceCode = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <v-checkbox
            class="mt-0"
            v-model="shippingLeadTime.main"
            label="Main shipping lead time"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <v-checkbox
            class="mt-0"
            v-model="shippingLeadTime.defaultForCountryOfDelivery"
            label="Default for country of delivery"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-2 ml-2">
          <base-input-select-date-picker
              item-label="Valid from"
              :rules="[rules.required]"
              :selected-value="shippingLeadTime.validFrom"
              @change="shippingLeadTime.validFrom = arguments[0]"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pb-0 pt-0 ml-2">
          <base-input-select-date-picker
            item-label="Valid to"
            :rules="[rules.fromDateIsBeforeToDate]"
            :selected-value="shippingLeadTime.validTo"
            @change="shippingLeadTime.validTo = arguments[0]"/>
        </v-col>
      </v-row>

    </v-card>
  </v-form>
</template>

<script>
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import BaseInputTextField from '@/base/input/BaseInputTextField'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import { validateDate } from '@/validation/customvalidation'

export default {
  name: 'CreateShippingLeadTimeStep1',
  components: {
    BaseInputSelectDatePicker,
    BaseInputTextField,
    BaseInputSelect,
    BaseInputSelectWithFreightService,
    BaseInputSelectWithFlag
  },
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        requiredIfSea: value => this.validateRequiredIfSea(value) || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.shippingLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  props: {
    shippingLeadTime: {
      type: Object,
      require: true
    },
    geographicalCountries: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    placesOfLoading: {
      type: Array,
      require: true
    },
    placesOfDelivery: {
      type: Array,
      require: true
    },
    serviceProviders: {
      type: Array,
      require: true
    },
    carriers: {
      type: Array,
      require: true
    },
    serviceCodes: {
      type: Array,
      require: true
    }
  },
  computed: {
    placesOfLoadingCountryOfDelivery () {
      if (this.shippingLeadTime.countryOfDeliveryId) {
        // Get all places of loading with same country as country of delivery
        const deliveryCountryPlacesOfLoading = this.placesOfLoading.filter(item =>
          item.location.id === this.shippingLeadTime.countryOfDeliveryId)

        if (deliveryCountryPlacesOfLoading.length > 0) {
          const returnPlacesOfLoading = []
          // Add places of loading with the same country as country of delivery first on the list
          returnPlacesOfLoading.push({ header: deliveryCountryPlacesOfLoading[0].location.description })
          deliveryCountryPlacesOfLoading.forEach(placeOfLoading => {
            returnPlacesOfLoading.push(placeOfLoading)
          })
          returnPlacesOfLoading.push({ header: 'Other locations' })
          this.placesOfLoading.forEach(placeOfLoading => {
            returnPlacesOfLoading.push(placeOfLoading)
          })
          return returnPlacesOfLoading
        }
      }
      return this.placesOfLoading
    }
  },
  methods: {
    validate () {
      return this.$refs['shipping-lead-time-create-step1-form'].validate()
    },
    validateRequiredIfSea (value) {
      const freightService = this.freightServices.find(item => item.id === this.shippingLeadTime.freightServiceId)
      if (freightService) {
        const transportMode = freightService.transportModes[freightService.transportModes.length - 1].name
        if (transportMode === 'SEA') {
          return !!value
        }
      }
      return true
    },
    updateFreightService (value) {
      this.shippingLeadTime.freightServiceId = value
      this.$emit('freightServiceUpdated')
    }
  }
}
</script>

<style scoped>
</style>
