<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
        Terms of delivery details
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text style="height: 900px;" class="text-body-1 text--primary">

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Country of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" >
            <base-label-with-flag
              :item-country="viewDialogTermsOfDelivery.countryOfDelivery.code"
              :item-label="viewDialogTermsOfDelivery.countryOfDelivery.description"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Destination logistics delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" >
            <base-label-with-flag
              :item-country="viewDialogTermsOfDelivery.destinationLogisticsCountry.code"
              :item-label="viewDialogTermsOfDelivery.destinationLogisticsCountry.description"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Is HazMat?
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTermsOfDelivery.hazMat | formatBoolean }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" >
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Packing mode
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-packing-mode :packing-mode="viewDialogTermsOfDelivery.packingMode"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            MoT category
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-mode-of-transport-category :mode-of-transport-category="viewDialogTermsOfDelivery.modeOfTransportCategory"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            MoT category priority
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTermsOfDelivery.modeOfTransportCategory.priority }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Mode of transport
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-mode-of-transport :mode-of-transport="viewDialogTermsOfDelivery.modeOfTransport"/>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0">
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Mode of transport priority
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTermsOfDelivery.modeOfTransport.priority }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" >
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Inco term
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTermsOfDelivery.incoTerms.description }}
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Published on:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogTermsOfDelivery.publishTime" >
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTermsOfDelivery.publishTime | formatDateTimeFromInstant }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid from:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogTermsOfDelivery.validFrom" >
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTermsOfDelivery.validFrom | formatDate }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid to:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogTermsOfDelivery.validTo">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTermsOfDelivery.validTo | formatDate }}
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" >
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            External ID:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTermsOfDelivery.externalId }}
          </v-col>
        </v-row>
        <v-row class="mt-2 mr-0" >
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Version:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTermsOfDelivery.aggregateVersion }}
          </v-col>
        </v-row>
        <v-row class="mt-2 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Creation time:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTermsOfDelivery.creationTime | formatDateTimeFromInstant }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Modification time:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTermsOfDelivery.modificationTime | formatDateTimeFromInstant }}
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button v-if=false label="Update" icon="mdi-pencil" @click="update"/>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithModeOfTransport from '@/base/label/BaseLabelWithModeOfTransport'
import BaseLabelWithModeOfTransportCategory from '@/base/label/BaseLabelWithModeOfTransportCategory'
import BaseLabelWithPackingMode from '@/base/label/BaseLabelWithPackingMode'

export default {
  name: 'SearchTermsOfDeliveryViewDetailsDialog',
  components: {
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseLabelWithFlag,
    BaseLabelWithModeOfTransport,
    BaseLabelWithModeOfTransportCategory,
    BaseLabelWithPackingMode
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogTermsOfDelivery: {
      type: Object,
      require: true
    },
    canUpdate: {
      type: Boolean,
      require: true,
      default: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    update (item) {
      this.$router.push({ path: '/TermsOfDelivery/Update/' + this.viewDialogTermsOfDelivery.id })
    }
  }
}
</script>

<style scoped>

</style>
