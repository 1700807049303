<template>
  <v-dialog persistent v-model="dialog" max-width="700px">
    <v-card v-if="createLLtsDialog">
      <v-card-text>
        <v-row justify="center" class="mt-10 ml-5">
          Direct replace will end the existing SLLT associated with the Final Destination Lead Time and create a new SLLT with the replaced Final Destination Lead time.
          The new SLLT will also be published with today's date.
          <v-row class="mt-4 ml-1">
          Do you want to continue?
          </v-row>
          <v-spacer></v-spacer>
        </v-row>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button @click="$emit('replace')" label="Yes"/>
        <base-input-secondary-button @click="$emit('close')" label="No"/>
      </v-card-actions>
    </v-card>

    <v-card v-if="showResultsDialog">
      <v-card-text>
        <v-row justify="center" class="pt-16 mb-5">
          The new final destination lead times have been created and the previous ones have ended, along with its SLLTs.
          New logistic SLLTs have been created.
        </v-row>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button @click="goToLogisticLTs()" label="Go to SLLT search"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
export default {
  name: 'ReplaceFinalDestinationLeadTimeDialog',
  components: {
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  props: {
    dialog: {
      type: Boolean,
      require: true
    },
    createLLtsDialog: {
      type: Boolean,
      require: true
    },
    showResultsDialog: {
      type: Boolean,
      require: true
    }
  },
  methods: {
    goToLogisticLTs () {
      this.$router.push({ name: 'SearchLogisticsLeadTime' })
    }
  }
}
</script>

<style scoped>

</style>
