<template>
  <v-container class="container">
    <v-alert v-if="showSuccessMessage" color="green lighten-2" dark>
        <span>Successfully triggered creation of proposed logistics lead time. Kindly search for Proposed logistics lead time after few minutes</span>
    </v-alert>
    <v-alert v-else-if="showErrorMessage" color="red lighten-2" dark>
      <span>Unable to trigger creation of proposed logistics lead time at the moment. Please try again later.</span>
    </v-alert>
    <v-form ref="proposed-llt-create-form">
      <v-card class="mt-5">
        <base-card-title label="Create proposed shipping & logistics lead time"/>

        <v-card-text class="pl-8 pr-8">
          <v-row class="pt-3">
            <v-col md="4" class="pb-0 pt-0">
              <base-input-select-date-picker
                item-label="Shipping lead time valid from"
                :selected-value="searchCriteria.shippingLeadTimeValidFrom"
                @change="searchCriteria.shippingLeadTimeValidFrom = arguments[0]"/>
            </v-col>
          </v-row>

          <base-input-primary-button label="Trigger creation"
                                     @click="() => this.confirmDialog = true"/>

          <base-dialog-confirm
            :confirm-dialog="confirmDialog"
            label="Do you really want to create shipping & logistic lead time proposals? Note: This action will take few minutes to complete"
            @confirm="triggerCreation"
            @cancel="() => this.confirmDialog = false"/>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>

import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import axios from 'axios'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'

export default {
  name: 'CreateProposedLogisticsLeadTime',
  components: {
    BaseDialogConfirm,
    BaseInputPrimaryButton,
    BaseInputSelectDatePicker,
    BaseCardTitle

  },
  data () {
    return {
      showSuccessMessage: false,
      showErrorMessage: false,
      confirmDialog: false,
      searchCriteria: {
        shippingLeadTimeValidFrom: ''
      }
    }
  },
  methods: {
    triggerCreation () {
      axios.post('/api/touchpoint/proposed-logistic-lead-time/trigger-creation', this.searchCriteria)
        .then(response => {
          if (response.data === 'SUCCESS') {
            this.showSuccessMessage = true
            this.confirmDialog = false
          } else {
            this.showErrorMessage = true
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1300px;
}
</style>
